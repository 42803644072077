import React, {forwardRef, useState} from 'react';
import {Button, ButtonGroup, Menu, MenuItem} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {DataId, SortDirection, Sx} from 'app/types/common';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {capitalize} from 'app/components/helpers/commonHelpers';

type Props = Sx &
  DataId & {
    direction: SortDirection;
    field: string;
    fields: string[];
    onSort: (field: string, direction: SortDirection) => void;
  };

export const SortingSelector = forwardRef<HTMLDivElement, Props>(
  ({dataId, sx, field, fields, direction, onSort}, ref) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOrder = () => {
      onSort(field, direction === 'asc' ? 'desc' : 'asc');
    };

    const handleField = (field: string) => {
      onSort(field, direction);
      setAnchorEl(null);
    };

    const menuOpen = Boolean(anchorEl);

    return (
      <ButtonGroup data-id={dataId} sx={sx} ref={ref} variant="outlined" color="info">
        <Tooltip content={`Sort field: ${field}`}>
          <Button
            data-id="select-sort-btn"
            sx={{justifyContent: 'flex-start', flex: 1}}
            startIcon={<SortIcon />}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            {capitalize(field)}
          </Button>
        </Tooltip>

        <Menu
          data-id="sort-menu"
          open={menuOpen}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          onClose={() => setAnchorEl(null)}
        >
          {fields.map((f) => (
            <MenuItem
              key={f}
              data-id={f}
              selected={f === field}
              sx={{minWidth: 100}}
              onClick={() => handleField(f)}
            >
              {capitalize(f)}
            </MenuItem>
          ))}
        </Menu>

        <Tooltip content={formatDirection(direction)}>
          <Button data-id="order-sort-btn" size="small" onClick={handleOrder}>
            {direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </Button>
        </Tooltip>
      </ButtonGroup>
    );
  },
);

function formatDirection(dir: SortDirection): string {
  const direction = dir === 'asc' ? 'ascending' : 'descending';
  return `Sort direction: ${direction}`;
}
