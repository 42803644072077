import React from 'react';
import {Cloud} from 'app/domain/cloud';
import {capitalize} from 'app/components/helpers/commonHelpers';
import {Box} from '@mui/material';
import {Sx} from 'app/types/common';

type Level = 'critical' | 'error' | 'warning' | 'low';

function getSeverityLevel(severity: Cloud.AlertSeverity): Level {
  switch (severity) {
    case 'critical':
      return 'critical';

    case 'error':
      return 'error';

    case 'warning':
      return 'warning';

    default:
      return 'low';
  }
}

interface Props extends Sx {
  severity: Cloud.AlertSeverity;
}

export function DeviceAlertBadge({sx, severity}: Props) {
  const level = getSeverityLevel(severity);

  return (
    <Box
      sx={sx}
      className={`device-alert-badge device-alert-badge--${level}`}
      data-id="device_alert_badge"
    >
      {capitalize(level)}
    </Box>
  );
}
