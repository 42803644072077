import React from 'react';
import {Box, Stack} from '@mui/material';
import {TeamSelector} from 'app/components/Navigation/TeamSelector/TeamSelector';
import {NavNotifications} from 'app/components/Navigation/NavNotifications/NavNotifications';

interface Props extends React.ComponentProps<typeof TeamSelector> {}

export function NavHeader({
  sx,
  email,
  teamId,
  teamName,
  compact,
  teams,
  onCreate,
  onLogout,
  onMyTeam,
}: Props) {
  const {src, srcSet} = useLogo(compact);

  return (
    <Stack sx={sx}>
      {/* TODO: replace with button with home page redirection */}
      <Box
        px={compact ? 0 : 2}
        py={compact ? 1 : 2}
        component="img"
        alt="Epiphan Cloud"
        src={src}
        srcSet={srcSet}
        mb={1}
      />
      <TeamSelector
        teams={teams}
        email={email}
        teamId={teamId}
        teamName={teamName}
        compact={compact}
        onCreate={onCreate}
        onLogout={onLogout}
        onMyTeam={onMyTeam}
      />

      <NavNotifications sx={{mt: 1}} compact={compact} />
    </Stack>
  );
}

interface Src {
  src: string;
  srcSet: string;
}

function useLogo(compact?: boolean): Src {
  if (compact) {
    return {
      src: '/assets/img/logo/logo-small.png',
      srcSet: '/assets/img/logo/logo-small@x2.png 2x',
    };
  }

  return {
    src: '/assets/img/logo/logo.png',
    srcSet: '/assets/img/logo/logo@x2.png 2x',
  };
}
