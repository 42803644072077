import {useEffect, useState} from 'react';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

type State = {
  started: AnyDeviceModelType[];
  stopped: AnyDeviceModelType[];
};

type Statistic = {
  streaming: State & {selectedDestinations: boolean};
  recording: State;
  transcribing: State;
};

function getDefaultState(): Statistic {
  return {
    streaming: {started: [], stopped: [], selectedDestinations: false},
    recording: {started: [], stopped: []},
    transcribing: {started: [], stopped: []},
  };
}

export function useBatchMediaState(devices: AnyDeviceModelType[]): Statistic {
  const [state, setState] = useState(() => getDefaultState());

  useEffect(() => {
    const stats = getDefaultState();

    devices.forEach((device) => {
      if (device.isOffline()) {
        return;
      }

      if (device.access.streaming.enabled && allowCountState(device)) {
        if (device.isStreaming() || device.isStreamingStarted()) {
          stats.streaming.started.push(device);
        } else {
          stats.streaming.stopped.push(device);
        }

        if (
          !stats.streaming.selectedDestinations &&
          (device.getSelectedStreamingDestinationId() ||
            device.getSelectedPublisherIds().length > 0)
        ) {
          stats.streaming.selectedDestinations = true;
        }
      }

      if (device.access.recording.enabled && allowCountState(device)) {
        if (device.isRecording()) {
          stats.recording.started.push(device);
        } else {
          stats.recording.stopped.push(device);
        }
      }

      if (device.access.transcribing.enabled) {
        if (device.isTranscribing()) {
          stats.transcribing.started.push(device);
        } else {
          stats.transcribing.stopped.push(device);
        }
      }
    });

    setState(stats);
  }, [devices]);

  return state;
}

function allowCountState(device: AnyDeviceModelType) {
  const modelName = device.getModelName();
  return ModelService.isWebcaster(modelName) || ModelService.isChannel(modelName);
}
