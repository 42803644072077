import {CurrentTeamTracker} from 'app/util/CurrentTeamTracker';
import {cirrusApi, front} from 'app/api/CirrusApi';
import {getTeamIdFromCurrentUrl} from 'app/api/util/getTeamIdFromCurrentUrl';
import {cirrusDefaultTeamApi} from 'app/api/CirrusDefaultTeamApi';
import {CurrentUserData} from 'app/store/User/types';
import {TeamApiService} from 'app/services/api/team/TeamApiService';

const checkTeamIsAvailable = async (teamId: string | null): Promise<string> =>
  new Promise((resolve, reject) => {
    if (teamId) {
      cirrusApi.setCurrentTeamId(teamId);
      front
        .teams()
        .get()
        .then((teams: Contracts.Core.Team[]) => {
          const teamIsAvailable = teams.some(({TeamID}) => TeamID === teamId);

          if (teamIsAvailable) {
            resolve(teamId);
          } else {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    } else {
      reject();
    }
  });

export const checkTeamAccess = async (teamId: string | null): Promise<string> => {
  if (teamId) {
    cirrusApi.setCurrentTeamId(teamId);

    const teams = await TeamApiService.getTeams();

    const available = teams.some((t) => t.TeamID === teamId);

    if (!available) {
      throw new Error('No access to team');
    }

    return teamId;
  }

  throw new Error('No team id');
};

const getCurrentTeamIdFromURL = async () => checkTeamIsAvailable(getTeamIdFromCurrentUrl());

const getCurrentTeamIdFromApi = async (): Promise<string> => {
  return (cirrusDefaultTeamApi as any).front
    .users()
    .me()
    .get()
    .then((userData: CurrentUserData) => userData.CurrentTeam);
};

export const determineTeamId = async (tr: CurrentTeamTracker): Promise<string> => {
  try {
    const idFromUrl = getTeamIdFromCurrentUrl();
    const result = await checkTeamAccess(idFromUrl);
    return result;
  } catch {
    try {
      const idFromTracker = tr.load();
      const result = await checkTeamAccess(idFromTracker);
      return result;
    } catch {
      tr.reset();
      const result = await getCurrentTeamIdFromApi();
      return result;
    }
  }
};

export const loadTeamId = async (tr: CurrentTeamTracker) => {
  const teamIdFromURL = getTeamIdFromCurrentUrl();
  const teamId = await determineTeamId(tr);

  if (teamIdFromURL !== teamId) {
    window.location.pathname = `/${teamId}`;
  }

  return teamId;
};

const loadCurrentTeamId = async (teamTracker: CurrentTeamTracker): Promise<string> => {
  return getCurrentTeamIdFromURL()
    .catch(async () => {
      return checkTeamIsAvailable(teamTracker.load());
    })
    .catch(async () => {
      teamTracker.reset();
      return Promise.reject();
    })
    .catch(async () => {
      return getCurrentTeamIdFromApi();
    })
    .then((teamId) => {
      const teamIdFromURL = getTeamIdFromCurrentUrl();

      if (teamIdFromURL !== teamId) {
        // eslint-disable-next-line no-restricted-globals
        location.pathname = `/${teamId}`;
      }

      return teamId;
    });
};

export {loadCurrentTeamId};
