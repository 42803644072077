import {createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {CloudContract} from 'app/contracts/cloud';
import {Cloud} from 'app/domain/cloud';

function mapAlertSettings(c: CloudContract.AlertSettings): Cloud.AlertSettings {
  const {DeviceID, Device, Settings} = c;
  const device = createDeviceModel(Device);

  return {
    id: DeviceID,
    deviceName: device.getName() ?? '',
    deviceModel: device.getModelName(),
    deviceGroupId: device.getGroupId(),
    settings: Settings,
  };
}

function mapAlert(c: CloudContract.Alert): Cloud.Alert {
  const {id, name, description, remedy} = c;
  return {id, name, description, remedy};
}

function mapUnitAlert(c: CloudContract.UnitAlert): Cloud.UnitAlert {
  const {timestamp, severity, message} = c;

  return {
    timestamp,
    severity,
    message,
    id: c.alert_id,
    deviceId: c.device_id,
    deviceModel: c.device_model,
    deviceName: c.device_name,
    read: c.is_read,
  };
}

export const AlertMapper = {mapAlertSettings, mapAlert, mapUnitAlert};
