import React, {useState} from 'react';
import {filesize} from 'filesize';
import {Button, Popover, Stack, Tooltip, Typography} from '@mui/material';
import {Gauge} from '@mui/x-charts';
import StorageIcon from '@mui/icons-material/Storage';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {isNil} from 'app/util/isNil';
import {Edge} from 'app/domain/edge';
import {getGaugeSx} from 'app/components/features/edge/EdgeDevice/indicators/indicators.types';

const rootSx: StyleSx = {py: 0.5, px: 1, gap: 0.5};
const paperSx: StyleSx = {p: 1, minWidth: 132};

type Props = Sx & {
  storage: Edge.Storage;
};

export function DeviceStorage({sx, storage}: Props) {
  const [menu, setMenu] = useState<HTMLElement | null>(null);

  const {free, used, total} = storage;

  const gaugeSx = packSx(getGaugeStyles(used / total), {alignSelf: 'center'});

  const renderPanel = () => {
    if (!total) {
      return <Typography>No storage available</Typography>;
    }

    return (
      <>
        {' '}
        <Gauge
          sx={gaugeSx}
          width={80}
          height={80}
          innerRadius="90%"
          outerRadius="125%"
          valueMax={total}
          value={used}
          text={({value}) => {
            const used = value ?? 0;
            const usage = (used / total) * 100;

            return `${usage.toFixed(1).replace('.0', '')}%`;
          }}
        />
        <Stack direction="row" justifyContent="space-between">
          <Typography>Total:</Typography>

          <Typography fontWeight={600} data-id="storage-total">
            {filesize(total, {round: 1})}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography>Used:</Typography>

          <Typography data-id="storage-used" fontWeight={600}>
            {filesize(used, {round: 1})}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography>Free:</Typography>

          <Typography data-id="storage-free" fontWeight={600}>
            {filesize(free, {round: 1})}
          </Typography>
        </Stack>
      </>
    );
  };

  return (
    <>
      <Tooltip title="Device storage">
        <Button
          data-id="storage-btn"
          sx={packSx(rootSx, sx)}
          variant="outlined"
          color="info"
          onClick={(e) => setMenu(e.currentTarget)}
        >
          <StorageIcon />

          <Typography data-id="device_card_storage" noWrap={true}>
            {filesize(free, {round: 1})}
          </Typography>
        </Button>
      </Tooltip>

      <Popover
        anchorEl={menu}
        open={!isNil(menu)}
        slotProps={{paper: {sx: paperSx}}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        onClose={() => setMenu(null)}
      >
        <Typography mb={0.25} fontWeight={600}>
          Device storage
        </Typography>

        {renderPanel()}
      </Popover>
    </>
  );
}

function getGaugeStyles(ratio: number): StyleSx {
  if (ratio > 0.95) return getGaugeSx('error');

  if (ratio > 0.8) return getGaugeSx('warning');

  return getGaugeSx('ok');
}
