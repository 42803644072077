import {Edge} from 'app/domain/edge';

export type UnitModel =
  | 'Pearl-2'
  | 'Pearl Nano'
  | 'Pearl Mini'
  | 'Pearl Nexus'
  | 'Pearl Virtual'
  | 'LiveScrypt'
  | 'Epiphan Unify Project'
  | 'Webcaster'
  | 'Channel'
  | 'Demo';

export type Alert = {
  id: AlertType;
  name: string;
  description: string;
  remedy: string;
};

export enum AlertType {
  DeviceOffline = 'alert_device_offline',
  Storage = 'alert_storage',
  InputNoSignal = 'alert_source_no_signal',
  NoSignal = 'alert_no_signal',
  DeviceHealth = 'alert_device_health',
  Framerate = 'alert_framerate',
  PublisherBandwidth = 'alert_publisher_bandwidth',
  AFU = 'alert_afu',
  SystemLoad = 'alert_system_load',
  Publishers = 'alert_publishers',
  NoStorage = 'alert_no_storage',
  Schedule = 'alert_cms',
}

export type AlertSettings = {
  id: string;
  deviceName: string;
  deviceModel: UnitModel;
  deviceGroupId: string;
  settings: Partial<Record<AlertType, boolean>>;
};

export type AlertSeverity = 'critical' | 'error' | 'warning' | (string & {});

export type UnitAlert = {
  id: string;
  timestamp: TimeStampSeconds;
  severity: AlertSeverity;
  message: string;
  deviceId: string;
  deviceName: string;
  deviceModel: string;
  read: boolean;
};

type DestinationMetadata = {
  id: string;
  name: string;
  active: boolean;
  deleted: boolean;
  lastModified: TimeStampSeconds;
  ownedBy: string;
};

export type DestinationAuth<Service extends string> = {
  service: Service;
  userName: string;
  login: string;
  error: string;
  errorTime: number;
  externalId: string;
};

export type DestinationSetting = {
  title?: string;
  picture?: string;
  description: string;
  privacy?: string;
  pubId?: string;
  pubDestination?: string;
};

export type RtmpDestination = DestinationMetadata & {
  type: 'rtmp' | 'rtmps';
  rtmp: {
    key: string;
    name: string;
    url: string;
  };
};

export type GoogleDestination = DestinationMetadata & {
  type: 'google';
  auth: DestinationAuth<'google'>;
};

export type StreamingDestination = RtmpDestination | GoogleDestination;

export type ProjectStatus =
  | 'online'
  | 'offline'
  | 'starting'
  | 'resuming'
  | 'stopping'
  | 'deleting';

export type UnitStatus = ProjectStatus | Edge.DeviceStatus;
