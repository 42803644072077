import {Schedule} from 'app/domain/schedule';

export function isSkipped(status: Schedule.EventStatus): boolean {
  return status === 'skipped';
}

export function isUploading(status: Schedule.EventStatus): boolean {
  return status === 'uploading';
}

export function isFinished(status: Schedule.EventStatus): boolean {
  return status === 'finished';
}

export function isCompleted(status: Schedule.EventStatus) {
  return status === 'canceled' || isSkipped(status) || isUploading(status) || isFinished(status);
}

export function isRunning(status: Schedule.EventStatus) {
  return status === 'running';
}

export function isPaused(status: Schedule.EventStatus) {
  return status === 'paused';
}

export function isOngoing(status: Schedule.EventStatus) {
  return isRunning(status) || isPaused(status);
}

export function isScheduled(status: Schedule.EventStatus) {
  return status === 'scheduled';
}

export function getEventVariant(status: Schedule.EventStatus): Schedule.EventVariant {
  if (isOngoing(status)) return 'ongoing';

  if (isScheduled(status)) return 'scheduled';

  return 'completed';
}
