import {useCallback, useEffect, useRef, useState} from 'react';
import {Dispatcher} from 'app/data/Dispatcher';
import {ActionTypes} from 'app/data/ActionTypes';
import {KeyboardCode} from 'app/constants';
import {noop} from 'app/util/noop';

// /**
//  * @param {Function} [onChange]
//  * @return {{opened: boolean, toggle: ((function(): void)|*), close: ((function(): void)|*), open: ((function(): void)|*)}}
//  */
export function useToggleAsideNotifications({
  onChange = noop,
}) {
  const [opened, setOpened] = useState(false);
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const open = useCallback(() => {
    setOpened(true);
  }, []);
  const close = useCallback(() => {
    setOpened(false);
  }, []);
  const toggle = useCallback(() => {
    setOpened(opened => !opened);
  }, []);

  // Close panel on Esc
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === KeyboardCode.Escape) {
        close();
      }
    };

    if (opened === false) {
      return;
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [opened, close]);

  useEffect(() => {
    Dispatcher.on(ActionTypes.UI_ASIDE_NOTIFICATIONS_TOGGLE, toggle);

    return () => {
      Dispatcher.removeListener(ActionTypes.UI_ASIDE_NOTIFICATIONS_TOGGLE, toggle);
    };
  }, [toggle]);

  useEffect(() => {
    Dispatcher.emit(ActionTypes.NOTIFICATIONS_NO_UNREAD);
    onChangeRef.current();
  }, [opened]);

  return {
    opened,
    open,
    close,
    toggle,
  };
}
