import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogTitle} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';

interface Props extends Prompt {
  onConnect(): Promise<void>;
}

export function ConnectChronoDialog({open, onConnect, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const handleConnect = async () => {
    try {
      setLoading(true);
      await onConnect();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true}>
      <DialogClose onClose={onClose} />

      <DialogTitle>Enable Epiphan Edge scheduling on your device</DialogTitle>

      <DialogActions>
        <LoadingButton
          data-id="confirm-connect-button"
          variant="contained"
          color="secondary"
          loading={loading}
          onClick={handleConnect}
        >
          Enable
        </LoadingButton>

        <Button data-id="cancel-connect-button" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
