import {useQuery} from '@tanstack/react-query';
import {AlertsApiService} from 'app/services/api/alerts/AlertsApiService';
import {AlertMapper} from 'app/services/mappers/AlertMapper/AlertMapper';
import {createStringComparator} from 'app/util/Sort';

interface Args {
  teamId: string;
  enabled: boolean;
}

export function useAlertSettings({teamId, enabled}: Args) {
  return useQuery({
    queryKey: ['alert-settings', teamId],
    queryFn: async () => {
      const response = await AlertsApiService.getAlertSettings();
      const mapped = response.map(AlertMapper.mapAlertSettings);
      const sorted = mapped.sort(createStringComparator((deviceAlert) => deviceAlert.deviceName));
      return sorted;
    },
    enabled,
    refetchInterval: 30 * 1000,
  });
}
