import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Divider, Stack, Typography} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {debounce} from 'throttle-debounce';
import {Cloud} from 'app/domain/cloud';
import {ALL_ALERT_TYPES} from 'app/components/Settings/Alerts/AlertSettings/constants';
import {isAlertDisabled} from 'app/components/Settings/Alerts/AlertSettings/utils';
import {FilterSelector} from 'app/components/sharedReactComponents/FilterSelector';
import {filterItemsByFilterSwitches} from 'app/components/sharedReactComponents/FilterSelector/utils';
import {TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {AlertsApiService} from 'app/services/api/alerts/AlertsApiService';
import {useDeviceGroups} from 'app/hooks/useDeviceGroups';
import {Sx} from 'app/types/common';
import {AlertsTable} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertsTable';
import {useAlertSettings} from 'app/components/Settings/Alerts/AlertSettings/hooks/useTeamAlerts';
import {
  filterSwitchGroups,
  useFilterSwitches,
} from 'app/components/Settings/Alerts/AlertSettings/hooks/useFilterSwitches';

interface Props extends Sx {
  teamId: string;
  premium: boolean;
  userEmail: string;
  getAlertByType: (type: Cloud.AlertType) => Cloud.Alert | undefined;
}

export function AlertSettings({sx, teamId, premium, userEmail, getAlertByType}: Props) {
  const queryClient = useQueryClient();

  const [deviceAlerts, setDeviceAlerts] = useState<Cloud.AlertSettings[]>([]);
  const [filteredDeviceAlerts, setFilteredDeviceAlerts] = useState<Cloud.AlertSettings[]>([]);

  const {groups} = useDeviceGroups({teamId, enabled: premium});

  const {filterSwitches, activeFilterSwitches, toggleFilterSwitcher, clearFilterSwitches} =
    useFilterSwitches({deviceGroups: groups});

  const {data, isInitialLoading} = useAlertSettings({teamId, enabled: true});

  useEffect(() => {
    setDeviceAlerts((prev) => data ?? prev);
  }, [data]);

  const mutation = useMutation({
    mutationFn: async (alerts: Cloud.AlertSettings[]) => {
      const data = alerts.map((a) => {
        const {id, settings} = a;

        return {DeviceID: id, Settings: settings};
      });

      await AlertsApiService.updateAlerts(data);
    },
    onError: async () => {
      await queryClient.invalidateQueries({queryKey: ['team-alerts']});
    },
  });

  const handleDebouncedSave = useCallback(
    debounce(1000, async (alerts: Cloud.AlertSettings[]) => {
      mutation.mutate(alerts);
    }),
    [],
  );

  const handleSave = (alerts: Cloud.AlertSettings[]) => {
    setDeviceAlerts(alerts);
    handleDebouncedSave(alerts);
  };

  useEffect(() => {
    let filteredDeviceAlerts = [...deviceAlerts];

    // Filtering
    if (activeFilterSwitches.size > 0) {
      const activeFilters = filterSwitches.current.filter((filterSwitch) =>
        activeFilterSwitches.has(filterSwitch.id),
      );
      filteredDeviceAlerts = filterItemsByFilterSwitches(deviceAlerts, activeFilters);
    }

    setFilteredDeviceAlerts(filteredDeviceAlerts);
  }, [deviceAlerts, filterSwitches, activeFilterSwitches]);

  const updateDeviceAlerts = (id: string, alertType: Cloud.AlertType, value: boolean) => {
    const newDeviceAlerts = deviceAlerts.map((deviceAlert) => {
      if (deviceAlert.id === id) {
        return {
          ...deviceAlert,
          settings: {...deviceAlert.settings, [alertType]: value},
        };
      }

      return deviceAlert;
    });

    handleSave(newDeviceAlerts);
  };

  const toggleAlertForAllDevices = (alertType: Cloud.AlertType, value: boolean) => {
    const filteredDeviceAlertIds = collectAlerts(filteredDeviceAlerts);

    const newDeviceAlerts = deviceAlerts.map((deviceAlert) => {
      const settings = {...deviceAlert.settings};

      if (filteredDeviceAlertIds.has(deviceAlert.id)) {
        settings[alertType] = value && !isAlertDisabled(deviceAlert, alertType);
      }

      return {...deviceAlert, settings};
    });

    handleSave(newDeviceAlerts);
  };

  const toggleAll = (value: boolean) => {
    const filteredDeviceAlertIds = collectAlerts(filteredDeviceAlerts);

    const newDeviceAlerts = deviceAlerts.map((deviceAlert) => {
      const settings = {...deviceAlert.settings};

      if (filteredDeviceAlertIds.has(deviceAlert.id)) {
        ALL_ALERT_TYPES.forEach((alertType) => {
          settings[alertType] = value && !isAlertDisabled(deviceAlert, alertType);
        });
      }

      return {...deviceAlert, settings};
    });

    handleSave(newDeviceAlerts);
  };

  const selectAll = () => {
    void toggleAll(true);
  };

  const unselectAll = () => {
    void toggleAll(false);
  };

  const description =
    'Alerts are device specific. You can select the conditions that raise an alert in Epiphan Cloud'.concat(
      premium ? ' and trigger a notification email.' : '.',
    );

  return (
    <Box sx={sx}>
      <Typography variant="h6" fontWeight={600} mb={1}>
        Device alerts
      </Typography>

      <Typography>{description}</Typography>

      {premium && userEmail && <Typography>{`Alerts will be sent to ${userEmail}`}</Typography>}

      <Stack mt={2} direction="row" alignItems="center">
        {premium && (
          <FilterSelector
            filterSwitches={filterSwitches.current}
            filterSwitchGroups={filterSwitchGroups}
            activeFilterSwitches={activeFilterSwitches}
            placement={TOOLTIP_PLACEMENT.BOTTOM_START}
            onClickFilterSwitcher={toggleFilterSwitcher}
          />
        )}

        <Stack
          ml="auto"
          direction="row"
          alignItems="center"
          gap={1}
          divider={<Divider orientation="vertical" flexItem={true} />}
        >
          <Button variant="text" size="small" onClick={selectAll}>
            Select all
          </Button>

          <Button variant="text" size="small" onClick={unselectAll}>
            Unselect all
          </Button>
        </Stack>
      </Stack>

      <AlertsTable
        sx={{mt: 1}}
        deviceAlerts={filteredDeviceAlerts}
        emptyFilterResults={filteredDeviceAlerts.length === 0 && activeFilterSwitches.size > 0}
        loading={isInitialLoading}
        onChange={updateDeviceAlerts}
        onToggleAlert={toggleAlertForAllDevices}
        getAlertByType={getAlertByType}
        onClearFilter={clearFilterSwitches}
      />
    </Box>
  );
}

function collectAlerts(filtered: Cloud.AlertSettings[]): Set<string> {
  return new Set(filtered.map(({id}) => id));
}
