import {front} from 'app/api/CirrusApi';

export class DeviceGroupApiService {
  static async addDeviceToGroup(teamId: string, groupId: string, deviceId: string): Promise<void> {
    return front.team(teamId).groups(groupId).device(deviceId).post();
  }

  static async moveToGroup(deviceId: string, groupId: string): Promise<void> {
    return front.groups(groupId).device(deviceId).post();
  }

  static async removeFromGroup(deviceId: string, groupId: string): Promise<void> {
    return front.groups(groupId).device(deviceId).delete();
  }

  static async createGroup(name: string): Promise<void> {
    return front.groups().post({name});
  }

  static async renameGroup(groupId: string, name: string): Promise<void> {
    return front.groups(groupId).put({name});
  }

  static async deleteGroup(groupId: string): Promise<void> {
    return front.groups(groupId).delete();
  }
}
