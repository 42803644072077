import React, {useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {useCloudAndUnifyReport} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/CloudAndUnifyInvoices/useCloudAndUnifyReport';
import {
  InvoiceCard,
  Row,
} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/shared/InvoiceCard/InvoiceCard';
import {isNil} from 'app/util/isNil';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {CloudHistory} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/CloudAndUnifyInvoices/CloudHistory/CloudHistory';
import {palette} from 'app/themes/app';

interface Props extends Sx {
  teamId: string;
  period: App.Billing.Period;
}

export function CloudAndUnifyInvoices({teamId, period}: Props) {
  const {isInitialLoading, data} = useCloudAndUnifyReport({teamId, enabled: true});

  const rows = useMemo<Row[]>(() => {
    const invoice = data?.invoice;

    if (!isNil(invoice)) {
      return [
        {
          name: 'Edge subscription',
          total: invoice.cloud.fee,
          description: 'Edge usage in period.',
        },
        {
          name: 'LiveScrypt',
          total: invoice.lvs.usage,
          description: `Total hours: ${invoice.lvs.hours}. Billed hours: ${invoice.lvs.billedHours}.`,
        },
        {
          name: 'Unify subscription',
          total: invoice.unify.usage,
          description: 'Unify usage in period.',
        },
      ];
    }

    return [];
  }, [data]);

  const renderBody = () => {
    const loading = isInitialLoading || isNil(data);

    if (loading) {
      return <LoadingPlaceholder />;
    }

    const {invoice, history} = data;

    return (
      <React.Fragment>
        {invoice && (
          <InvoiceCard
            dataId="upcoming-invoice"
            sx={{mb: 3}}
            issueDate={invoice.date}
            total={invoice.total}
            period={period}
            rows={rows}
          />
        )}

        <Typography fontWeight={600} fontSize={16} mb={1}>
          Invoice history
        </Typography>

        {history.length > 0 ? (
          <CloudHistory history={history} teamId={teamId} />
        ) : (
          <Typography fontWeight={600} textAlign="center" color={palette.darkerGray} my={2}>
            No previous invoices
          </Typography>
        )}
      </React.Fragment>
    );
  };

  return <Box>{renderBody()}</Box>;
}
