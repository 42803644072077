import dayjs from 'dayjs';
import {DateAlerts} from 'app/components/DevicesAlerts/types';
import {numberComparator} from 'app/util/comparators/numberComparator';
import {Cloud} from 'app/domain/cloud';

export function groupDeviceAlertsByDate(alerts: Cloud.UnitAlert[]): DateAlerts[] {
  if (!alerts.length) {
    return [];
  }

  const sorted = alerts.sort((a, b) => numberComparator(a.timestamp, b.timestamp));

  const map = new Map<number, Cloud.UnitAlert[]>();

  sorted.forEach((alert) => {
    const startDay = dayjs.unix(alert.timestamp).startOf('day').unix();
    const dayAlerts = map.get(startDay);

    map.set(startDay, dayAlerts ? [...dayAlerts, alert] : [alert]);
  });

  return Array.from(map.entries()).map(([date, alerts]) => ({date, alerts}));
}
