import React, {forwardRef, useMemo, useState} from 'react';
import {Button, Card, IconButton, Typography} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {Files} from 'app/domain/files';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {DeleteRecordingsDialog} from 'app/components/features/recordings/dialogs/DeleteRecordingsDialog/DeleteRecordingsDialog';
import {RecordingsApi} from 'app/services/api/recordings/RecordingsApi';
import {downloadFile} from 'app/util/download';
import {DownloadRecordingsDialog} from 'app/components/features/recordings/dialogs/DownloadRecordingsDialog/DownloadRecordingsDialog';

const rootStyles: StyleSx = {
  p: 2,
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  height: 64,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: 1,
};

type Props = Sx & {
  recordings: Files.Recording[];
  reload: () => Promise<void>;
  onClose: () => void;
};

export const BatchRecordings = forwardRef<HTMLDivElement, Props>(
  ({sx, recordings, reload, onClose}, ref) => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [downloadDialog, setDownloadDialog] = useState(false);

    const total = recordings.length;

    const handleDelete = async () => {
      await RecordingsApi.batchDelete(recordings.map((r) => r.id));
      await reload();
      setDeleteDialog(false);
    };

    const uploaded = useMemo(() => recordings.filter((r) => r.uploaded), [recordings]);

    const handleDownload = async () => {
      uploaded.forEach((r) => {
        downloadFile(RecordingsApi.getDownloadUrl(r.id), r.name);
      });
    };

    return (
      <Card
        data-id="recordings-batch-"
        ref={ref}
        sx={packSx(rootStyles, sx)}
        variant="elevation"
        elevation={8}
      >
        <Typography data-id="selected-count">
          {total} {pluralizeN('File', total)}
        </Typography>

        <Button
          data-id="download-btn"
          sx={{ml: 4}}
          variant="outlined"
          color="info"
          startIcon={<CloudDownloadIcon />}
          onClick={() => setDownloadDialog(true)}
        >
          Download
        </Button>

        <Button
          data-id="delete-btn"
          variant="outlined"
          color="info"
          startIcon={<DeleteIcon />}
          onClick={() => setDeleteDialog(true)}
        >
          Delete
        </Button>

        <IconButton data-id="close-batch-btn" sx={{ml: 'auto'}} color="info" onClick={onClose}>
          <ClearIcon />
        </IconButton>

        <DeleteRecordingsDialog
          open={deleteDialog}
          onDelete={handleDelete}
          recordings={recordings.map((r) => r.name)}
          onClose={() => setDeleteDialog(false)}
        />

        <DownloadRecordingsDialog
          open={downloadDialog}
          recordings={uploaded.map((r) => r.name)}
          onDownload={handleDownload}
          onClose={() => setDownloadDialog(false)}
        />
      </Card>
    );
  },
);
