import {FilesContract} from 'app/contracts/files';
import {front} from 'app/api/CirrusApi';

export class RecordingsApi {
  static async getTeamRecordings(
    from: number,
    to: number,
  ): Promise<{files: FilesContract.Recording[]}> {
    return front.filemanager().v1().files().get({from, to});
  }

  static async getUnitRecordings(
    deviceId: string,
    from: number,
    to: number,
  ): Promise<{files: FilesContract.Recording[]}> {
    return front.filemanager().v1().devices(deviceId).files().get({from, to});
  }

  static async batchDelete(ids: string[]): Promise<void> {
    return front.filemanager().v1().files().remove().post(ids);
  }

  static async deleteRecording(id: string): Promise<void> {
    return front.filemanager().v1().files(id).delete();
  }

  static getDownloadUrl(id: string): string {
    return front.filemanager().v1().files(id).download().url() as string;
  }
}
