import {unionOfSets} from 'app/util/unionOfSets';
import {collectNotificationsTokens} from 'app/components/AsideNotifications/utils';
import {TokenModel} from 'app/components/AsideNotifications/types';

const READ_NOTIFICATIONS_STORAGE_KEY = 'aside-notifications-read';

const AsideNotificationsTokenStorage = {
  save(notifications: TokenModel[]) {
    const tokens = new Set(collectNotificationsTokens(notifications));
    const savedTokens = new Set<string>(this.load());
    const tokensToSave = unionOfSets(tokens, savedTokens);

    window.sessionStorage.setItem(READ_NOTIFICATIONS_STORAGE_KEY, JSON.stringify(Array.from(tokensToSave)));
  },

  load(): string[] {
    try {
      const data = window.sessionStorage.getItem(READ_NOTIFICATIONS_STORAGE_KEY);
      if (!data) {
        throw new Error();
      }

      return JSON.parse(data);
    } catch (_: unknown) {
      return [];
    }
  },
};

export {
  AsideNotificationsTokenStorage,
};
