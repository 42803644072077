const empty: App.Cloud.UnitCapability = {
  enabled: false,
  reason: '',
};

export class UnitAccess {
  map: Map<App.Cloud.UnitPermission, App.Cloud.UnitCapability>;

  constructor(map: Map<App.Cloud.UnitPermission, App.Cloud.UnitCapability>) {
    this.map = map;
  }

  get remoteLogin() {
    return this.map.get('remote-login') ?? empty;
  }

  get cms() {
    return this.map.get('cms') ?? empty;
  }

  get recording() {
    return this.map.get('recording') ?? empty;
  }

  get streaming() {
    return this.map.get('streaming') ?? empty;
  }

  get transcribing() {
    return this.map.get('transcribing') ?? empty;
  }

  get presets() {
    return this.map.get('presets') ?? empty;
  }

  get hls() {
    return this.map.get('hls') ?? empty;
  }

  get batch() {
    return this.map.get('batch') ?? empty;
  }

  get groups() {
    return this.map.get('groups') ?? empty;
  }
}
