import {front} from 'app/api/CirrusApi';

export class Google {
  public _next: any;
  public _state: any;

  constructor({
    next,
    state,
  }: any) {
    this._next = next;
    this._state = state;

    this._init();
  }

  _init() {
    /**
     * {@link https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.initialize}
     */
    window.google.accounts.id.initialize({
      client_id: CIRRUS_AUTH_GOOGLE_CLIENT_ID,
      ux_mode: 'redirect',
      login_uri: `${window.location.origin}${front.oauth().google({state: this._state}).url()}`,
    });
  }

  renderButton(element: HTMLDivElement, signIn = false) {
    /**
     * {@link https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton}
     */
    window.google.accounts.id.renderButton(element, {
      type: 'standard',
      shape: 'rectangular',
      theme: 'filled_blue',
      text: signIn ? 'signin_with' : 'signup_with',
      size: 'large',
      logo_alignment: 'left',
      width: 352,
    });
  }
}
