import React from 'react';
import {Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Cloud} from 'app/domain/cloud';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Sx} from 'app/types/common';
import {isAlertDisabled} from 'app/components/Settings/Alerts/AlertSettings/utils';
import {AlertValue} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertTableBody/AlertValue';

interface Props extends Sx {
  deviceAlert: Cloud.AlertSettings;
  alertTypes: Cloud.AlertType[];
  hoveredAlert?: Cloud.AlertType;
  disabled: boolean;
  onChange: (id: string, type: Cloud.AlertType, value: boolean) => void;
}

export function UnitAlert({sx, alertTypes, hoveredAlert, deviceAlert, disabled, onChange}: Props) {
  return (
    <Stack sx={sx} direction="row" alignItems="center">
      <Typography flexGrow={1} fontWeight={600} minWidth={0} component="div">
        <OverflowTooltip rowsNumber={2}>{deviceAlert.deviceName}</OverflowTooltip>
      </Typography>

      <Stack direction="row">
        {alertTypes.map((alertType) => {
          const typeDisabled = disabled || isAlertDisabled(deviceAlert, alertType);

          const typeHovered = hoveredAlert === alertType && !typeDisabled;

          const handleChange = (alertType: Cloud.AlertType, value: boolean) => {
            onChange(deviceAlert.id, alertType, value);
          };

          return (
            <AlertValue
              sx={{'&:nth-of-type(odd)': {bgcolor: grey[100]}}}
              key={alertType}
              type={alertType}
              value={deviceAlert.settings[alertType] ?? false}
              hovered={typeHovered}
              disabled={typeDisabled}
              onChange={handleChange}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
