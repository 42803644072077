import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {DeviceAlertBadge} from 'app/components/DevicesAlerts/DeviceAlertBadge';
import {useAlertDictionary} from 'app/store/hooks';
import {Button} from 'app/components/sharedReactComponents/Button';
import {VARIANT, SIZE} from 'app/constants';
import {Caret, CARET_VARIANT} from 'app/components/sharedReactComponents/Caret';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {processDetectingDeviceModel} from 'app/store/models/device/utils';
import {Cloud} from 'app/domain/cloud';
import {formatFullTimeOfDay} from 'app/util/time';

interface Props {
  alert: Cloud.UnitAlert;
}

const DevicesAlertsSidebarAlert: React.VFC<Props> = observer(({alert}) => {
  const deviceAlertDictionaryStore = useAlertDictionary();

  const [opened, setOpened] = useState(false);
  const handleOnClick = () => {
    setOpened(!opened);
  };

  const suggestedActions = deviceAlertDictionaryStore.getSuggestForAlert(alert.id) || 'No actions';
  const suggestedActionsList = suggestedActions.split('\n');
  const deviceModelName = processDetectingDeviceModel(alert.deviceModel);

  return (
    <div className={`devices-alerts-sidebar-alert devices-alerts-sidebar-alert--${alert.severity}`}>
      <div className="devices-alerts-sidebar-alert__header">
        <div className="devices-alerts-sidebar-alert__time">
          {formatFullTimeOfDay(alert.timestamp)}
        </div>

        <DeviceAlertBadge severity={alert.severity} />
      </div>

      <div className="devices-alerts-sidebar-alert__device-info">
        <OverflowTooltip rowsNumber={2}>
          {alert.deviceName || '<Unknown Device>'}
          {deviceModelName && ` - ${deviceModelName}`}
        </OverflowTooltip>
      </div>

      <div className="devices-alerts-sidebar-alert__message">{alert.message}</div>

      <Button
        className="devices-alerts-sidebar-alert__toggler"
        variant={VARIANT.TEXT}
        size={SIZE.S}
        onClick={handleOnClick}
      >
        <div className="devices-alerts-sidebar-alert__suggested_actions">Suggested Actions</div>

        <Caret
          className="devices-alerts-sidebar-alert__toggler-caret"
          opened={opened}
          variant={CARET_VARIANT.THIN}
          size={SIZE.S}
        />
      </Button>

      {opened && (
        <ul className="devices-alerts-sidebar-alert__suggested-actions-list">
          {suggestedActionsList.map((action, index) => (
            <li key={index}>{action}</li>
          ))}
        </ul>
      )}
    </div>
  );
});

DevicesAlertsSidebarAlert.displayName = 'DevicesAlertsSidebarAlert';

export {DevicesAlertsSidebarAlert};
