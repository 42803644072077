import {front} from 'app/api/CirrusApi';
import {GetAbortCallback} from 'app/api/types';
import {CloudContract} from 'app/contracts/cloud';

export class AlertsApiService {
  static async getAlertSettings(
    getAbort?: GetAbortCallback,
  ): Promise<CloudContract.AlertSettings[]> {
    return front.users().me().device_alerts().get(undefined, getAbort);
  }

  static async getAlertDict(): Promise<CloudContract.Alert[]> {
    return front.devices().alerts().dictionary().get();
  }

  static async updateAlerts(data: CloudContract.AlertUpdate[]): Promise<void> {
    await front.users().me().device_alerts().post(data);
  }

  static async getUnreadAlerts(getAbort?: GetAbortCallback): Promise<CloudContract.UnitAlert[]> {
    return front
      .users()
      .me()
      .unread_device_alerts()
      .get(undefined, getAbort)
      .then((alerts: CloudContract.UnitAlert[] | null) => {
        return Array.isArray(alerts) ? alerts : [];
      });
  }

  static async getTeamAlerts(): Promise<CloudContract.UnitAlert[]> {
    return front
      .users()
      .me()
      .all_device_alerts()
      .get()
      .then((response) => response || []);
  }

  static async acknowledgeAlerts(timestamp: number): Promise<void> {
    return front.users().me().acknowledge_device_alerts().put({timestamp});
  }
}
