import React, {useState} from 'react';
import {Button, Popover, Stack, Tooltip, Typography} from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import {packSx} from 'app/util/packSx/packSx';
import {StyleSx, Sx} from 'app/types/common';
import {useFirmware} from 'app/components/features/edge/hooks/useFirmware';
import {UpgradeFirmwareDialog} from 'app/components/features/edge/dialogs/UpgradeFirmwareDialog/UpgradeFirmwareDialog';

const rootSx: StyleSx = {py: 0.5, px: 1, gap: 0.5};
const paperSx: StyleSx = {p: 1, minWidth: 132};

type Props = Sx & {
  deviceId: string;
  firmware: string;
  checkUpdates: boolean;
  onUpgrade: () => Promise<any>;
};

export function FirmwareIndicator({sx, deviceId, firmware, checkUpdates, onUpgrade}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [dialog, setDialog] = useState(false);

  const {data, isSuccess, isLoading, refetch} = useFirmware({
    deviceId,
    enabled: checkUpdates,
    staleTime: 30 * 1000,
    refetchInterval: 60 * 1000,
  });

  const handleUpgrade = async () => {
    await onUpgrade();

    await refetch();
  };

  const renderUpdate = () => {
    if (!checkUpdates) {
      return null;
    }

    if (isLoading) {
      return <Typography>Checking for updates</Typography>;
    }

    if (isSuccess) {
      if (data?.update) {
        const {status} = data.update;

        if (status === 'downloading' || status === 'progress') {
          return (
            <Typography data-id="upgrade-status" color="text.secondary">
              Upgrading firmware
            </Typography>
          );
        }

        if (status === 'available' && data.available) {
          return (
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography>
                New version <strong data-id="new-available-version">{data.available}</strong> is
                available
              </Typography>

              <Button
                data-id="upgrade-btn"
                variant="outlined"
                size="small"
                onClick={() => setDialog(true)}
              >
                Upgrade
              </Button>

              <UpgradeFirmwareDialog
                open={dialog}
                onUpgrade={handleUpgrade}
                onClose={() => setDialog(false)}
              />
            </Stack>
          );
        }

        return null;
      }

      return <Typography color="text.disabled">No updates available</Typography>;
    }

    return null;
  };

  return (
    <>
      <Tooltip title="Firmware">
        <Button
          data-id="firmware-btn"
          sx={packSx(rootSx, sx)}
          variant="outlined"
          color="info"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <MemoryIcon />

          <Typography data-id="device_card_firmwareVersion" noWrap={true}>
            {firmware}
          </Typography>
        </Button>
      </Tooltip>

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        slotProps={{paper: {sx: paperSx}}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        onClose={() => setAnchorEl(null)}
      >
        <Typography mb={0.25} fontWeight={600}>
          Firmware
        </Typography>

        <Typography mb={0.25}>
          Version: <strong data-id="firmware-version">{firmware}</strong>
        </Typography>

        {renderUpdate()}
      </Popover>
    </>
  );
}
