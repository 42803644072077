import React from 'react';
import {Button, ButtonProps, Stack, Typography} from '@mui/material';
import {EventTitle} from 'app/components/sharedReactComponents/Events/shared/EventTitle/EventTitle';
import {DataId, Sx} from 'app/types/common';
import {EventCountdown} from 'app/components/sharedReactComponents/Events/shared/EventCountdown/EventCountdown';
import {router} from 'app/router/admin';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';
import {EventIcon} from 'app/components/sharedReactComponents/Events/shared/EventIcon/EventIcon';
import {Schedule} from 'app/domain/schedule';
import {getEventVariant} from 'app/domain/schedule/utils';
import {PausedBadge} from 'app/components/sharedReactComponents/Events/shared/PausedBadge/PausedBadge';
import {packSx} from 'app/util/packSx/packSx';
import {getVariant} from 'app/components/sharedReactComponents/Events/utils';
import {getOverMessage} from 'app/components/sharedReactComponents/Events/shared/EventCountdown/utils';

type Props = DataId &
  Sx & {
    event: Schedule.Event;
    deviceId: string;
    label?: string;
    showTime?: boolean;
    overLabel?: string;
  } & Pick<ButtonProps, 'variant' | 'color'>;

export function EventInfo({
  dataId,
  sx,
  deviceId,
  event,
  color,
  overLabel,
  showTime = false,
  label = '',
  variant = 'text',
}: Props) {
  const [master] = parseMasterAndChannelIds(deviceId);

  const eventVariant = getEventVariant(event.status);
  const paused = event.status === 'paused';

  const deviceDetailsUrl = router.url('deviceDetails', {deviceId: master}, {activeTab: 'events'});

  const completed = eventVariant === 'completed';

  return (
    <Button
      data-id={dataId}
      sx={packSx({height: 24, fontSize: 14, '&:focus': {outline: 'none'}}, sx)}
      variant={variant}
      color={color}
      href={deviceDetailsUrl}
    >
      <Stack direction="row" alignItems="center" gap={1} minWidth={0}>
        <EventIcon type={getVariant(event.status)} />

        <EventTitle
          sx={{color: 'text.primary', minWidth: 0}}
          dataId="event-title"
          text={event.title}
          rows={1}
        />

        {paused && <PausedBadge />}

        {showTime && !completed && (
          <EventCountdown
            dataId="event-timer"
            sx={{flexShrink: 0, color: getTimerTextColor(eventVariant)}}
            variant={eventVariant}
            start={event.start}
            end={event.end}
            overMessage={getOverMessage(eventVariant, event.start, overLabel)}
            label={
              label ? (
                <Typography noWrap={true} color="inherit">
                  {label}
                </Typography>
              ) : null
            }
          />
        )}
      </Stack>
    </Button>
  );
}

function getTimerTextColor(variant: Schedule.EventVariant) {
  if (variant === 'ongoing') {
    return 'error.main';
  }

  if (variant === 'scheduled') {
    return 'primary.main';
  }

  return 'text.primary';
}
