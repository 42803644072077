import {NOTIFICATION_ID} from 'app/components/AsideNotifications/constants';
import {NotificationBaseModel, NotificationModel, TokenModel} from 'app/components/AsideNotifications/types';

type NotificationCollection = Partial<Record<NOTIFICATION_ID, NotificationModel[]>>;

function collectNotificationsByTypes(notifications: NotificationBaseModel[]): NotificationCollection {
  const notificationsByTypes = {};

  for (const notification of notifications) {
    const type = notification.Type;
    if (!notificationsByTypes[type]) {
      notificationsByTypes[type] = [];
    }

    notificationsByTypes[type].push(notification);
  }

  return notificationsByTypes;
}

function collectNotificationsTokens(notifications: TokenModel[]): string[] {
  return notifications.map(({Token}) => Token);
}

export {
  collectNotificationsByTypes,
  collectNotificationsTokens,
};
