import React, {useEffect, useState} from 'react';
import {Box, Link, Stack, Typography} from '@mui/material';
import {groupDeviceAlertsByDate} from 'app/components/DevicesAlerts/utils';
import {DevicesAlertsDay} from 'app/components/DevicesAlerts/DevicesAlertsDay';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {Sx} from 'app/types/common';
import {DateAlerts} from 'app/components/DevicesAlerts/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {router} from 'app/router/main';
import {useTeamAlerts} from 'app/components/DevicesAlerts/hooks/useTeamAlerts';

interface Props extends Sx {
  teamId: string;
  device: AnyDeviceModelType;
  getSuggestion: (id: string) => string | undefined;
}

export function AlertsTab({sx, teamId, device, getSuggestion}: Props) {
  const [alertGroups, setAlertGroups] = useState<DateAlerts[]>([]);

  const deviceId = device.getId();
  const query = useTeamAlerts({teamId});

  useEffect(() => {
    const filtered = query.data?.filter((a) => a.deviceId.startsWith(deviceId)) ?? [];
    const grouped = groupDeviceAlertsByDate(filtered);
    setAlertGroups(grouped);
  }, [query.data, deviceId]);

  return (
    <Box sx={sx}>
      <Typography variant="h5" fontWeight={600} mb={1}>
        Device Alerts
      </Typography>

      <Typography mb={1}>
        <Link href={router.url('alerts')}>Manage alerts</Link> to select what alerts you would like
        to receive.
      </Typography>

      {query.isSuccess ? (
        <Stack gap={2}>
          {alertGroups.map((group, index) => (
            <DevicesAlertsDay
              key={group.date}
              group={group}
              showHeader={index === 0}
              getSuggestion={getSuggestion}
            />
          ))}
        </Stack>
      ) : (
        <LoadingPlaceholder />
      )}
    </Box>
  );
}
