import React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@mui/material';
import {Header} from 'app/components/FleetManager/Header';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {UnifyManager} from 'app/components/UnifyProjects/UnifyManager/UnifyManager';
import {useProjects} from 'app/components/UnifyProjects/hooks/useProjects';
import {useUnifyStreamingDestination} from 'app/components/UnifyProjects/hooks/useUnifyStreamingDestination';
import {noop} from 'app/util/noop';
import {useCurrentTeamStore} from 'app/store/hooks';
import {UnifyWelcome} from 'app/components/UnifyProjects/UnifyWelcome/UnifyWelcome';
import {useTeamBilling} from 'app/components/BillingManager/hooks/useTeamBilling';
import {useActualEvents} from 'app/components/sharedReactComponents/Events/hooks/useActualEvents';
import {useCoreEntities} from 'app/components/UserProvider/UserProvider';

function UnifyProjectsRoot() {
  const team = useCurrentTeamStore();
  const {user} = useCoreEntities();
  const {unify, cloud, actions} = useTeamBilling();

  const teamId = team.id;
  const capabilities = team.getCapabilities();

  const {role} = user;

  const hasUnify = capabilities.unify();
  const hasSchedule = capabilities.cms();
  const hasPremium = cloud.premium;

  const {loading, projects, refetchProjects, getProject} = useProjects({
    teamId,
    enabled: hasUnify,
  });

  const actualEvents = useActualEvents({teamId, enabled: hasSchedule});

  const {streamingDestinations, onChangeStreamingDestination} =
    useUnifyStreamingDestination(refetchProjects);

  if (!hasUnify || !unify) {
    return (
      <div className="container">
        <UnifyWelcome
          sx={{mt: 4}}
          canManage={role.canEditBilling()}
          onManage={() => actions.manageUnify()}
        />
      </div>
    );
  }

  return (
    <Box>
      <Header showSearch={false} tools={{pair: false}} searchValue="" onSearch={noop} />

      {loading ? (
        <IndeterminateProgressBar />
      ) : (
        <UnifyManager
          teamId={teamId}
          role={role}
          projects={projects}
          actualEvents={actualEvents}
          hasPremium={hasPremium}
          price={unify.tier.hourlyRate}
          streamingDestinations={streamingDestinations}
          teamCapabilities={capabilities}
          onReload={refetchProjects}
          getById={getProject}
          onChangeStreamingDestination={onChangeStreamingDestination}
        />
      )}
    </Box>
  );
}

export const UnifyProjects = observer(UnifyProjectsRoot);
