import {front} from 'app/api/CirrusApi';
import {UserContract} from 'app/contracts/user';

export class UserApiService {
  static async getUser(): Promise<Contracts.Core.User> {
    return front.users().me().get();
  }

  static async getAgreement(): Promise<UserContract.Agreement> {
    return front.users().agreement().get();
  }

  static async getPolicy(): Promise<UserContract.Policy> {
    return front.users().policy().get();
  }
}
