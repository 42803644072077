import React, {useCallback, useRef} from 'react';
import {DeviceStreamingPanel} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingPanel';
import {useStreamingDestinations} from 'app/components/FleetManager/useStreamingDestinations';
import {useDevicePublisherSelection} from 'app/components/sharedReactComponents/ActionButtons/useDevicePublisherSelection';
import {useDeviceStreamingDestinationSelection} from 'app/components/sharedReactComponents/ActionButtons/useDeviceStreamingDestinationSelection';
import {useDeviceWarnings} from 'app/components/sharedReactComponents/ActionButtons/useDeviceWarnings';
import {uniqueNewName} from 'app/util/newName';
import {useScrollToAnchor} from 'app/components/sharedReactComponents/ScrollToAnchor/useScrollToAnchor';
import {ClassName} from 'app/types/common';
import {streamingServicesRepository} from 'app/components/StreamingServices';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {useCoreEntities} from 'app/components/UserProvider/UserProvider';

interface Props extends ClassName {
  device: AnyDeviceModelType;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
}

export function DeviceStreamingPanelContainer({className, device, getDeviceById}: Props) {
  const {user} = useCoreEntities();
  const containerRef = useRef<HTMLDivElement | null>(null);

  // TODO: Update with #setStreamingInfo (add #resetStreamingInfo)
  const {streamingDestinations, streamingDestinationsLoading, reloadStreamingDestinations} =
    useStreamingDestinations({
      device,
    });

  const {destinations: streamingDestinationWarnings, publisher: publisherWarnings} =
    useDeviceWarnings(device);

  const {
    selectedStreamingDestinations,
    selectingStreamingDestinations,
    handleStreamingDestinationSelect,
  } = useDeviceStreamingDestinationSelection({
    device,
    streamingDestinations,
  });

  useScrollToAnchor({
    ref: containerRef,
    name: 'streamingDestinations',
    disabled: streamingDestinationsLoading,
  });

  const {selectedPublishers, selectingPublishers, handlePublisherSelect} =
    useDevicePublisherSelection({
      device,
    });

  const handleClickStartSelectedStreaming = useCallback(async () => {
    return device.startStreaming();
  }, [device]);

  const handleClickStopAllStreaming = useCallback(async () => {
    return device.stopStreaming();
  }, [device]);

  const handleStreamingDestinationCreate = useCallback(
    async (serviceType) => {
      const service = streamingServicesRepository.getServiceByType(serviceType);

      if (!service) {
        return;
      }

      const newName = uniqueNewName(
        service.getName() ?? '',
        streamingDestinations.map((a) => a.getName()),
      );

      return (
        service
          .createService(newName)
          // TODO: Mark created streaming destination item
          // .then((name) => createdName = name)
          .finally(() => {
            reloadStreamingDestinations();
          })
      );
    },
    [reloadStreamingDestinations, streamingDestinations],
  );

  const handleStreamingDestinationDelete = useCallback(() => {
    reloadStreamingDestinations();
  }, [reloadStreamingDestinations]);

  const checkStreamingDestinationAvailableToSelect = useCallback(
    (streamingDestinationType) => {
      const value = device.checkStreamingDestinationAvailableToUse(streamingDestinationType);

      return {
        value,
        reasonMessage: value ? '' : `Unavailable for ${device.getModelName() as string} devices`,
      };
    },
    [device],
  );

  let warningsCount = device.getFailedPublishersCount();
  if (streamingDestinationWarnings.length > 0) {
    warningsCount += 1;
  }

  const offline = device.isOffline();

  return (
    <DeviceStreamingPanel
      panelRef={containerRef}
      className={className}
      deviceId={device.getId()}
      streamingCount={device.getStreamingDestinationStreamingCount()}
      warningsCount={warningsCount}
      publishers={device.getPublishers()}
      selectedPublishers={selectedPublishers}
      publisherWarnings={publisherWarnings}
      streamingDestinations={streamingDestinations}
      selectedStreamingDestinations={selectedStreamingDestinations}
      streamingDestinationWarnings={streamingDestinationWarnings}
      offline={offline}
      started={offline ? false : device.isStreamingStarted()}
      selecting={selectingStreamingDestinations || selectingPublishers}
      editable={user.role.canEditStreamingDestinations()}
      checkStreamingDestinationAvailableToSelect={checkStreamingDestinationAvailableToSelect}
      getDeviceById={getDeviceById}
      onPublisherSelect={handlePublisherSelect}
      onStreamingDestinationSelect={handleStreamingDestinationSelect}
      onClickStartSelectedStreaming={handleClickStartSelectedStreaming}
      onClickStopAllStreaming={handleClickStopAllStreaming}
      onStreamingDestinationCreate={handleStreamingDestinationCreate}
      onStreamingDestinationDelete={handleStreamingDestinationDelete}
    />
  );
}
