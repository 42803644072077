import React, {useMemo} from 'react';
import {
  Box,
  Card,
  Collapse,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import {green, grey} from '@mui/material/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {DataId, StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Edge} from 'app/domain/edge';
import {StatusIndicator} from 'app/components/features/edge/EdgeDevice/StatusIndicator/StatusIndicator';
import {DeviceHealthIndicator} from 'app/components/sharedReactComponents/DeviceHealthIndicator/DeviceHealthIndicator';
import {PerformanceIndicator} from 'app/components/features/edge/EdgeDevice/PerformanceIndicator/PerformanceIndicator';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {RemoteLoginButton} from 'app/components/sharedReactComponents/RemoteLogin';
import {Schedule} from 'app/domain/schedule';
import {isScheduled} from 'app/domain/schedule/utils';
import {EventInfo} from 'app/components/sharedReactComponents/Events/shared/EventInfo/EventInfo';
import {router} from 'app/router/main';
import {StorageIndicator} from 'app/components/features/edge/EdgeDevice/StorageIndicator/StorageIndicator';
import {UploadingIndicator} from 'app/components/features/edge/EdgeDevice/UploadingIndicator/UploadingIndicator';
import {DeviceChannels} from 'app/components/features/edge/EdgeDevice/body/DeviceChannels/DeviceChannels';
import {EdgeCheckbox} from 'app/components/features/edge/EdgeDevice/EdgeCheckbox/EdgeCheckbox';
import {getPreviewUpdateStrategy} from 'app/components/sharedReactComponents/Preview/utils';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {DeviceMenu} from 'app/components/features/edge/EdgeDevice/DeviceMenu/DeviceMenu';
import {Notifications} from 'app/components/Notifications';
import {cardStyles} from 'app/components/features/edge/styles/card.styles';
import {LivescryptDetails} from 'app/components/features/edge/EdgeDevice/body/LivescryptDetails/LivescryptDetails';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';
import {WebcasterDetails} from 'app/components/features/edge/EdgeDevice/body/WebcasterDetails/WebcasterDetails';
import {FirmwareIndicator} from 'app/components/features/edge/EdgeDevice/FirmwareIndicator/FirmwareIndicator';
import {DeviceStatus} from 'app/domain/edge/edge.domain';
import {CopyButton} from 'app/components/features/edge/shared/CopyButton/CopyButton';

const rootSx: StyleSx = packSx(
  {py: 1, pl: 0.25, pr: 1, bgcolor: alpha(grey[100], 0.75)},
  cardStyles.root,
);
const onlineSx: StyleSx = {bgcolor: alpha(green[50], 0.15), borderColor: green[200]};
const buttonSx: StyleSx = {py: 0.5, px: 1};
const iconButtonSx: StyleSx = packSx(buttonSx, {minWidth: 24});
const copyButtonSx: StyleSx = packSx(buttonSx, {fontSize: 14});
const checkboxSx: StyleSx = {m: 0};
const togglerSx: StyleSx = {transform: 'rotate(180deg)'};
const eventSx: StyleSx = packSx({minWidth: 0, minHeight: 31, maxWidth: 300}, buttonSx);
const bodySx: StyleSx = {mt: 1};
const singleBodySx: StyleSx = packSx(bodySx, {ml: 1.25});

type Props = Sx &
  DataId & {
    device: AnyDeviceModelType;
    groups: Edge.Group[];
    presets: Edge.TeamPreset[];
    open: boolean;
    premium: boolean;
    billingAccess: boolean;
    streamsAccess: boolean;
    streamingDestinations: AnyStreamingDestinationModelType[];
    permitReboot: boolean;
    permitPresets: boolean;
    detailed: boolean;
    event?: Schedule.Event;
    getDeviceById: (deviceId: string) => AnyDeviceModelType | undefined;
    checkSelection: (deviceId: string) => boolean;
    toggleSelection: (device: AnyDeviceModelType) => void;
    onApplyPreset: (deviceId: string, preset: Edge.TeamPreset) => Promise<void>;
    onDelete: (deviceId: string) => Promise<void>;
    onMoveToGroup: (deviceId: string, groupId: string) => Promise<void>;
    onMoveFromGroup: (deviceId: string, groupId: string) => Promise<void>;
    toggleOpen: (deviceId: string) => void;
  };

export const EdgeDevice = React.memo<Props>(
  ({
    dataId,
    sx,
    device,
    groups,
    open,
    event,
    premium,
    permitReboot,
    permitPresets,
    billingAccess,
    streamsAccess,
    streamingDestinations,
    detailed,
    presets,
    getDeviceById,
    checkSelection,
    toggleSelection,
    onApplyPreset,
    onDelete,
    onMoveToGroup,
    onMoveFromGroup,
    toggleOpen,
  }: Props) => {
    const hasDetails = areDetailsAvailable(device);

    const deviceId = device.getId();
    const model = device.getModelName();
    const online = device.isOnline();
    const groupId = device.getGroupId();
    const ip = device.getIPAddress() ?? '';
    const status = device.getStatus() as DeviceStatus;

    const selected = checkSelection(deviceId);

    const streaming = device.isStreaming();
    const recording = device.isRecording();
    const transcribing = device.isTranscribing();

    const detailsUrl = getDetailsUrl(device);

    const {allSelected, partlySelected} = useChannelSelection(device, checkSelection);

    const highlighted = selected || allSelected || partlySelected;

    const isWebcaster = ModelService.isWebcaster(model);
    const isNano = ModelService.isNano(model);
    const isLvs = ModelService.isLivescrypt(model);
    const isPearl = ModelService.isPearlFamily(model);

    const activeIndicators = isPearl && online;

    const disabledSwitcher = isNano || isWebcaster || isLvs;
    const warnings = device.getWarnings();

    const availableGroups = useMemo(
      () => (premium ? groups.filter((g) => g.id !== groupId) : []),
      [premium, groups, groupId],
    );

    const expanded = hasDetails ? open : false;

    const showIndicators = detailed && online;

    const showWarnings = online && warnings.length > 0;

    const handleApplyPreset = async (preset: Edge.TeamPreset) => onApplyPreset(deviceId, preset);

    const handleDelete = async () => onDelete(deviceId);

    const handleMoveToGroup = async (groupId: string) => onMoveToGroup(deviceId, groupId);

    const handleMoveFromGroup = async () => {
      if (groupId) {
        await onMoveFromGroup(deviceId, groupId);
      }
    };

    const handleUpgrade = async () => {
      try {
        await device.sendUpdateFirmwareCommand();
      } catch (e) {
        Notifications.addErrorNotification('Failed to start device upgrade.');
        throw e;
      }
    };

    const handleReboot = async () => {
      try {
        await device.sendRebootCommand();
      } catch (e) {
        Notifications.addErrorNotification('Failed to reboot device.');
        throw e;
      }
    };

    const infoFields = (
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography data-id="device_card_model" width={80} textAlign="center">
          {model}
        </Typography>

        <CopyButton
          dataId="device_card_serialNumber"
          sx={copyButtonSx}
          value={device.getSerialNumber()}
          tooltip="Serial number"
          variant="text"
          color="inherit"
        />

        <CopyButton
          dataId="device_card_ipAddress"
          sx={copyButtonSx}
          value={ip}
          tooltip="IP address"
          variant="text"
          color="inherit"
        />
      </Stack>
    );

    const renderSection = () => {
      if (isLvs) {
        return (
          <LivescryptDetails
            sx={singleBodySx}
            selected={selected}
            device={device as LiveScryptDeviceModel}
          />
        );
      }

      const previewType = getPreviewUpdateStrategy(device);

      if (ModelService.isMultiChannel(model)) {
        return (
          <DeviceChannels
            sx={bodySx}
            master={device as PearlMasterDeviceModel}
            premium={premium}
            previewType={previewType}
            billingAccess={billingAccess}
            streamsAccess={streamsAccess}
            streamingDestinations={streamingDestinations}
            getDeviceById={getDeviceById}
            checkSelection={checkSelection}
            toggleSelection={toggleSelection}
          />
        );
      }

      return (
        <WebcasterDetails
          sx={singleBodySx}
          device={device}
          selected={selected}
          previewType={previewType}
        />
      );
    };

    return (
      <Card
        data-id={dataId}
        sx={packSx(rootSx, online && onlineSx, highlighted && cardStyles.selected, sx)}
        variant="outlined"
        tabIndex={0}
      >
        <Stack direction="row" alignItems="center" gap={0.5}>
          <EdgeCheckbox
            data-id="device_card_checkbox"
            sx={checkboxSx}
            billingAccess={billingAccess}
            premium={premium}
            checked={selected}
            indeterminate={partlySelected}
            disableRipple={false}
            onClick={() => toggleSelection(device)}
          />

          <Box minWidth={0} flex={1} fontWeight={600} fontSize={16}>
            <Link
              color="inherit"
              sx={{color: online ? 'text.primary' : 'text.disabled'}}
              href={detailsUrl}
              underline="none"
            >
              <OverflowTooltip dataId="device_card_name">{device.getName()}</OverflowTooltip>
            </Link>
          </Box>

          {event && (
            <EventInfo
              dataId="event-indicator"
              sx={eventSx}
              event={event}
              showTime={true}
              deviceId={deviceId}
              variant="text"
              label={isScheduled(event.status) ? 'in' : undefined}
              overLabel="at"
            />
          )}

          {!online && infoFields}

          <StatusIndicator
            sx={{px: 0.5}}
            status={status}
            recording={recording}
            streaming={streaming}
            transcribing={transcribing}
          />

          {showWarnings && <DeviceHealthIndicator warnings={warnings} offline={!online} />}

          {online && (
            <>
              <Tooltip title={online && disabledSwitcher && `${model} does not support switcher`}>
                <span>
                  <RemoteLoginButton
                    sx={iconButtonSx}
                    type="switcher"
                    deviceId={deviceId}
                    size="small"
                    paymentAccess={billingAccess}
                    premium={premium}
                    labeled={true}
                    disabled={!online || disabledSwitcher}
                  />
                </span>
              </Tooltip>

              <Tooltip title={online && isWebcaster && `${model} does not support admin login`}>
                <span>
                  <RemoteLoginButton
                    sx={iconButtonSx}
                    type="admin"
                    deviceId={deviceId}
                    size="small"
                    paymentAccess={billingAccess}
                    premium={premium}
                    labeled={true}
                    disabled={!online || isWebcaster}
                  />
                </span>
              </Tooltip>
            </>
          )}

          <DeviceMenu
            device={device}
            groups={availableGroups}
            presets={presets}
            premium={premium}
            permitReboot={permitReboot}
            permitPresets={permitPresets}
            onApplyPreset={handleApplyPreset}
            onDelete={handleDelete}
            onReboot={handleReboot}
            onMoveToGroup={handleMoveToGroup}
            onMoveFromGroup={handleMoveFromGroup}
          />

          <IconButton
            data-id="device_card_toggler"
            disabled={!hasDetails}
            onClick={() => toggleOpen(deviceId)}
          >
            <KeyboardArrowDownIcon sx={packSx(expanded && togglerSx)} />
          </IconButton>
        </Stack>

        {showIndicators && (
          <Stack mt={1} ml={1.25} direction="row" alignItems="center" gap={1}>
            <Stack direction="row" alignItems="center" gap={1} mr="auto">
              <FirmwareIndicator
                deviceId={deviceId}
                firmware={device.getFirmwareVersion()}
                checkUpdates={activeIndicators}
                onUpgrade={handleUpgrade}
              />

              <StorageIndicator deviceId={deviceId} enabled={activeIndicators} />

              <UploadingIndicator deviceId={deviceId} enabled={activeIndicators} />

              <PerformanceIndicator deviceId={deviceId} enabled={activeIndicators} />
            </Stack>

            {infoFields}
          </Stack>
        )}

        <Collapse in={expanded}>{renderSection()}</Collapse>
      </Card>
    );
  },
);

function areDetailsAvailable(device: AnyDeviceModelType): boolean {
  const online = device.isOnline();

  if (ModelService.isMultiChannel(device.getModelName())) {
    return (device as PearlMasterDeviceModel).hasChannels() && online;
  }

  return online;
}

function getDetailsUrl(device: AnyDeviceModelType): string {
  const deviceId = device.getId();

  if (ModelService.isLivescrypt(device.getModelName())) {
    return router.url('transcribeDeviceDetails', {deviceId});
  }

  return router.url('deviceDetails', {deviceId});
}

function useChannelSelection(device: AnyDeviceModelType, checkSelection: (id: string) => boolean) {
  return useMemo(() => {
    let partlySelected = false;
    let allSelected = false;

    const model = device.getModelName();

    if (ModelService.isMultiChannel(model)) {
      const channels = (device as PearlMasterDeviceModel).getChanelModels();

      if (channels.length > 0) {
        const selectedChannels = channels.filter((channel) => checkSelection(channel.getId()));

        allSelected = selectedChannels.length === channels.length;
        partlySelected = selectedChannels.length > 0 && !allSelected;
      }
    }

    return {partlySelected, allSelected};
  }, [device, checkSelection]);
}
