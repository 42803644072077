import React from 'react';
import {Skeleton, Stack} from '@mui/material';
import {Sx} from 'app/types/common';

type Props = Sx & {
  lines?: number;
};
export function RecordingsSkeleton({sx, lines = 5}: Props) {
  return (
    <Stack sx={sx} flex={1} direction="column" gap={1}>
      {createRangeArray(lines).map((m) => (
        <Skeleton key={m} variant="rounded" animation="wave" height={60} />
      ))}
    </Stack>
  );
}

function createRangeArray(n: number) {
  return Array.from({length: n}, (_, i) => i);
}
