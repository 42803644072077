import {useEffect, useMemo, useState} from 'react';
import {front} from 'app/api/CirrusApi';
import {WS} from 'app/api/WebSocket/WS';
import {useDeviceStatusStartCommand} from 'app/hooks/Device/useDeviceStatusStartCommand';
import {useUpdatePreviewByInterval} from 'app/hooks/Device/Preview/useUpdatePreviewByInterval';
import {createStampedImageSrc} from 'app/components/sharedReactComponents/StatusImage/utils';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {WSDeviceSourceImageDto} from 'app/api/WebSocket/types';
import {PreviewUpdateStrategy} from 'app/hooks/Device/Preview/types';

interface Params {
  device: PearlMasterDeviceModel;
  sourceId: string;
}

export function useDeviceSourcePreview({
  device,
  sourceId,
}: Params) {
  const deviceId = device.getId();

  const updateStrategy = useMemo(() => {
    const capabilities = device.getCapabilities();
    if (capabilities.supportsWsApi()) {
      return PreviewUpdateStrategy.WsApi;
    }

    if (capabilities.supportsSourcePreviews()) {
      return PreviewUpdateStrategy.Ws;
    }

    return PreviewUpdateStrategy.Interval;
  }, [device]);

  const initialImageSrc = useMemo<string>(() => {
    if (updateStrategy === PreviewUpdateStrategy.WsApi) {
      return front.devices(deviceId).source(sourceId).preview().url();
    }

    return front.devices(deviceId).source(sourceId).snapshot().url();
  }, [deviceId, sourceId, updateStrategy]);

  const [imageSrc, setImageSrc] = useState(initialImageSrc);

  useDeviceStatusStartCommand({
    command: async () => device.sendSourcePreviewsStartCommand(),
    disabled: updateStrategy !== PreviewUpdateStrategy.Ws,
  });

  useEffect(() => {
    if (updateStrategy !== PreviewUpdateStrategy.Ws) {
      return;
    }

    function handleDeviceStatusImage(data: WSDeviceSourceImageDto) {
      setImageSrc(createStampedImageSrc(initialImageSrc, data.Timestamp));
    }

    WS.onDeviceSourceImage(handleDeviceStatusImage, deviceId, sourceId);

    return () => WS.offDeviceSourceImage(handleDeviceStatusImage, deviceId, sourceId);
  }, [deviceId, sourceId, updateStrategy, initialImageSrc]);

  useUpdatePreviewByInterval({
    initialImageSrc,
    disabled: updateStrategy === PreviewUpdateStrategy.Ws,
    setImageSrc,
  });

  return imageSrc;
}
