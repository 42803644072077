import {AppTeam} from 'app/models/AppTeam/AppTeam';
import {TeamUser} from 'app/models/User/TeamUser';
import {Owner} from 'app/models/PermissionsModel/Owner';
import {Admin} from 'app/models/PermissionsModel/Admin';
import {Editor} from 'app/models/PermissionsModel/Editor';
import {Operator} from 'app/models/PermissionsModel/Operator';

function mapAppTeam(c: Contracts.Core.Team): AppTeam {
  return new AppTeam({
    id: c.TeamID,
    name: c.TeamName,
    role: mapUserRole(c.UserRole, c.IsMaster),
  });
}

function mapUserRole(role: Contracts.Core.Role, maser: boolean) {
  switch (role) {
    case 'owner':
      if (maser) {
        return new Owner();
      }
      return new Admin();

    case 'editor':
      return new Editor();

    case 'operator':
      return new Operator();

    default:
      throw new Error(`Unknown role: ${role}`);
  }
}

function mapTeamUser(c: Contracts.Core.User): TeamUser {
  return new TeamUser({
    id: c.ID,
    name: c.Name,
    email: c.Email,
    privateTeam: c.UserPrivateTeam,
    teamId: c.CurrentTeam,
    teamName: c.TeamName,
    role: mapUserRole(c.CurrentRole, c.IsMaster),
    picture: c.Picture,
    checks: {
      agreement: c.IsAgreementConfirmed,
      policy: c.IsPolicyConfirmed,
    },
  });
}

export const AppMapper = {
  mapAppTeam,
  mapTeamUser,
};
