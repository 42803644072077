import {ApiResponseNumeralStatus, ApiResponseStatus} from 'app/data/Status';

export type Endpoints = Record<string, EndpointData | Record<string, EndpointData>>;
export type EndpointData = {
  __noteam__?: boolean;
  __url__?: string;
  __nocache__?: boolean;
  __protocol__?: string;
} & Record<string, any>;

export type AbortCallback = () => void;
export type GetAbortCallback = (abort: AbortCallback) => void;

export type AjaxErrorCallback = (error) => void;

export type AjaxComplexSuccessfulResponseType<T = {}> = T & {
  Status: ApiResponseStatus;
  StatusCode: ApiResponseNumeralStatus;
};

export type ErrorResponse = {
  data: {
    Status: 'error';
    StatusCode: number;
    Error?: string;
    ErrorData?: Record<string, any>;
  };
};

export function isErrorResponse(e: unknown): e is ErrorResponse {
  const response = e as ErrorResponse;
  return Boolean(response.data?.Error) || Boolean(response.data?.ErrorData);
}

export function getErrorContents(e: ErrorResponse): string {
  if (e.data.ErrorData) {
    return Object.values(e.data.ErrorData).join(' ');
  }

  if (e.data.Error) {
    return e.data.Error;
  }

  return '';
}
