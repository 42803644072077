import React from 'react';
import {Box, Stack} from '@mui/material';
import {grey} from '@mui/material/colors';
import VideocamIcon from '@mui/icons-material/Videocam';
import {StyleSx, Sx} from 'app/types/common';
import {Filmstrip} from 'app/components/features/recordings/shared/RecordingCard/RecordingThumbnail/Filmstrip/Filmstrip';
import {packSx} from 'app/util/packSx/packSx';

const rootSx: StyleSx = {borderRadius: 0.5, aspectRatio: '320 / 180'};

type Props = Sx & {
  thumbnail?: string;
  filmstrip?: string;
  width: number;
};

export function RecordingThumbnail({sx, thumbnail, filmstrip, width}: Props) {
  const styles = packSx(rootSx, sx);

  if (thumbnail && filmstrip)
    return <Filmstrip sx={styles} width={width} filmstrip={filmstrip} thumbnail={thumbnail} />;

  if (thumbnail) return <Box sx={styles} component="img" src={thumbnail} width={width} />;

  return (
    <Stack
      sx={styles}
      bgcolor={grey[200]}
      width={width}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <VideocamIcon color="info" fontSize="large" />
    </Stack>
  );
}
