import React from 'react';
import {router} from 'app/router/main';
import {cirrusApi} from 'app/api/CirrusApi';
import {Callback} from 'app/types/common';

interface Props {
  teamId: string;
  teamName: string;
  onClick: Callback;
}

export const TeamUrl: React.VFC<Props> = ({teamId, teamName, onClick}) => {
  return (
    <a
      href={router.urlToTeamRoute(teamId, router.url('accountSettings'))}
      onClick={(e) => {
        e.preventDefault();
        onClick();

        if (teamId === cirrusApi.getCurrentTeamId()) {
          router.go('accountSettings');
        } else {
          router.goToTeamRoute(teamId, router.url('accountSettings'));
        }
      }}
    >
      {teamName}
    </a>
  );
};
