import React from 'react';
import {Box, Stack, Tooltip, Typography} from '@mui/material';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import {Component, Sx} from 'app/types/common';
import {isNil} from 'app/util/isNil';
import {palette} from 'app/themes/app';
import {Maintenance} from 'app/domain/maintenance';

interface Props extends Component, Sx {
  state: Maintenance.State;
  compact: boolean;
}

export function NavigationMaintenance({sx, state, compact}: Props) {
  const {planned} = state;

  if (isNil(planned)) {
    return null;
  }

  const date = new Date(planned.start * 1000);

  const time = date.toLocaleDateString('en', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  });

  const content = (
    <Box>
      <Typography data-id="maintenance-msg" component="strong" fontWeight={600}>
        {planned.summary}
      </Typography>
      <Typography data-id="maintenance-time" fontSize={12}>
        {time}
      </Typography>
    </Box>
  );

  return (
    <Tooltip title={compact ? content : null}>
      <Stack
        sx={sx}
        data-id="maintenance-summary"
        px={2}
        py={1}
        direction="row"
        alignItems="center"
        gap={1}
      >
        <BuildCircleIcon sx={{color: palette.orange, fontSize: 22}} />
        {!compact && content}
      </Stack>
    </Tooltip>
  );
}
