import React from 'react';
import {TableCell, tableCellClasses, TableCellProps} from '@mui/material';
import {packSx} from 'app/util/packSx/packSx';
import {palette} from 'app/themes/app';

export function InvoiceCell({sx, ...rest}: TableCellProps) {
  return (
    <TableCell
      sx={packSx(
        {
          [`&.${tableCellClasses.head}`]: {
            fontWeight: 'bold',
            color: palette.darkerGray,
          },
          [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
          },
        },
        sx,
      )}
      {...rest}
    />
  );
}
