import React, {useState} from 'react';
import classNames from 'classnames';
import {partial} from 'ramda';
import {Avatar} from 'app/components/sharedReactComponents/Avatar';
import {TeamMemberStatus} from 'app/data/Status';
import {UserRoleSelector} from 'app/components/Teams/UsersPanel/UserRoleSelector';
import {Icons} from 'app/util/icons';
import {Button} from 'app/components/sharedReactComponents/Button';
import {UserLoginType} from 'app/constants';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {DeletePrompt} from 'app/components/sharedReactComponents/Modal/DeletePrompt';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {User} from 'app/models/User/UserModel';
import {Callback, ClassName} from 'app/types/common';
import {useIsMounted} from 'app/hooks/useIsMounted';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';

interface Props extends ClassName {
  user: User;
  currentUserId: string;
  currentUserRole: AnyUserRoleModel;
  changeOwner: Callback;
  onUpdate: Callback;
  onDelete: Callback;
}

export const UsersPanelListItem = ({
  className,
  user,
  currentUserId,
  currentUserRole,
  changeOwner,
  onUpdate,
  onDelete,
}: Props) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const mountedRef = useIsMounted();
  const userId = user.getId();
  const userName = user.getName();

  const handleRoleChange = async (e) => {
    setUpdating(true);
    const newRole = e.target.value;

    try {
      await onUpdate(userId, newRole);
    } finally {
      if (mountedRef.current) {
        setUpdating(false);
      }
    }
  };

  const handleClickDelete = () => {
    DeletePrompt.show({
      title: `Are you sure you want to remove "${userName}" team member?`,
      action: async () => {
        try {
          await onDelete(userId);
        } finally {
          if (mountedRef.current) {
            setUpdating(false);
          }
        }
      }
    });
  };

  const isPending = user.getStatus() === TeamMemberStatus.Pending;
  const canDelete = currentUserRole.canEditTeams() && userId !== currentUserId && !user.isMaster();
  const canChangeRole = canDelete && !isPending;

  let onChangeOwner;
  if (currentUserRole.canEditTeamOwner() && !isPending) {
    onChangeOwner = partial(changeOwner, [userId, userName]);
  }

  const hasEmail = (user.getLoginType() !== UserLoginType.Facebook) && user.getEmail();

  return (
    <div
      className={classNames('users-panel-list-item', className)}
      data-id="users_list_item"
    >
      {user.getPicture() && (
        <Avatar
          className="users-panel-list-item__avatar"
          src={user.getPicture()}
        />
      )}

      <OverflowTooltip
        className="users-panel-list-item__name"
        dataId="item_name"
      >
        {userName}
      </OverflowTooltip>

      {isPending && (
        <div
          className="users-panel-list-item__status"
          data-id="item_status"
        >
          (pending)
        </div>
      )}

      {hasEmail && (
        <OverflowTooltip
          className="users-panel-list-item__email"
          dataId="item_email"
        >
          {user.getEmail()}
        </OverflowTooltip>
      )}

      <div
        className="users-panel-list-item__role"
        data-id="item_role"
      >
        <UserRoleSelector
          value={user.getRole()}
          loading={updating}
          master={user.isMaster()}
          disabled={!canChangeRole}
          onChange={handleRoleChange}
          onChangeOwner={onChangeOwner}
        />
      </div>

      <div
        className="users-panel-list-item__remove-btn-placeholder"
        data-id="remove_item_button"
      >
        {canDelete && (
          <Tooltip content="Remove team member">
            <Button
              className="users-panel-list-item__remove-btn"
              onClick={handleClickDelete}
            >
              {Icons.remove().reactComponent()}
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
