import {useQuery} from '@tanstack/react-query';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';

interface Args {
  deviceId: string;
  enabled: boolean;
  staleTime?: number;
  refetchInterval?: number;
}

export function useDeviceUploading({deviceId, enabled, refetchInterval, staleTime}: Args) {
  return useQuery({
    queryKey: [`device-afu`, deviceId],
    queryFn: async () => {
      const response = await DeviceApiService.getAfu(deviceId);
      return response;
    },
    refetchInterval,
    staleTime,
    enabled,
  });
}
