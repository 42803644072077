import React, {useCallback} from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {ListSearchFilter} from 'app/components/Dashboard/ListSearchFilter';
import {Buttons} from 'app/components/Dashboard/DashboardListHeader/Buttons';
import {Callback, ClassName} from 'app/types/common';
import {useMaintenance} from 'app/components/Maintenance/useMaintenance';

interface Props extends ClassName {
  filterValue: string;
  buttonsComponent: React.ReactNode;
  onFilter: Callback;
  showSearch: boolean;
}

export const DashboardListHeader: React.VFC<Props> = observer(
  ({className, filterValue, buttonsComponent, showSearch, onFilter}) => {
    const {inHeader} = useMaintenance();

    const handleFilterChange = useCallback(
      ({target: {value}}) => {
        onFilter(value);
      },
      [onFilter],
    );

    const handleFilterClear = useCallback(() => {
      onFilter('');
    }, [onFilter]);

    return (
      <div
        className={classNames(
          'dashboard-list-header',
          inHeader && 'dashboard-list-header--banner',
          className,
        )}
      >
        <div className="dashboard-list-header__container">
          <div className="dashboard-list-header__content container">
            <div className="dashboard-list-header__filter">
              {showSearch && (
                <ListSearchFilter
                  value={filterValue}
                  onChange={handleFilterChange}
                  onClear={handleFilterClear}
                />
              )}
            </div>

            <Buttons buttonsComponent={buttonsComponent} />
          </div>
        </div>
      </div>
    );
  },
);
