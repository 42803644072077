import {useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {WS} from 'app/api/WebSocket/WS';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {useDeviceId} from 'app/hooks/Device/useDeviceId';
import {AnyDeviceModelType, createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {Ws} from 'app/contracts/ws';
import {
  setChannelWarnings,
  setDesiredState,
  setDeviceGroup,
  setPublishers,
  setUnitStatus,
} from 'app/components/features/edge/utils';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';

interface Args {
  deviceId: string;
}

type Return = {
  loading: boolean;
  master?: AnyDeviceModelType;
  channel?: AnyDeviceModelType;
  channelIdx: string;
};

export function useDevice({deviceId}: Args): Return {
  const [loading, setLoading] = useState(true);
  const [master, setMaster] = useState<AnyDeviceModelType | undefined>();
  const [channel, setChannel] = useState<PearlSlotDeviceModel | undefined>();

  const [masterId, channelIdx] = useDeviceId(deviceId);

  const deviceQuery = useDeviceQuery(masterId);

  useEffect(() => {
    if (deviceQuery.isLoading) {
      setLoading(true);
      return;
    }

    if (deviceQuery.isError) {
      setLoading(false);
      setMaster(undefined);
      setChannel(undefined);
    }
  }, [deviceQuery.isError, deviceQuery.isLoading]);

  useEffect(() => {
    if (deviceQuery.isSuccess) {
      const master = deviceQuery.data;

      setMaster(master);
      setLoading(false);
    }
  }, [deviceQuery.data, deviceQuery.isSuccess]);

  useEffect(() => {
    if (channelIdx && master instanceof PearlMasterDeviceModel) {
      const channel = master.getChannelDeviceById(`${masterId}-${channelIdx}`);
      setChannel(channel);
      return;
    }

    setChannel(undefined);
  }, [master, masterId, channelIdx]);

  useEffect(() => {
    const onGroupChange = (message: Ws.GroupChange) => {
      const {Action: action} = message.Body;

      if (action === 'device_removed' || action === 'device_added') {
        const {DeviceID: deviceId} = message.Body;

        if (deviceId !== masterId) {
          return;
        }

        setMaster((prev) => {
          if (!prev) {
            return prev;
          }

          const copy = prev.getInnerModel();

          if (action === 'device_removed') {
            const updated = createDeviceModel(setDeviceGroup(copy, '', ''));
            return updated;
          }

          if (action === 'device_added') {
            const {GroupID, GroupName} = message.Body;
            const updated = createDeviceModel(setDeviceGroup(copy, GroupID, GroupName));
            return updated;
          }
        });
      }
    };

    WS.onDeviceGroupChange(onGroupChange);

    return () => {
      WS.offDeviceGroupChange(onGroupChange);
    };
  }, [masterId]);

  useEffect(() => {
    const onStatusChange = (message: Ws.DeviceStatusChange) => {
      const {DeviceID: deviceId} = message.Body;

      if (masterId !== deviceId) {
        return;
      }

      setMaster((prev) => {
        if (!prev) {
          return prev;
        }

        const copy = prev.getInnerModel();
        const updated = setUnitStatus(copy, message);
        return createDeviceModel(updated);
      });
    };

    const onPublishersChange = (message: Ws.DevicePublishers) => {
      const {DeviceID: deviceId} = message.Body;

      if (masterId !== deviceId) {
        return;
      }

      setMaster((prev) => {
        if (!prev) {
          return prev;
        }

        const copy = prev.getInnerModel();
        const updated = setPublishers(copy, message.Body.Payload);
        return createDeviceModel(updated);
      });
    };

    const onStateChange = (message: Ws.DeviceDesiredState) => {
      const {DeviceID: deviceId} = message.Body;

      if (masterId !== deviceId) {
        return;
      }

      setMaster((prev) => {
        if (!prev) {
          return prev;
        }

        const copy = prev.getInnerModel();
        const updated = setDesiredState(copy, message.Body.Payload);
        return createDeviceModel(updated);
      });
    };

    const onWarningsChange = (message: Ws.DeviceWarnings) => {
      const {DeviceID: deviceId} = message.Body;

      const [id, channelIdx] = parseMasterAndChannelIds(deviceId);

      if (masterId !== id) {
        return;
      }

      setMaster((prev) => {
        if (!prev) {
          return prev;
        }

        const copy = prev.getInnerModel();

        if (channelIdx) {
          const updated = setChannelWarnings(copy, deviceId, message.Body.Payload);
          return createDeviceModel(updated);
        }

        return createDeviceModel({...copy, Warnings: message.Body.Payload});
      });
    };

    const onDeleteDevice = (message: Ws.DeviceRemove) => {
      const {DeviceID: deviceId} = message.Body;

      if (masterId !== deviceId) {
        return;
      }

      setMaster(undefined);
    };

    const onRenameDevice = (message: Ws.DeviceRename) => {
      const {DeviceID: unitId} = message.Body;

      const [id, channelIdx] = parseMasterAndChannelIds(unitId);

      if (masterId !== id) {
        return;
      }

      setMaster((prev) => {
        if (!prev) {
          return prev;
        }

        const copy = prev.getInnerModel();

        if (channelIdx) {
          const channels = (copy.Child ?? []).map((channel) =>
            channel.Id === unitId ? {...channel, Name: message.Body.Payload.Name} : channel,
          );

          return createDeviceModel({...copy, Child: channels});
        }

        return createDeviceModel({...copy, Name: message.Body.Payload.Name});
      });
    };

    const onDeviceChange = (message: Ws.DeviceChange) => {
      if (masterId !== message.Body.DeviceID) {
        return;
      }

      const instance = createDeviceModel(message.Body.Payload);

      setMaster(instance);
    };

    WS.on(EVENT_TYPE.DEVICE_CHANGE, onDeviceChange);
    WS.on(EVENT_TYPE.DEVICE_STATUS, onStatusChange);
    WS.on(EVENT_TYPE.DEVICE_DESIRED_STATE, onStateChange);
    WS.on(EVENT_TYPE.DEVICE_PUBLISHERS, onPublishersChange);
    WS.on(EVENT_TYPE.DEVICE_WARNINGS, onWarningsChange);
    WS.on(EVENT_TYPE.DEVICE_RENAME, onRenameDevice);
    WS.on(EVENT_TYPE.UNPAIR_DEVICE, onDeleteDevice);
    WS.on(EVENT_TYPE.REMOVE_DEVICE, onDeleteDevice);

    return () => {
      WS.off(EVENT_TYPE.DEVICE_CHANGE, onDeviceChange);
      WS.off(EVENT_TYPE.DEVICE_STATUS, onStatusChange);
      WS.off(EVENT_TYPE.DEVICE_DESIRED_STATE, onStateChange);
      WS.off(EVENT_TYPE.DEVICE_PUBLISHERS, onPublishersChange);
      WS.off(EVENT_TYPE.DEVICE_WARNINGS, onWarningsChange);
      WS.off(EVENT_TYPE.DEVICE_RENAME, onRenameDevice);
      WS.off(EVENT_TYPE.UNPAIR_DEVICE, onDeleteDevice);
      WS.off(EVENT_TYPE.REMOVE_DEVICE, onDeleteDevice);
    };
  }, [masterId]);

  return {master, channel, loading, channelIdx};
}

function useDeviceQuery(id: string) {
  return useQuery({
    queryKey: ['device', id],
    queryFn: async () => {
      const contract = await DeviceApiService.retrieveDevice(id);
      return createDeviceModel(contract);
    },
    retry: false,
  });
}
