import React, {Fragment, useCallback} from 'react';
import classNames from 'classnames';
import {CreateGroupPrompt} from 'app/components/libs/dialogs/CreateGroupPrompt';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {Button} from 'app/components/sharedReactComponents/Button';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {Callback, ClassName} from 'app/types/common';
import {useDeviceGroups} from 'app/hooks/useDeviceGroups';

const getOptionLabel = (item) => item.name;
const getOptionValue = (item) => item.id;

interface Props extends ClassName {
  value: string | undefined;
  name: string;
  teamId: string;
  disabled: boolean;
  onChange: Callback;
}

export const DeviceGroupSelector: React.VFC<Props> = ({
  className,
  value,
  name,
  teamId,
  disabled,
  onChange,
}) => {
  const {groups, refetch, loading} = useDeviceGroups({teamId, enabled: true});

  const handleClickCreateNewGroup = useCallback(() => {
    CreateGroupPrompt.show({
      teamId,
      onDone: ({id}) => {
        onChange({
          target: {
            name,
            value: id,
          },
        });

        return refetch();
      },
    });
  }, [name, onChange, refetch, teamId]);

  const handleChange = useCallback(
    (event) => {
      onChange(event);
    },
    [onChange],
  );

  if (loading) {
    return <LoadingPlaceholder className="pair-device-group-selector__loading-placeholder" />;
  }

  return (
    <FormControl
      data-id="device_group_selector"
      className={classNames('pair-device-group-selector', className)}
      value={value}
      name={name}
      label={
        <Fragment>
          Device group
          <Button
            dataId="device_group_selector_create_new_group_button"
            className="pair-device-group-selector__create-new-group-btn"
            disabled={disabled}
            onClick={handleClickCreateNewGroup}
          >
            + Create new group
          </Button>
        </Fragment>
      }
      placeholder="None"
      type={FORM_CONTROL_TYPE.SELECT}
      selectComponentProps={{
        options: groups,
        getOptionLabel,
        getOptionValue,
      }}
      disabled={disabled}
      clearable={true}
      fullWidth={true}
      onChange={handleChange}
    />
  );
};
