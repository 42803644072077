import React, {Fragment, useState} from 'react';
import {AsideNotification} from 'app/components/AsideNotifications/AsideNotification';
import {TeamUrl} from 'app/components/AsideNotifications/AsideNotification/TeamUrl';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {Callback} from 'app/types/common';
import {PaymentMethod} from 'app/components/sharedReactComponents/PaymentForm/constants';
import {
  PaymentCardData,
  PaymentInvoiceData,
} from 'app/components/sharedReactComponents/PaymentForm/PaymentForm';

interface Props {
  data: {
    teamId: string;
    teamName: string;
    token: string;
    fromUserName: string;
  };
  closeAction: Callback;
  onAccept: Callback<
    Promise<void>,
    [string, PaymentMethod?, (PaymentCardData | PaymentInvoiceData)?]
  >;
  onDecline: Callback;
}

export const BillingOwnerChangeNotification: React.VFC<Props> = ({
  data,
  closeAction,
  onAccept,
  onDecline,
}) => {
  const {teamId, teamName, token, fromUserName} = data;

  const [inProgress, setInProgress] = useState(false);

  const renderTeamUrl = () => <TeamUrl teamId={teamId} teamName={teamName} onClick={closeAction} />;

  return (
    <AsideNotification
      dataId="billing_ownership_change_notification"
      buttons={
        <Fragment>
          <ProgressButton
            dataId="accept_button"
            theme={THEME.PRIMARY}
            variant={VARIANT.SOLID}
            size={SIZE.S}
            loading={inProgress}
            onClick={() => {
              closeAction();
              setInProgress(true);
              onAccept(token).finally(() => setInProgress(false));
            }}
          >
            Accept transfer request
          </ProgressButton>

          <ProgressButton
            dataId="decline_button"
            theme={THEME.SECONDARY}
            variant={VARIANT.SOLID}
            size={SIZE.S}
            loading={inProgress}
            onClick={() => {
              setInProgress(true);
              onDecline(token).finally(() => setInProgress(false));
            }}
          >
            Decline transfer request
          </ProgressButton>
        </Fragment>
      }
      footnote="* If ownership transfer is accepted, your role will change to Owner"
    >
      Would you like to accept ownership of "{renderTeamUrl()}" sent to you by{' '}
      <strong>{fromUserName}</strong>?
    </AsideNotification>
  );
};
