import React from 'react';
import {Box, Stack} from '@mui/material';
import {Cloud} from 'app/domain/cloud';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Sx} from 'app/types/common';
import {isAlertDisabled} from 'app/components/Settings/Alerts/AlertSettings/utils';
import {AlertName} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertTableHeader/AlertName';

interface Props extends Sx {
  deviceAlerts: Cloud.AlertSettings[];
  alertTypes: Cloud.AlertType[];
  getAlertByType: (type: Cloud.AlertType) => Cloud.Alert | undefined;
  onHoverAlert: (type?: Cloud.AlertType) => void;
  onToggleAlert: (type: Cloud.AlertType, value: boolean) => void;
}

export function AlertTableHeader({
  sx,
  alertTypes,
  deviceAlerts,
  onToggleAlert,
  onHoverAlert,
  getAlertByType,
}: Props) {
  return (
    <Stack sx={sx} direction="row" alignItems="center">
      <Box sx={{flex: 1}}>
        <OverflowTooltip rowsNumber={2}>Devices</OverflowTooltip>
      </Box>

      <Stack direction="row">
        {alertTypes.map((alertType) => {
          const hasUnchecked = deviceAlerts.some((deviceAlert) => {
            return !deviceAlert.settings[alertType] && !isAlertDisabled(deviceAlert, alertType);
          });

          const alert = getAlertByType(alertType);

          if (alert) {
            return (
              <AlertName
                alertType={alertType}
                alert={alert}
                key={alertType}
                hasUnchecked={hasUnchecked}
                onHover={onHoverAlert}
                onToggle={onToggleAlert}
              />
            );
          }
          return null;
        })}
      </Stack>
    </Stack>
  );
}
