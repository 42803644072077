import {Device} from 'app/components/DeviceDetails/Models/types';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';
import {EdgeContract} from 'app/contracts/edge';
import {Ws} from 'app/contracts/ws';
import {Edge} from 'app/domain/edge';

export function setPublishers(origin: Device, payload: Record<string, Edge.Publisher[]>): Device {
  const channels = origin.Child;

  if (channels) {
    const newChannels = channels.map((channel) => {
      const [, channelIdx] = parseMasterAndChannelIds(channel.Id);

      const publishers = payload[channelIdx];

      if (publishers) {
        return {...channel, Publishers: publishers};
      }

      return channel;
    });

    return {...origin, Child: newChannels};
  }

  return origin;
}

export function setStreamingState(
  origin: EdgeContract.DesiredState[],
  payload: Ws.StreamingPayload,
): EdgeContract.DesiredState[] {
  let hasState = false;

  const states = origin.map((state) => {
    if (state.Type === 'streaming') {
      hasState = true;
      return {...state, Params: payload.Params, StateTime: payload.Timestamp};
    }

    return state;
  });

  if (!hasState) {
    states.push({Type: 'streaming', StateTime: payload.Timestamp, Params: payload.Params});
  }

  return states;
}

export function setRecordingState(
  origin: EdgeContract.DesiredState[],
  payload: Ws.RecordingPayload,
): EdgeContract.DesiredState[] {
  let hasState = false;

  const states = origin.map((state) => {
    if (state.Type === 'recording') {
      hasState = true;
      return {...state, Params: payload.Params, StateTime: payload.Timestamp};
    }

    return state;
  });

  if (!hasState) {
    states.push({Type: 'recording', StateTime: payload.Timestamp, Params: payload.Params});
  }

  return states;
}

export function setTranscribingState(
  origin: EdgeContract.DesiredState[],
  payload: Ws.TranscribingPayload,
): EdgeContract.DesiredState[] {
  let hasState = false;

  const states = origin.map((state) => {
    if (state.Type === 'transcribing') {
      hasState = true;
      return {...state, Params: payload.Params, StateTime: payload.Timestamp};
    }

    return state;
  });

  if (!hasState) {
    states.push({Type: 'transcribing', StateTime: payload.Timestamp, Params: payload.Params});
  }

  return states;
}

export function setDesiredState(origin: Device, payload: Ws.DesireStatePayload): Device {
  const channelIdx = payload.Channel;
  const type = payload.Type;

  if (origin.Child) {
    const channels = origin.Child.map((channel) => {
      const [, index] = parseMasterAndChannelIds(channel.Id);

      if (index === `${channelIdx}`) {
        switch (type) {
          case 'streaming': {
            return {
              ...channel,
              DesiredState: setStreamingState(channel.DesiredState ?? [], payload),
            };
          }

          case 'recording': {
            return {
              ...channel,
              DesiredState: setRecordingState(channel.DesiredState ?? [], payload),
            };
          }

          case 'transcribing': {
            return {
              ...channel,
              DesiredState: setTranscribingState(channel.DesiredState ?? [], payload),
            };
          }

          default:
            return channel;
        }
      }

      return channel;
    });

    if (type === 'transcribing') {
      return {...origin, DesiredState: setTranscribingState(origin.DesiredState ?? [], payload)};
    }

    return {...origin, Child: channels};
  }

  return origin;
}

export function setChannelWarnings(
  origin: Device,
  channelId: string,
  payload: Edge.Warning[],
): Device {
  if (origin.Child) {
    const channels = origin.Child.map((channel) => {
      if (channel.Id === channelId) {
        return {...channel, Warnings: payload};
      }

      return channel;
    });

    return {...origin, Child: channels};
  }

  return origin;
}

export function setUnitStatus(origin: Device, payload: Ws.DeviceStatusChange): Device {
  const status = payload.Body.Status;

  if (status === '') {
    return {...origin, Status: 'online'};
  }

  if (status !== 'holding_legacy') {
    return {...origin, Status: status};
  }

  return {...origin};
}

export function setDeviceGroup(origin: Device, groupId: string, groupName: string) {
  return {...origin, GroupId: groupId, GroupName: groupName};
}
