import {action, autorun, computed, makeObservable, observable} from 'mobx';
import {RootStore} from 'app/store/RootStore';
import {buildUserRole} from 'app/models/PermissionsModel/Fabric';
import {RepeatedCall} from 'app/util/RepeatedCall';
import {minutesToMilliseconds, secondsToMilliseconds} from 'app/util/timeConverter';
import {front} from 'app/api/CirrusApi';
import {UserAgreementPrompt} from 'app/components/libs/dialogs/UserAgreementPrompt';
import {CurrentUserData} from 'app/store/User/types';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';

class CurrentUserStore {
  @observable initialized = false;
  @observable id = '';
  @observable name = '';
  @observable email = '';
  @observable privateTeamId = '';
  @observable isAgreementConfirmed = false;
  @observable currentRole: AnyUserRoleModel | undefined;

  root: RootStore;

  private readonly repeatedCall: RepeatedCall;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.root = rootStore;

    this.repeatedCall = new RepeatedCall({
      call: async () => {
        return this.load();
      },
      initialTimeoutMs: secondsToMilliseconds(60),
      maxTimeoutMs: minutesToMilliseconds(2),
    });

    autorun(() => {
      if (rootStore.currentTeam.initialized) {
        this.init();
      }
    });
  }

  @computed
  get role() {
    if (!this.currentRole) {
      throw new Error('Initialize store before read');
    }

    return this.currentRole;
  }

  private checkAgreementIsConfirmed() {
    if (this.isAgreementConfirmed === false) {
      front
        .users()
        .agreement()
        .get()
        .then((agreement) => {
          UserAgreementPrompt.show({
            content: agreement.Agreement,
            onDone: async (): Promise<any> => {
              return front.users().agreement().post({
                AgreementID: agreement.AgreementID,
                AgreementTime: agreement.AgreementTime,
              });
            },
          });
        });
    }
  }

  private async load(): Promise<void> {
    return front
      .users()
      .me()
      .get()
      .then(
        action((response: CurrentUserData) => {
          this.id = response.ID;
          this.name = response.Name;
          this.email = response.Email ?? '';
          this.privateTeamId = response.UserPrivateTeam;
          this.isAgreementConfirmed = response.IsAgreementConfirmed;

          this.currentRole = buildUserRole(
            response.CurrentRole ?? response.UserRole,
            response.IsMaster,
          );

          this.initialized = true;
        }),
      );
  }

  private async init() {
    return this.repeatedCall.start(true).then(() => {
      this.checkAgreementIsConfirmed();
    });
  }
}

export {CurrentUserStore};
