import React from 'react';
import {AsideNotification} from 'app/components/AsideNotifications/AsideNotification';
import {TeamUrl} from 'app/components/AsideNotifications/AsideNotification/TeamUrl';
import {Callback} from 'app/types/common';
import {BillingUsageLink} from 'app/components/sharedReactComponents/BillingUsageLink';

interface Props {
  data: {
    teamId: string;
    teamName: string;
  };
  closeAction: Callback;
}

export const InvoicePaymentFailedNotification: React.VFC<Props> = ({
  data: {
    teamId,
    teamName,
  },
  closeAction,
}) => {
  const renderTeamUrl = () => (
    <TeamUrl
      teamId={teamId}
      teamName={teamName}
      onClick={closeAction}
    />
  );

  return (
    <AsideNotification dataId="invoice_payment_failed_notification">
      To continue using your premium account for team "{renderTeamUrl()}",
      please update <BillingUsageLink onClickLink={closeAction}>billing information</BillingUsageLink>
    </AsideNotification>
  );
};
