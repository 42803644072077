import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {router} from 'app/router/main';
import {groupDeviceAlertsByDate} from 'app/components/DevicesAlerts/utils';
import {DevicesAlertsSidebarDay} from 'app/components/DevicesAlerts/DevicesAlertsSidebar/DevicesAlertsSidebarDay';
import {Button} from 'app/components/sharedReactComponents/Button';
import {DateAlerts} from 'app/components/DevicesAlerts/types';
import {useUnreadAlerts} from 'app/components/DevicesAlerts/AlertsCounter';

export function DevicesAlertsSidebar() {
  const {alerts, acknowledgeSince} = useUnreadAlerts();

  const [alertGroups, setAlertGroups] = useState<DateAlerts[]>([]);

  useEffect(() => {
    setAlertGroups(groupDeviceAlertsByDate(alerts));
  }, [alerts]);

  return (
    <Box maxHeight="85vh" width={462} p={1.5} className="devices-alerts-sidebar">
      <div className="devices-alerts-sidebar__header">
        <span>Latest Alerts</span>

        <a className="devices-alerts-sidebar__settings-link" href={router.url('alerts')}>
          {Icons.settings().size(SIZE.M).reactComponent()}
        </a>
      </div>

      <div className="devices-alerts-sidebar__days">
        {alertGroups.map((group) => (
          <DevicesAlertsSidebarDay
            key={group.date}
            group={group}
            onAcknowledgeAlerts={acknowledgeSince}
          />
        ))}
        {alertGroups.length === 0 && (
          <div className="devices-alerts-sidebar__no-alerts">No Alerts</div>
        )}
      </div>

      <div className="devices-alerts-sidebar__footer">
        <Button
          className="devices-alerts-sidebar__button"
          onClick={() => {
            router.go('devicesAlerts');
          }}
        >
          View all Alerts
        </Button>
      </div>
    </Box>
  );
}
