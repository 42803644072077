import {useEffect, useState} from 'react';
import {RouteDefinition} from 'app/components/Navigation/types';
import {TeamUser} from 'app/models/User/TeamUser';
import {CurrentTeamStore} from 'app/store/Team/CurrentTeamStore';
import {useFeature} from 'app/util/ApplicationFeatures';

export interface NavRoutes {
  edge: RouteDefinition[];
  settings: RouteDefinition[];
}

export type NavSection = keyof NavRoutes | undefined;

interface Args {
  enabled: boolean;
  user: TeamUser;
  team: CurrentTeamStore;
}

// TODO: remove enabled when root app storages are done
export function useNavRoutes({enabled, user, team}: Args): NavRoutes {
  const [routes, setRoutes] = useState<NavRoutes>({edge: [], settings: []});

  const edgePremium = team.canAccessRoute('events');
  const permitBilling = user.role.canEditBilling();
  const filesFeatures = useFeature('files');

  useEffect(() => {
    if (!enabled) {
      setRoutes({edge: [], settings: []});
      return;
    }

    const edge: RouteDefinition[] = [
      {
        label: 'All Devices',
        route: 'devices',
        access: true,
      },
      {
        label: 'LiveScrypt',
        route: 'transcribeDevices',
        access: true,
      },
      {
        label: 'Schedule',
        route: 'events',
        access: edgePremium,
      },
      {
        label: 'Recordings',
        route: 'recordings',
        access: edgePremium && filesFeatures,
      },
    ];

    const settings: RouteDefinition[] = [
      {
        label: 'Manage alerts',
        route: 'alerts',
        access: true,
      },
      {
        label: 'Streaming destinations',
        route: 'streamingDestinations',
        access: true,
      },
      {
        label: 'Configuration presets',
        route: 'presets',
        access: true,
      },
      {
        label: 'Epiphan Connect',
        route: 'connectAccountSettings',
        access: true,
      },
      {
        label: 'Billing and usage',
        route: 'settingBillingUsage',
        access: permitBilling,
      },
    ];

    setRoutes({edge, settings});
  }, [edgePremium, permitBilling, enabled, filesFeatures]);

  return routes;
}
