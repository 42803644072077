import React, {useState} from 'react';
import dayjs from 'dayjs';
import {Sx, TimestampRange} from 'app/types/common';
import {UnitRecordings} from 'app/components/features/recordings/UnitRecordings/UnitRecordings';
import {useUnitRecordings} from 'app/components/features/recordings/hooks/useUnitRecordings';
import {packSx} from 'app/util/packSx/packSx';

type Props = Sx & {
  teamId: string;
  deviceId: string;
};

export function RecordingsTab({sx, teamId, deviceId}: Props) {
  const [range, setRange] = useState<TimestampRange>({
    from: dayjs().subtract(7, 'days').startOf('day').unix(),
    to: dayjs().add(7, 'days').endOf('day').unix(),
  });

  const {
    data: recordings,
    isSuccess,
    refetch,
  } = useUnitRecordings({enabled: true, teamId, deviceId, range});

  return (
    <UnitRecordings
      sx={packSx({flex: 1}, sx)}
      loading={!isSuccess}
      recordings={recordings ?? []}
      range={range}
      setRange={setRange}
      reload={refetch}
    />
  );
}
