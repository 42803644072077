import {useQuery} from '@tanstack/react-query';
import {AlertsApiService} from 'app/services/api/alerts/AlertsApiService';
import {AlertMapper} from 'app/services/mappers/AlertMapper/AlertMapper';

interface Args {
  teamId: string;
}

export function useTeamAlerts({teamId}: Args) {
  return useQuery({
    queryKey: ['team-alerts', teamId],
    queryFn: async () => {
      const response = await AlertsApiService.getTeamAlerts();
      return response.map(AlertMapper.mapUnitAlert);
    },
  });
}
