import React, {useState} from 'react';
import dayjs from 'dayjs';
import {filesize} from 'filesize';
import {Card, Checkbox, Grid, IconButton, Stack} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {DataId, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {Files} from 'app/domain/files';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {RecordingDevice} from 'app/components/features/recordings/shared/RecordingCard/RecordingDevice';
import {DeleteRecordingsDialog} from 'app/components/features/recordings/dialogs/DeleteRecordingsDialog/DeleteRecordingsDialog';
import {DownloadRecordingsDialog} from 'app/components/features/recordings/dialogs/DownloadRecordingsDialog/DownloadRecordingsDialog';
import {RecordingName} from 'app/components/features/recordings/shared/RecordingCard/RecordingName/RecordingName';
import {RecordingThumbnail} from 'app/components/features/recordings/shared/RecordingCard/RecordingThumbnail/RecordingThumbnail';

type Props = Sx &
  DataId & {
    selected: boolean;
    recording: Files.Recording;
    device?: AnyDeviceModelType;
    showUnit?: boolean;
    onSelect: () => void;
    onDelete: () => Promise<void>;
    onDownload: () => Promise<void>;
  };

export function RecordingCard({
  dataId,
  sx,
  selected,
  recording,
  showUnit,
  device,
  onSelect,
  onDelete,
  onDownload,
}: Props) {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [downloadDialog, setDownloadDialog] = useState(false);

  const handleDelete = async () => {
    await onDelete();
    setDeleteDialog(false);
  };

  const handleDownload = async () => {
    await onDownload();
    setDownloadDialog(false);
  };

  return (
    <Card
      data-id={dataId}
      sx={packSx(
        {py: 0.5, height: 60, display: 'flex'},
        selected && {borderColor: 'primary.main'},
        sx,
      )}
      variant="outlined"
    >
      <Grid container={true} columns={24} columnGap={1} alignItems="center">
        <Grid xs={3} item={true}>
          <Stack direction="row" gap={0.5} alignItems="center">
            <Checkbox
              data-id="recording-checkbox"
              disableRipple={false}
              checked={selected}
              onChange={() => onSelect()}
            />

            <RecordingThumbnail
              thumbnail={recording.thumbnailUrl}
              filmstrip={recording.filmstripUrl}
              width={80}
            />
          </Stack>
        </Grid>

        <Grid xs={true} item={true} minWidth={0}>
          <RecordingName
            sx={{maxWidth: '100%'}}
            name={recording.name}
            editable={false}
            onSave={() => {}}
          />
        </Grid>
        <Grid xs={3} item={true} data-id="recording-date">
          {formateDate(recording.startAt)}
        </Grid>
        <Grid xs={2} item={true} data-id="recording-time">
          {formateTime(recording.startAt)}
        </Grid>
        <Grid xs={2} item={true} data-id="recording-size">
          {filesize(recording.size)}
        </Grid>

        {showUnit && (
          <Grid xs={3} item={true}>
            <RecordingDevice dataId="recording-device-link" device={device} />
          </Grid>
        )}
        <Grid xs={2} item={true} justifyContent="flex-end" gap={0.5}>
          <IconButton
            data-id="recording-download-btn"
            disabled={!recording.uploaded}
            disableRipple={false}
            onClick={() => setDownloadDialog(true)}
          >
            <CloudDownloadIcon />
          </IconButton>

          <IconButton
            data-id="recording-delete-btn"
            disableRipple={false}
            onClick={() => setDeleteDialog(true)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>

      <DeleteRecordingsDialog
        open={deleteDialog}
        recordings={[recording.name]}
        onDelete={handleDelete}
        onClose={() => setDeleteDialog(false)}
      />

      <DownloadRecordingsDialog
        recordings={[recording.name]}
        open={downloadDialog}
        onDownload={handleDownload}
        onClose={() => setDownloadDialog(false)}
      />
    </Card>
  );
}

function formateDate(datetime: number): string {
  return dayjs.unix(datetime).format('MMM D, YYYY');
}

function formateTime(datetime: number): string {
  return dayjs.unix(datetime).format('h:mm A');
}
