import {useEffect, useState} from 'react';
import {Files} from 'app/domain/files';
import {useDebounce} from 'react-use';
import {containsSearchString} from 'app/components/FleetManager/utils';

type Args = {
  recordings: Files.Recording[];
  sortFn: (a: Files.Recording, b: Files.Recording) => number;
};

export function useFilterRecordings({recordings, sortFn}: Args) {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [filtered, setFiltered] = useState<Files.Recording[]>(recordings);

  const searchTrim = search.trim();

  useDebounce(
    () => {
      setDebouncedSearch(searchTrim);
    },
    200,
    [searchTrim],
  );

  useEffect(() => {
    let copy = [...recordings];

    if (debouncedSearch) {
      copy = copy.filter((r) => containsSearchString(r.name, debouncedSearch));
    }

    setFiltered(copy.sort(sortFn));
  }, [debouncedSearch, recordings, sortFn]);

  return {filtered, search, setSearch};
}
