/* eslint-disable @typescript-eslint/member-ordering */
import {RootStore} from 'app/store/RootStore';
import {action, makeObservable, observable} from 'mobx';
import {CurrentTeamTracker} from 'app/util/CurrentTeamTracker';
import {cirrusApi} from 'app/api/CirrusApi';
import {MainRoutes, router} from 'app/router/main';
import {loadCurrentTeamId} from 'app/store/Team/utils';
import {Dispatcher} from 'app/data/Dispatcher';
import {ActionTypes} from 'app/data/ActionTypes';
import {createTeamDetailsModel} from 'app/models/Team/Fabric';
import {TeamDetailsModel} from 'app/models/Team/TeamDetailsModel';
import {teamApiService} from 'app/services/api/team/TeamApiService';
import {RepeatedCall} from 'app/util/RepeatedCall';
import {minutesToMilliseconds, secondsToMilliseconds} from 'app/util/timeConverter';

class CurrentTeamStore {
  @observable initialized = false;
  @observable id = '';
  @observable name = '';

  @observable private _model: TeamDetailsModel | undefined;

  private readonly repeatedCall: RepeatedCall;

  root: RootStore;

  private readonly tracker: CurrentTeamTracker;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.root = rootStore;

    this.handleBillingPlanUpdate = this.handleBillingPlanUpdate.bind(this);

    this.tracker = new CurrentTeamTracker({
      api: cirrusApi,
      router,
    });

    this.repeatedCall = new RepeatedCall({
      call: async () => {
        return this.fetchTeamDetails();
      },
      initialTimeoutMs: secondsToMilliseconds(30),
      maxTimeoutMs: minutesToMilliseconds(2),
    });

    void this.init();
  }

  handleBillingPlanUpdate() {
    void this.repeatedCall.restart(true);
  }

  get model() {
    return this._model!;
  }

  @action
  setTeamName(name: string) {
    this.name = name;
  }

  @action
  async rename(name: string) {
    const previousName = this.name;
    this.name = name;

    return teamApiService.rename(this.id, name).catch(() => {
      this.name = previousName;
    });
  }

  getName() {
    return this.name;
  }

  getCapabilities() {
    return this.model.capabilities;
  }

  getCapabilitiesRaw() {
    return this.model.capabilitiesRaw;
  }

  getBillingEmail() {
    return this.model.billingEmail;
  }

  canAccessRoute(routeName: MainRoutes) {
    switch (routeName) {
      case 'recordings':
      case 'events':
        return this.getCapabilities().cms();
      case 'projectDetails':
        return this.getCapabilities().unify();
      case 'meetingDetails':
        return this.getCapabilities().connect();
      default:
        return true;
    }
  }

  private async init() {
    try {
      this.id = await loadCurrentTeamId(this.tracker);
      cirrusApi.setCurrentTeamId(this.id);
      Dispatcher.on(ActionTypes.TEAMS_TEAM_PLAN_UPDATE, this.handleBillingPlanUpdate);

      void this.repeatedCall.start();

      void this.fetchInitData().then(
        action(() => {
          this.initialized = true;
        }),
      );
    } catch (error: unknown) {
      console.log('Can not initialize team', error);
    }
  }

  private async fetchInitData() {
    return Promise.all([this.fetchTeamDetails()]);
  }

  private async fetchTeamDetails() {
    return teamApiService.fetchTeamDetails(this.id).then(
      action((team) => {
        const currentTeam = createTeamDetailsModel(team);
        this._model = currentTeam;
        this.name = currentTeam.name;
      }),
    );
  }
}

export {CurrentTeamStore};
