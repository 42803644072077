import React, {useEffect, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {Box, Button, Divider, Popover, Stack} from '@mui/material';
import {blue} from '@mui/material/colors';
import {
  DateCalendar,
  PickersDay,
  PickersDayProps,
  pickersMonthClasses,
  pickersYearClasses,
} from '@mui/x-date-pickers';
import {StyleSx, Sx, TimestampRange} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

const calendarSx: StyleSx = {
  [`& .${pickersYearClasses.yearButton}, & .${pickersMonthClasses.monthButton}`]: {
    display: 'block',
  },
};

const calendarViews = ['year', 'month', 'day'] as const;

const minDate = dayjs().year(2020).startOf('year');
const maxDate = dayjs().year(2050).endOf('year');

type Props = Sx & {
  range: TimestampRange;
  setRange: React.Dispatch<React.SetStateAction<TimestampRange>>;
};

export function RecordingsPeriod({sx, range, setRange}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [from, setFrom] = useState<Dayjs | null>(() => dayjs.unix(range.from));
  const [to, setTo] = useState<Dayjs | null>(dayjs.unix(range.to));

  const pickerOpen = Boolean(anchorEl);

  useEffect(() => {
    if (!pickerOpen && from && to) {
      setRange({from: from.unix(), to: to.unix()});
    }
  }, [pickerOpen, from, to, setRange]);

  const formatPeriod = () => {
    if (!from || !to) {
      return '';
    }

    const sameYear = from.isSame(to, 'year');

    return `${from.format(sameYear ? 'MMMM D' : 'LL')} - ${to.format('LL')}`;
  };

  return (
    <>
      <Button
        sx={packSx(sx)}
        variant="outlined"
        color="info"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {formatPeriod()}
      </Button>

      <Popover
        open={pickerOpen}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        onClose={() => setAnchorEl(null)}
      >
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem={true} />}>
          <DateCalendar
            sx={calendarSx}
            value={from}
            minDate={minDate}
            maxDate={to?.subtract(1, 'day')}
            showDaysOutsideCurrentMonth={true}
            disableHighlightToday={true}
            views={calendarViews}
            slots={{day: Day}}
            slotProps={{
              day: (props) => ({pos: calcPosition(props.day, from, to)} as any),
            }}
            onChange={(day) => setFrom(day)}
          />

          <DateCalendar
            sx={calendarSx}
            value={to}
            minDate={from?.add(1, 'day')}
            maxDate={maxDate}
            showDaysOutsideCurrentMonth={true}
            disableHighlightToday={true}
            views={calendarViews}
            slots={{day: Day}}
            slotProps={{
              day: (props) => ({pos: calcPosition(props.day, from, to)} as any),
            }}
            onChange={(day) => setTo(day)}
          />
        </Stack>
      </Popover>
    </>
  );
}

type Position = 'left' | 'right' | 'inside';

type DayProps = PickersDayProps<Dayjs> & {
  pos?: Position;
};

function Day({selected, pos, ...props}: DayProps) {
  return (
    <Box sx={packSx(getStyles(pos), {px: 0.25})}>
      <PickersDay {...props} selected={selected} disableRipple={false} disableMargin={true} />
    </Box>
  );
}

function getStyles(pos?: Position): StyleSx {
  const bgcolor = blue[50];

  switch (pos) {
    case 'left':
      return {bgcolor, borderTopLeftRadius: 16, borderBottomLeftRadius: 16};

    case 'right':
      return {bgcolor, borderTopRightRadius: 16, borderBottomRightRadius: 16};

    case 'inside':
      return {bgcolor};

    default: {
      return {};
    }
  }
}

function calcPosition(day: Dayjs, left: Dayjs | null, right: Dayjs | null): Position | undefined {
  if (!left || !right) {
    return undefined;
  }

  if (day.isSame(left, 'day')) {
    return 'left';
  }

  if (day.isSame(right, 'day')) {
    return 'right';
  }

  if (day.isBefore(right, 'day') && day.isAfter(left, 'day')) {
    return 'inside';
  }

  return undefined;
}
