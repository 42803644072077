import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {Owner} from 'app/models/PermissionsModel/Owner';
import {Admin} from 'app/models/PermissionsModel/Admin';
import {Editor} from 'app/models/PermissionsModel/Editor';
import {Operator} from 'app/models/PermissionsModel/Operator';

export function buildUserRole(role: Contracts.Core.Role, owner: boolean): AnyUserRoleModel {
  switch (role) {
    case 'owner':
      if (owner) {
        return new Owner();
      }
      return new Admin();

    case 'editor':
      return new Editor();

    case 'operator':
      return new Operator();

    default:
      throw new Error(`Unknown role: ${role}`);
  }
}
