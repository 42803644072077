import React from 'react';
import {MaintenanceMessage} from 'app/components/Maintenance/MaintenanceMessage/MaintenanceMessage';
import {useMaintenance} from 'app/components/Maintenance/useMaintenance';

export function MaintenanceHeader() {
  const {state, inHeader, minimize, showDetails} = useMaintenance();

  if (!inHeader) {
    return null;
  }

  return <MaintenanceMessage state={state} onClose={minimize} onDetails={showDetails} />;
}
