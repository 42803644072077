import {CloudContract} from 'app/contracts/cloud';
import {Cloud} from 'app/domain/cloud';
import {UnitAccess} from 'app/models/Capabilities/UnitAccess/UnitAccess';
import {Entries} from 'type-fest';

const actualCapabilities = new Set<Contracts.Cloud.Capability>([
  'recording',
  'streaming',
  'transcribing',
  'remote_login',
  'start_hls_stream',
  'use_cms',
  'use_preset',
  'batch_selection',
  'use_groups',
]);

function mapDeviceCapabilities(c: Contracts.Cloud.UnitCapabilities): UnitAccess {
  const entries = Object.entries(c) as Entries<Contracts.Cloud.UnitCapabilities>;

  const init = entries
    .filter((e) => {
      const [k] = e;
      return actualCapabilities.has(k);
    })
    .map((e) => {
      const [k, v] = e;
      return [mapCapabilityKey(k), v] as const;
    });

  const map = new Map<App.Cloud.UnitPermission, App.Cloud.UnitCapability>(init);

  return new UnitAccess(map);
}

function mapCapabilityKey(c: Contracts.Cloud.Capability): App.Cloud.UnitPermission {
  switch (c) {
    case 'remote_login': {
      return 'remote-login';
    }

    case 'use_preset': {
      return 'presets';
    }

    case 'start_hls_stream':
      return 'hls';

    case 'use_cms': {
      return 'cms';
    }

    case 'batch_selection': {
      return 'batch';
    }

    case 'use_groups': {
      return 'groups';
    }

    default:
      return c;
  }
}

function mapRtmpDestination(c: CloudContract.RtmpDestination): Cloud.RtmpDestination {
  return {
    id: c.StreamID,
    name: c.Name,
    type: c.Type,
    active: c.CurrentlyStreaming,
    ownedBy: c.LockByDevice,
    deleted: c.IsDeleted,
    lastModified: c.LastModified,
    rtmp: {
      key: c.RTMP.StreamingKey,
      name: c.RTMP.DestName,
      url: c.RTMP.URL,
    },
  };
}

function mapGoogleDestination(c: CloudContract.GoogleDestination): Cloud.GoogleDestination {
  const auth = c.OAuth2;

  return {
    id: c.StreamID,
    name: c.Name,
    type: c.Type,
    active: c.CurrentlyStreaming,
    ownedBy: c.LockByDevice,
    deleted: c.IsDeleted,
    lastModified: c.LastModified,
    auth: {
      service: 'google',
      login: auth.Login,
      userName: auth.UserName,
      externalId: auth.ExternalID,
      error: auth.Error,
      errorTime: auth.ErrorTime,
    },
  };
}

function mapDestination(
  c: CloudContract.StreamDestination,
): Cloud.StreamingDestination | undefined {
  if (c.Type === 'rtmp' || c.Type === 'rtmps') {
    return mapRtmpDestination(c);
  }

  if (c.Type === 'google') {
    return mapGoogleDestination(c);
  }

  return undefined;
}

export const CloudMapper = {mapDeviceCapabilities, mapDestination};
