import React from 'react';
import {observer} from 'mobx-react';
import {Box, Typography} from '@mui/material';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {useCurrentTeamStore, useAlertDictionary} from 'app/store/hooks';
import {useTeamBilling} from 'app/components/BillingManager/hooks/useTeamBilling';
import {useCoreEntities} from 'app/components/UserProvider/UserProvider';
import {AlertSettings} from 'app/components/Settings/Alerts/AlertSettings/AlertSettings';

export const Alerts = observer(() => {
  const currentTeamStore = useCurrentTeamStore();
  const {user} = useCoreEntities();
  const {cloud} = useTeamBilling();
  const dictionary = useAlertDictionary();

  const teamId = currentTeamStore.id;
  const teamName = currentTeamStore.name;

  return (
    <div>
      <PageHeader
        title={
          <Typography fontWeight={600} component="span" variant="h5">
            Manage alerts for <OverflowTooltip component="span">{teamName}</OverflowTooltip>
          </Typography>
        }
      />

      <Box my={2} className="container">
        <AlertSettings
          userEmail={user.email}
          premium={cloud.premium}
          teamId={teamId}
          getAlertByType={dictionary.getAlertByType}
        />
      </Box>
    </div>
  );
});
