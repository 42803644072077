import React from 'react';
import {DataId} from 'app/types/common';

interface Props extends DataId {
  buttons?: React.ReactNode;
  footnote?: React.ReactNode;
}

export const AsideNotification: React.FC<Props> = ({dataId, children, buttons, footnote}) => {
  return (
    <div data-id={dataId} className="cr-aside-notification">
      <div className="cr-aside-notification__main">{children}</div>

      {buttons && <div className="cr-aside-notification__buttons">{buttons}</div>}

      {footnote && <div className="cr-aside-notification__footnote">{footnote}</div>}
    </div>
  );
};
