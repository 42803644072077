import {useEffect, useState} from 'react';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Shared} from 'app/types/shared';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {DetailsTab} from 'app/components/DeviceDetails/DeviceInfoTab/types';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

type Args = {
  urlTab: string;
  device?: AnyDeviceModelType;
  edgePremium: boolean;
  filesFeature: boolean;
};

export function useDetailsTabs({urlTab, device, edgePremium, filesFeature}: Args) {
  const [tab, setTab] = useState(urlTab);
  const [tabs, setTabs] = useState<Shared.LabelValue<DetailsTab>[]>([]);

  useEffect(() => {
    setTab(urlTab);
  }, [urlTab]);

  useEffect(() => {
    if (!device) {
      setTabs([]);
      return;
    }

    const model = device.getModelName();
    const isMaster = device instanceof PearlMasterDeviceModel;
    const isWebcaster = ModelService.isWebcaster(model);
    const isLvs = ModelService.isLivescrypt(model);
    const isPearl = ModelService.isPearlFamily(model);
    const isUnify = ModelService.isUnify(model);

    const isOnline = device.isOnline();

    const hasPresets = device.getCapabilities().supportsPresets();
    const canRecord = device.getCapabilities().canRecording();

    const hasEvents = device.access.cms.enabled;
    const hasTranscriber = device.access.transcribing.enabled;

    const result: Shared.LabelValue<DetailsTab>[] = [
      {
        value: 'status',
        label: 'Device info',
      },
    ];

    if (isMaster) {
      result.push({
        value: 'channels',
        label: 'Channels',
      });

      if (isOnline && !isUnify) {
        result.push({
          value: 'inputs',
          label: 'Inputs',
        });
      }
    } else if (canRecord && !isWebcaster) {
      result.push({
        value: 'channels',
        label: 'Channel',
      });
    }

    if (isLvs && hasTranscriber) {
      result.push(
        {
          label: 'Output',
          value: 'output',
        },
        {
          value: 'audio',
          label: 'Audio',
        },
        {
          value: 'transcription',
          label: 'Transcription',
        },
        {
          value: 'session',
          label: 'Sessions',
        },
      );
    }

    if (hasEvents) {
      result.push({
        value: 'events',
        label: 'Events',
      });
    }

    result.push({
      value: 'alerts',
      label: 'Alerts',
    });

    if (hasPresets) {
      result.push({
        value: 'presets',
        label: 'Presets',
      });
    }

    if (filesFeature && edgePremium && isPearl) {
      result.push({
        value: 'recordings',
        label: 'Recordings',
      });
    }

    setTabs(result);
  }, [device, edgePremium, filesFeature]);

  return {tabs, tab, setTab};
}
