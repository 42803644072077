import React, {useEffect, useState} from 'react';
import {Box, ButtonBase, Typography, alpha, buttonBaseClasses} from '@mui/material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import {Sx} from 'app/types/common';
import {palette} from 'app/themes/app';
import {Dispatcher} from 'app/data/Dispatcher';
import {ActionTypes} from 'app/data/ActionTypes';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Sx {
  compact?: boolean;
}

interface State {
  total: number;
  unread: number;
}

interface Data {
  count: number;
}

export function NavNotifications({sx, compact}: Props) {
  const [state, setState] = useState<State>({total: 0, unread: 0});

  useEffect(() => {
    const setTotal = ({count}: Data) => {
      setState((p) => ({...p, total: count}));
    };

    const setUnread = ({count}: Data) => {
      setState((p) => ({...p, unread: count}));
    };

    const readAll = () => {
      setState((p) => ({...p, unread: 0}));
    };

    Dispatcher.on(ActionTypes.NOTIFICATIONS_COUNT, setTotal);
    Dispatcher.on(ActionTypes.NOTIFICATIONS_UNREAD, setUnread);
    Dispatcher.on(ActionTypes.NOTIFICATIONS_NO_UNREAD, readAll);

    return () => {
      Dispatcher.removeListener(ActionTypes.NOTIFICATIONS_COUNT, setTotal);
      Dispatcher.removeListener(ActionTypes.NOTIFICATIONS_UNREAD, setUnread);
      Dispatcher.removeListener(ActionTypes.NOTIFICATIONS_NO_UNREAD, readAll);
    };
  }, []);

  const handleView = () => {
    Dispatcher.emit(ActionTypes.UI_ASIDE_NOTIFICATIONS_TOGGLE, {});
  };

  const hasUnread = state.unread > 0;

  if (state.total === 0) {
    return null;
  }

  return (
    <Box px={1} sx={sx}>
      <ButtonBase
        data-id="nav-notifications"
        sx={packSx(
          {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderRadius: 1,
            gap: 1.5,
            p: 1,
            width: '100%',
            bgcolor: hasUnread ? palette.red : 'transparent',
            [`&:hover, &.${buttonBaseClasses.focusVisible}`]: {
              bgcolor: hasUnread ? alpha(palette.red, 0.9) : palette.dark1,
            },
          },
          sx,
        )}
        onClick={handleView}
      >
        <AnnouncementIcon />
        {!compact && (
          <Typography data-id="nav-notifications-count">
            {state.total} {pluralizeN('Notification', state.total)}{' '}
          </Typography>
        )}
      </ButtonBase>
    </Box>
  );
}
