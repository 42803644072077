import React from 'react';
import {Link, Skeleton} from '@mui/material';
import {router} from 'app/router/main';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DataId, Sx} from 'app/types/common';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

type Props = Sx &
  DataId & {
    device?: AnyDeviceModelType;
  };

export function RecordingDevice({dataId, sx, device}: Props) {
  if (!device) {
    return (
      <Tooltip content="Loading device">
        <Skeleton sx={sx} />
      </Tooltip>
    );
  }

  return (
    <Link
      data-id={dataId}
      sx={sx}
      href={router.url('deviceDetails', {deviceId: device.getId()}, {activeTab: 'recordings'})}
    >
      <OverflowTooltip rowsNumber={1}>{device.getName()}</OverflowTooltip>
    </Link>
  );
}
