import React, {useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {observer} from 'mobx-react';
import {Stack} from '@mui/material';
import {StyleSx, Sx, TimestampRange} from 'app/types/common';
import {Header} from 'app/components/FleetManager/Header';
import {useCurrentTeamStore} from 'app/store/hooks';
import {TeamRecordings} from 'app/components/features/recordings/TeamRecordings/TeamRecordings';
import {useTeamRecordings} from 'app/components/features/recordings/hooks/useTeamRecordings';
import {useDevicesQuery} from 'app/hooks/useDevicesQuery';

const rootStyles: StyleSx = {width: 1032, pl: 3, pr: 1.5, my: 2};

type Props = Sx & {};

export const RecordingsPage = observer(({sx}: Props) => {
  const team = useCurrentTeamStore();
  const teamId = team.id;

  const [range, setRange] = useState<TimestampRange>({
    from: dayjs().subtract(7, 'days').startOf('day').unix(),
    to: dayjs().add(7, 'days').endOf('day').unix(),
  });

  const {data: recordings, isSuccess, refetch} = useTeamRecordings({teamId, range, enabled: true});

  const deviceIds = useMemo(() => {
    if (!recordings) {
      return [];
    }

    return recordings.map((r) => r.deviceId);
  }, [recordings]);

  const {data: devices} = useDevicesQuery({
    key: 'recording-devices',
    teamId,
    ids: deviceIds,
    enabled: deviceIds.length > 0,
  });

  return (
    <Stack sx={sx} flex={1}>
      <Header searchValue="" showSearch={false} onSearch={() => {}} />

      <Stack flex={1} sx={rootStyles}>
        <TeamRecordings
          sx={{flex: 1}}
          loading={!isSuccess}
          recordings={recordings ?? []}
          devices={devices}
          range={range}
          setRange={setRange}
          reload={refetch}
        />
      </Stack>
    </Stack>
  );
});
