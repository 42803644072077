import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {RepeatedCall} from 'app/util/RepeatedCall';
import {front} from 'app/api/CirrusApi';
import {Dispatcher} from 'app/data/Dispatcher';
import {ActionTypes} from 'app/data/ActionTypes';
import {minutesToMilliseconds} from 'app/util/timeConverter';
import {
  collectNotificationsByTypes,
  collectNotificationsTokens,
} from 'app/components/AsideNotifications/utils';
import {AsideNotificationsTokenStorage} from 'app/components/AsideNotifications/AsideNotificationsTokenStorage';
import {NotificationModel} from 'app/components/AsideNotifications/types';

export function useTeamNotifications() {
  const repeatedCallRef = useRef<RepeatedCall>();

  const [notifications, setNotifications] = useState<NotificationModel[]>([]);

  useEffect(() => {
    let previousTokens = new Set();

    repeatedCallRef.current = new RepeatedCall({
      call: ({getAbort}) => front.users().me().notifications().get(undefined, {getAbort}),
      onSuccess: (response: NotificationModel[]) => {
        previousTokens = new Set(collectNotificationsTokens(response));

        const processedNotifications = response.filter(({Accepted}) => !Accepted);

        setNotifications(processedNotifications);
      },
      hasData: (notifications: NotificationModel[]) => {
        const tokens = collectNotificationsTokens(notifications);
        const tokensFromStorage = new Set<string>(AsideNotificationsTokenStorage.load());
        const newCount = tokens.filter(
          (token) => previousTokens.has(token) === false && tokensFromStorage.has(token) === false,
        ).length;

        const hasNew = newCount > 0;
        if (hasNew) {
          Dispatcher.emit(ActionTypes.NOTIFICATIONS_UNREAD, {count: newCount});
        }

        const count = tokens.length;

        if (count > 0) {
          Dispatcher.emit(ActionTypes.NOTIFICATIONS_COUNT, {count});
        }

        return hasNew;
      },
      maxTimeoutMs: minutesToMilliseconds(2),
    });

    repeatedCallRef.current?.start();

    return () => {
      repeatedCallRef.current?.stop();
    };
  }, []);

  // handleTeamsTeamPlanUpdate
  useEffect(() => {
    const handler = () => {
      repeatedCallRef.current?.restart();
    };

    Dispatcher.on(ActionTypes.TEAMS_TEAM_PLAN_UPDATE, handler);

    return () => {
      Dispatcher.removeListener(ActionTypes.TEAMS_TEAM_PLAN_UPDATE, handler);
    };
  }, []);

  const notificationsByTypes = useMemo(
    () => collectNotificationsByTypes(notifications),
    [notifications],
  );

  const getNotificationsByType = useCallback(
    (type) => {
      return notificationsByTypes[type] || [];
    },
    [notificationsByTypes],
  );

  const reload = useCallback(() => {
    repeatedCallRef.current?.restart();
  }, []);

  const markAsRead = useCallback(() => {
    AsideNotificationsTokenStorage.save(notifications);
  }, [notifications]);

  return {
    notifications,
    notificationsByTypes,
    count: notifications.length,
    getNotificationsByType,
    reload,
    markAsRead,
  };
}
