import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {useQuery} from '@tanstack/react-query';
import {PairingDeviceIdStorage} from 'app/components/OneClickDevicePairing/PairingDeviceIdStorage';
import {PairDevicePageCloseButton} from 'app/components/PairDevice/PairDevicePageCloseButton';
import {stringComparator} from 'app/util/Sort';
import {OneClickDevicePairingLoading} from 'app/components/OneClickDevicePairing/OneClickDevicePairingLoading';
import {OneTeam} from 'app/components/OneClickDevicePairing/OneTeam';
import {MultipleTeams} from 'app/components/OneClickDevicePairing/MultipleTeams';
import {router} from 'app/router/main';
import {isNil} from 'app/util/isNil';
import {AppMapper} from 'app/services/mappers/AppMapper/AppMapper';
import {AppTeam} from 'app/models/AppTeam/AppTeam';
import {TeamApiService} from 'app/services/api/team/TeamApiService';
import {useCurrentTeamStore} from 'app/store/hooks';

interface Props {
  deviceId?: string;
}

function OneClickDevicePairingPageRoot({deviceId}: Props) {
  const {data} = useTeams(deviceId);
  const team = useCurrentTeamStore();

  useEffect(() => {
    PairingDeviceIdStorage.reset();
  }, [deviceId]);

  if (!deviceId) {
    router.go('devices');
    return null;
  }

  const teams = data ?? [];

  return (
    <div className="one-click-device-pairing-page-container">
      <div className="container one-click-device-pairing-page">
        <PairDevicePageCloseButton />

        {teams.length === 0 && <OneClickDevicePairingLoading />}

        {teams.length === 1 && <OneTeam teamId={team.id} deviceId={deviceId} />}

        {teams.length > 1 && <MultipleTeams teamId={team.id} deviceId={deviceId} teams={teams} />}
      </div>
    </div>
  );
}

export const OneClickDevicePairingPage = observer(OneClickDevicePairingPageRoot);

function mapTeams(list: Contracts.Core.Team[]): AppTeam[] {
  return list.map(AppMapper.mapAppTeam).sort((a, b) => stringComparator(a.name, b.name));
}

function useTeams(deviceId?: string) {
  return useQuery({
    queryKey: ['teams', deviceId],
    queryFn: () => TeamApiService.getTeams(),
    enabled: !isNil(deviceId) && deviceId.length > 0,
    select: mapTeams,
  });
}
