import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TuneIcon from '@mui/icons-material/Tune';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import {Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceCommand} from 'app/components/DeviceDetails/constants';
import {ApplyPresetToDevicesDialog} from 'app/components/dialogs/ApplyPresetToDevicesDialog/ApplyPresetToDevicesDialog';
import {RebootDevicesDialog} from 'app/components/dialogs/RebootDevicesDialog/RebootDevicesDialog';
import {Edge} from 'app/domain/edge';
import {DeleteDevicesDialog} from 'app/components/dialogs/DeleteDevicesDialog/DeleteDevicesDialog';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {useMounted} from 'app/hooks/useIsMounted';
import {MoveToGroupDialog} from 'app/components/dialogs/MoveToGroupDialog/MoveToGroupDialog';

type Props = Sx & {
  device: AnyDeviceModelType;
  groups: Edge.Group[];
  presets: Edge.TeamPreset[];
  premium: boolean;
  permitReboot: boolean;
  permitPresets: boolean;
  onApplyPreset: (preset: Edge.TeamPreset) => Promise<void>;
  onDelete: () => Promise<void>;
  onReboot: () => Promise<void>;
  onMoveToGroup: (groupId: string) => Promise<void>;
  onMoveFromGroup: () => Promise<void>;
};

export function DeviceMenu({
  sx,
  device,
  groups,
  presets,
  premium,
  permitReboot,
  permitPresets,
  onApplyPreset,
  onDelete,
  onReboot,
  onMoveToGroup,
  onMoveFromGroup,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const [presetDialog, setPresetDialog] = useState(false);
  const [rebootDialog, setRebootDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [groupDialog, setGroupDialog] = useState(false);

  const mounted = useMounted();

  const name = device.getName();
  const model = device.getModelName();
  const online = device.isOnline();
  const groupId = device.getGroupId();
  const lvs = ModelService.isLivescrypt(model);
  const multichannel = ModelService.isMultiChannel(model);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showPreset = multichannel && permitPresets && premium && online;

  const showReboot =
    online &&
    device.getCapabilities().canRebooting() &&
    permitReboot &&
    device.canSendCommand(DeviceCommand.Reboot);

  const handleApplyPreset = async (preset: Edge.TeamPreset) => {
    await onApplyPreset(preset);

    if (mounted()) {
      setPresetDialog(false);
    }
  };

  const handleReboot = async () => {
    await onReboot();

    if (mounted()) {
      setRebootDialog(false);
    }
  };

  const handleDelete = async () => {
    await onDelete();

    if (mounted()) {
      setDeleteDialog(false);
    }
  };

  return (
    <>
      <IconButton
        data-id="device_card_context_menu_button"
        sx={sx}
        disableRipple={false}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        {premium && (
          <MenuItem
            data-id="move_to_group_option"
            onClick={() => {
              setGroupDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <DriveFileMoveIcon />
            </ListItemIcon>

            <ListItemText>Move to group</ListItemText>
          </MenuItem>
        )}

        {showPreset && (
          <MenuItem
            data-id="apply_preset_option"
            onClick={() => {
              setPresetDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <TuneIcon color="error" />
            </ListItemIcon>

            <ListItemText>Apply preset</ListItemText>
          </MenuItem>
        )}

        {showReboot && (
          <MenuItem
            data-id="reboot_option"
            onClick={() => {
              setRebootDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <RestartAltIcon color="error" />
            </ListItemIcon>

            <ListItemText>Reboot</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          data-id="delete_option"
          onClick={() => {
            setDeleteDialog(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteIcon color="error" />
          </ListItemIcon>

          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <RebootDevicesDialog
        open={rebootDialog}
        names={[name]}
        onReboot={handleReboot}
        onClose={() => setRebootDialog(false)}
      />

      <ApplyPresetToDevicesDialog
        open={presetDialog}
        unitCount={1}
        model={model}
        presets={presets}
        rebootWarning={true}
        onApply={handleApplyPreset}
        onClose={() => setPresetDialog(false)}
      />

      <DeleteDevicesDialog
        open={deleteDialog}
        names={[name]}
        lvsOnly={lvs}
        onDelete={handleDelete}
        onClose={() => setDeleteDialog(false)}
      />

      <MoveToGroupDialog
        open={groupDialog}
        devices={[device]}
        groups={groups}
        showRemove={groupId !== ''}
        onMove={onMoveToGroup}
        onRemove={onMoveFromGroup}
        onClose={() => setGroupDialog(false)}
      />
    </>
  );
}
