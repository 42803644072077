import React from 'react';
import {Stack} from '@mui/material';
import {Sx} from 'app/types/common';
import {DeviceDetailsNavigation} from 'app/components/DeviceDetails/DeviceInfoTab/DeviceDetailsNavigation/DeviceDetailsNavigation';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceDetailsHeader} from 'app/components/DeviceDetails/DeviceDetailsPageHeader/DeviceDetailsPageHeader';
import {Transcriber} from 'app/components/DeviceDetails/TranscriberDevice/Output/Transcriber';
import {TabPanels} from 'app/components/sharedReactComponents/Tabs/TabPanels';
import {TabPanel} from 'app/components/sharedReactComponents/Tabs/TabPanel';
import {Output} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Output/Output';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';
import {Transcription} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Transcription';
import {Audio} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Audio/Audio';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {AlertsTab} from 'app/components/DeviceDetails/AlertsTab';
import {PresetsTab} from 'app/components/DeviceDetails/PresetsTab';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {ChannelDetailsTab} from 'app/components/DeviceDetails/ChannelDetailsTab';
import {ChannelsTab} from 'app/components/DeviceDetails/ChannelsTab';
import {useGetDeviceById} from 'app/hooks/Device/useGetDeviceById';
import {router} from 'app/router/main';
import {useCoreEntities} from 'app/components/UserProvider/UserProvider';
import {Inputs} from 'app/components/DeviceDetails/Inputs/Inputs';
import {InformationPanel} from 'app/components/DeviceDetails/DeviceInfoTab/InformationPanel';
import {DetailsTab} from 'app/components/DeviceDetails/DeviceInfoTab/types';
import {Archive} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Archive/Archive';
import {Shared} from 'app/types/shared';
import {useActualEvent} from 'app/components/DeviceDetails/hooks/useActualEvent';
import {useDeviceStatus} from 'app/components/FleetManager/DeviceCard/useStatusProgress';
import {ScheduleTab} from 'app/components/DeviceDetails/ScheduleTab/ScheduleTab';
import {Edge} from 'app/domain/edge';
import {RecordingsTab} from 'app/components/DeviceDetails/tabs/RecordingsTab/RecordingsTab';
import {packSx} from 'app/util/packSx/packSx';
import {containerStyles} from 'app/styles';

interface Props extends Sx {
  teamId: string;
  device: AnyDeviceModelType;
  groups: Edge.Group[];
  premium: boolean;
  channel?: AnyDeviceModelType;
  tab: string;
  tabs: Shared.LabelValue<DetailsTab>[];
  canTranscribe: boolean;
  getAlertSuggest: (id: string) => string | undefined;
}

export function DeviceDetails({
  sx,
  teamId,
  device,
  groups,
  premium,
  channel,
  tab,
  tabs,
  canTranscribe,
  getAlertSuggest,
}: Props) {
  const {user} = useCoreEntities();
  const {role} = user;

  const deviceId = device.getId();

  const isMaster = device instanceof PearlMasterDeviceModel;
  const isTranscriber = device instanceof LiveScryptDeviceModel;

  const status = useDeviceStatus(device);
  const supportsRecording = device.getCapabilities().canRecording();

  const isMultiChannel = ModelService.isMultiChannel(device.getModelName());

  const actualEvent = useActualEvent({deviceId, enabled: premium});

  const getDeviceById = useGetDeviceById(device);

  const handleTabsChange = (value: string) => {
    router.go(undefined, {deviceId}, {activeTab: value});
  };

  const handleGoToChannels = () => {
    router.go(undefined, {deviceId}, {activeTab: 'channels'});
  };

  const showTranscriber = isTranscriber && device.access.transcribing.enabled;
  const permitManagePresets = role.canApplyPresets();

  const renderPanel = (tab: DetailsTab) => {
    switch (tab) {
      case 'status': {
        return <InformationPanel device={device} progress={status.progress} />;
      }

      case 'presets': {
        return (
          <PresetsTab
            device={device}
            userName={user.name}
            permitApply={permitManagePresets}
            permitDelete={permitManagePresets}
          />
        );
      }

      case 'inputs': {
        return isMaster ? <Inputs device={device} /> : null;
      }

      case 'events': {
        return isMaster ? (
          <ScheduleTab device={device} teamId={teamId} userId={user.id} actualEvent={actualEvent} />
        ) : null;
      }

      case 'output': {
        return isTranscriber ? <Output device={device} /> : null;
      }

      case 'transcription': {
        return isTranscriber ? <Transcription device={device} /> : null;
      }

      case 'audio': {
        return isTranscriber ? <Audio sx={{width: '60%'}} device={device} /> : null;
      }

      case 'session': {
        return isTranscriber ? <Archive device={device} /> : null;
      }

      case 'alerts': {
        return <AlertsTab teamId={teamId} device={device} getSuggestion={getAlertSuggest} />;
      }

      case 'channels': {
        if (isMultiChannel) {
          if (channel) {
            return (
              <ChannelDetailsTab
                device={device}
                showTitle={true}
                actualEvent={actualEvent}
                channelDevice={channel}
                getDeviceById={getDeviceById}
                onGoBack={handleGoToChannels}
              />
            );
          }

          return (
            <ChannelsTab
              device={device as PearlMasterDeviceModel}
              actualEvent={actualEvent}
              getDeviceById={getDeviceById}
            />
          );
        }

        if (supportsRecording) {
          return (
            <ChannelDetailsTab
              device={device}
              channelDevice={device}
              showTitle={false}
              actualEvent={actualEvent}
              getDeviceById={getDeviceById}
            />
          );
        }

        return null;
      }

      case 'recordings': {
        return <RecordingsTab teamId={teamId} deviceId={deviceId} />;
      }

      default:
        return null;
    }
  };

  return (
    <Stack sx={sx}>
      <DeviceDetailsHeader
        device={device}
        groups={groups}
        progress={status.progress}
        event={actualEvent}
        canReboot={role.canRebootDevices()}
        premium={premium}
      />

      {showTranscriber && <Transcriber device={device} canTranscribe={canTranscribe} />}

      <DeviceDetailsNavigation
        role={role}
        device={device}
        activeTab={tab}
        tabs={tabs}
        onTabsChange={handleTabsChange}
      />

      <Stack sx={packSx(containerStyles, {my: 4, flex: 1})}>
        <TabPanels value={tab}>
          {tabs.map((tab) => (
            <TabPanel
              sx={{display: 'flex', flexDirection: 'column', flex: 1}}
              key={tab.value}
              value={tab.value}
            >
              {renderPanel(tab.value)}
            </TabPanel>
          ))}
        </TabPanels>
      </Stack>
    </Stack>
  );
}
