import React from 'react';
import {Box, Stack, Tab, Tabs, tabClasses, tabsClasses} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {Shared} from 'app/types/shared';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {RemoteLoginButton} from 'app/components/sharedReactComponents/RemoteLogin';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {DetailsTab} from 'app/components/DeviceDetails/DeviceInfoTab/types';
import {UnifySwitcher} from 'app/components/sharedReactComponents/UnifySwitcher/UnifySwitcher';

interface Props extends Sx {
  role: AnyUserRoleModel;
  device: AnyDeviceModelType;
  activeTab: string;
  tabs: Shared.LabelValue<DetailsTab>[];
  onTabsChange: (tab: string) => void;
}

export function DeviceDetailsNavigation({role, activeTab, tabs, device, onTabsChange}: Props) {
  const model = device.getModelName();
  const isUnify = ModelService.isUnify(model);
  const isLiveScrypt = ModelService.isLivescrypt(model);
  const isNano = ModelService.isNano(model);
  const isWebcaster = ModelService.isWebcaster(model);

  const showUnify = isUnify && (device.isDown() || device.isOnline());
  const showSwitcher = !isLiveScrypt && !isNano && !isWebcaster;

  const permitUnify = role.canManageUnify();
  const canEditBilling = role.canEditBilling();

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    onTabsChange(newValue);
  };

  const access = device.access.remoteLogin.enabled;

  return (
    <Box bgcolor={grey[200]} borderTop={1} borderBottom={1} borderColor={grey[300]}>
      <Stack direction="row" className="container">
        <Stack direction="row" flexGrow={1}>
          <Tabs
            data-id="details-tabs"
            sx={{
              [`& .${tabClasses.root}`]: {
                fontSize: 16,
                opacity: 1,
                minHeight: 48,
              },
              [`& .${tabsClasses.indicator}`]: {height: 4},
            }}
            textColor="inherit"
            value={activeTab}
            onChange={handleTabChange}
          >
            {tabs.map((tab) => (
              <Tab
                data-id={`${tab.value}-tab`}
                key={tab.value}
                value={tab.value}
                label={tab.label}
                disableRipple={false}
              />
            ))}
          </Tabs>
        </Stack>

        <Stack direction="row" alignItems="center" gap={1} flexShrink={0}>
          {showSwitcher && (
            <RemoteLoginButton
              type="switcher"
              premium={access}
              paymentAccess={canEditBilling}
              deviceId={device.getId()}
              disabled={device.isOffline()}
            />
          )}

          {!isWebcaster && (
            <RemoteLoginButton
              type="admin"
              premium={access}
              paymentAccess={canEditBilling}
              deviceId={device.getId()}
              disabled={device.isOffline()}
            />
          )}

          {showUnify && (
            <UnifySwitcher
              project={device}
              action={device.isDown() ? 'start' : 'stop'}
              disabled={!permitUnify}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
