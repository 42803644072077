import {useQuery} from '@tanstack/react-query';
import {RecordingsApi} from 'app/services/api/recordings/RecordingsApi';
import {TimestampRange} from 'app/types/common';
import {FilesMapper} from 'app/util/mappers/files/FilesMapper';

type Args = {
  enabled: boolean;
  teamId: string;
  range: TimestampRange;
};

export function useTeamRecordings({teamId, range, enabled}: Args) {
  return useQuery({
    queryKey: ['team-recordings', teamId, range.from, range.to],
    queryFn: async () => {
      const {from, to} = range;
      const res = await RecordingsApi.getTeamRecordings(from, to);
      return res.files.map(FilesMapper.mapRecording);
    },
    enabled,
  });
}
