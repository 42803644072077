import React, {useMemo} from 'react';
import {Virtuoso} from 'react-virtuoso';
import {Box, Card, Collapse, IconButton, Stack, Typography, Divider, Checkbox} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {DataId, StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {Edge} from 'app/domain/edge';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {EdgeDevice} from 'app/components/features/edge/EdgeDevice/EdgeDevice';
import {Schedule} from 'app/domain/schedule';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {GroupIndicators} from 'app/components/features/edge/EdgeGroup/GroupIndicators/GroupIndicators';
import {GroupMenu} from 'app/components/features/edge/EdgeGroup/GroupMenu/GroupMenu';
import {cardStyles} from 'app/components/features/edge/styles/card.styles';

const rootSx: StyleSx = {p: 1};
const togglerSx: StyleSx = {transform: 'rotate(180deg)'};
const checkboxSx: StyleSx = {m: 0};

type Props = Sx &
  DataId & {
    group: Edge.Group;
    devices: AnyDeviceModelType[];
    allGroups: Edge.Group[];
    totalInGroup: number;
    streamingDestinations: AnyStreamingDestinationModelType[];
    streamsAccess: boolean;
    billingAccess: boolean;
    presets: Edge.TeamPreset[];
    permitReboot: boolean;
    permitPresets: boolean;
    premium: boolean;
    indicators: boolean;
    toggleGroupSelection: (groupId: string) => void;
    checkGroupSelection: (groupId: string) => boolean;
    onDeleteGroup: (groupId: string) => Promise<void>;
    onRenameGroup: (groupId: string, name: string) => Promise<void>;
    getActualEvent: (id: string) => Schedule.Event | undefined;
    getDeviceById: (deviceId: string) => AnyDeviceModelType | undefined;
    toggleDeviceSelection: (device: AnyDeviceModelType) => void;
    checkDeviceSelection: (deviceId: string) => boolean;
    onApplyPreset: (deviceId: string, preset: Edge.TeamPreset) => Promise<void>;
    onMoveToGroup: (deviceId: string, groupId: string) => Promise<void>;
    onMoveFromGroup: (deviceId: string, groupId: string) => Promise<void>;
    onDeleteDevice: (deviceId: string) => Promise<void>;
    countOngoingEvents: (ids: string[]) => number;
    toggleOpen: (id: string) => void;
    getOpenState: (id: string) => boolean;
  };

export function EdgeGroup({
  dataId,
  sx,
  group,
  devices,
  totalInGroup,
  allGroups,
  premium,
  streamingDestinations,
  presets,
  streamsAccess,
  billingAccess,
  permitPresets,
  permitReboot,
  indicators,
  checkGroupSelection,
  toggleGroupSelection,
  onRenameGroup,
  onDeleteGroup,
  getDeviceById,
  getActualEvent,
  checkDeviceSelection,
  toggleDeviceSelection,
  onApplyPreset,
  onMoveFromGroup,
  onMoveToGroup,
  onDeleteDevice,
  countOngoingEvents,
  toggleOpen,
  getOpenState,
}: Props) {
  const expanded = getOpenState(group.id);

  const groupId = group.id;
  const groupName = group.name;
  const hasFiltered = devices.length > 0;
  const open = hasFiltered ? expanded : false;

  const selected = checkGroupSelection(groupId);

  const indeterminate = useMemo(() => {
    const selected = devices.filter((device) => checkDeviceSelection(device.getId()));

    return selected.length > 0 && selected.length !== devices.length;
  }, [devices, checkDeviceSelection]);

  const handleDelete = async () => onDeleteGroup(groupId);
  const handleRename = async (name: string) => onRenameGroup(groupId, name);

  return (
    <Card
      data-id={dataId}
      sx={packSx(rootSx, cardStyles.root, selected && cardStyles.selected, sx)}
      variant="outlined"
      tabIndex={0}
    >
      <Stack
        direction="row"
        alignItems="center"
        pl={0.25}
        pr={1}
        border={1}
        borderColor="transparent"
        gap={0.5}
      >
        <Checkbox
          data-id="device_group_checkbox"
          sx={checkboxSx}
          checked={selected}
          indeterminate={indeterminate}
          disableRipple={false}
          onClick={() => toggleGroupSelection(groupId)}
        />

        <Typography flex={1} minWidth={0} fontWeight={600} fontSize={16} component="span">
          <OverflowTooltip dataId="device_group_name">{groupName}</OverflowTooltip>
        </Typography>

        <GroupIndicators
          devices={devices}
          premium={premium}
          total={totalInGroup}
          countOngoingEvents={countOngoingEvents}
        />

        <GroupMenu groupName={groupName} onRename={handleRename} onDelete={handleDelete} />

        <IconButton
          data-id="device_group_details_toggler"
          disabled={!hasFiltered}
          disableRipple={false}
          onClick={() => toggleOpen(groupId)}
        >
          <KeyboardArrowDownIcon sx={packSx(open && togglerSx)} />
        </IconButton>
      </Stack>

      <Collapse in={open}>
        <Box>
          <Divider sx={{my: 1}} />

          <Virtuoso
            data={devices}
            useWindowScroll={true}
            itemContent={(index, device) => {
              const deviceId = device.getId();
              const event = getActualEvent(deviceId);

              const last = index === devices.length - 1;

              return (
                <Box pb={last ? 0 : 2}>
                  <EdgeDevice
                    dataId={deviceId}
                    key={deviceId}
                    device={device}
                    open={getOpenState(deviceId)}
                    groups={allGroups}
                    event={event}
                    presets={presets}
                    streamingDestinations={streamingDestinations}
                    premium={premium}
                    billingAccess={billingAccess}
                    permitReboot={permitReboot}
                    permitPresets={permitPresets}
                    streamsAccess={streamsAccess}
                    detailed={indicators}
                    getDeviceById={getDeviceById}
                    checkSelection={checkDeviceSelection}
                    toggleSelection={toggleDeviceSelection}
                    onApplyPreset={onApplyPreset}
                    onDelete={onDeleteDevice}
                    onMoveToGroup={onMoveToGroup}
                    onMoveFromGroup={onMoveFromGroup}
                    toggleOpen={toggleOpen}
                  />
                </Box>
              );
            }}
          />
        </Box>
      </Collapse>
    </Card>
  );
}
