import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {Box, Link, Stack, Typography} from '@mui/material';
import {DevicesAlertsDay} from 'app/components/DevicesAlerts/DevicesAlertsDay';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {PairDeviceButton} from 'app/components/libs/buttons/PairDeviceButton';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {groupDeviceAlertsByDate} from 'app/components/DevicesAlerts/utils';
import {DevicesAlertsCounter} from 'app/components/DevicesAlerts/DevicesAlertsCounter';
import {DateAlerts} from 'app/components/DevicesAlerts/types';
import {router} from 'app/router/main';
import {useAlertDictionary, useCurrentTeamStore} from 'app/store/hooks';
import {useUnreadAlerts} from 'app/components/DevicesAlerts/AlertsCounter';
import {useTeamAlerts} from 'app/components/DevicesAlerts/hooks/useTeamAlerts';

export function DevicesAlertsPage() {
  const team = useCurrentTeamStore();
  const alertDictionary = useAlertDictionary();
  const teamId = team.id;

  const {acknowledgeSince} = useUnreadAlerts();

  const [deviceAlertGroups, setDeviceAlertGroups] = useState<DateAlerts[]>([]);

  const query = useTeamAlerts({teamId});

  useEffect(() => {
    if (query.isSuccess) {
      const now = dayjs().unix();
      void acknowledgeSince(now);

      const grouped = groupDeviceAlertsByDate(query.data);
      setDeviceAlertGroups(grouped);
    }
  }, [query.data, query.isSuccess, acknowledgeSince]);

  return (
    <div>
      <PageHeader
        buttons={
          <>
            <PairDeviceButton />

            <DevicesAlertsCounter />
          </>
        }
      />

      {query.isLoading && <IndeterminateProgressBar />}

      {query.isSuccess && (
        <Box my={2} className="container">
          <Typography mb={1} variant="h5" fontWeight={600}>
            Alerts
          </Typography>

          <Typography>Alerts are device specific.</Typography>

          <Typography mb={1}>
            <Link href={router.url('alerts')}>Manage alerts</Link> to select what alerts you would
            like to receive.
          </Typography>

          <Stack gap={2}>
            {deviceAlertGroups.map((group, index) => (
              <DevicesAlertsDay
                key={group.date}
                group={group}
                showHeader={index === 0}
                getSuggestion={alertDictionary.getSuggestForAlert}
              />
            ))}
          </Stack>
        </Box>
      )}
    </div>
  );
}
