import {MutableRefObject, useCallback, useEffect, useRef, useState} from 'react';
import {FilterSwitchGroupId} from 'app/components/FleetManager/useFilterSwitches';
import {toggleInSet} from 'app/util/toggleInSet';
import {differenceOfSets} from 'app/util/differenceOfSets';
import {unionOfSets} from 'app/util/unionOfSets';
import {
  FilterSwitch,
  FilterSwitchGroup,
  FilterSwitchGroupType,
} from 'app/components/sharedReactComponents/FilterSelector/types';
import {Cloud} from 'app/domain/cloud';
import {Edge} from 'app/domain/edge';

const filterSwitchGroups: FilterSwitchGroup[] = [
  {
    id: FilterSwitchGroupId.Group,
    label: 'By Groups',
  },
];

function createDeviceGroupFilter(deviceGroup: Edge.Group): FilterSwitch {
  const groupId = deviceGroup.id;

  return {
    id: groupId,
    group: FilterSwitchGroupId.Group,
    label: deviceGroup.name,
    callback: (deviceAlert: Cloud.AlertSettings) => deviceAlert.deviceGroupId === groupId,
  };
}

interface Props {
  deviceGroups: Edge.Group[];
}

interface Return {
  activeFilterSwitches: Set<string>;
  toggleFilterSwitcher: (filterSwitchId: string) => void;
  filterSwitches: MutableRefObject<FilterSwitch[]>;
  clearFilterSwitches: () => void;
}

function useFilterSwitches({deviceGroups}: Props): Return {
  const [activeFilterSwitches, setActiveFilterSwitches] = useState<Set<string>>(new Set());
  const filterSwitches = useRef<FilterSwitch[]>([]);

  useEffect(() => {
    filterSwitches.current = [
      ...deviceGroups.map((deviceGroup) => createDeviceGroupFilter(deviceGroup)),
    ];
  }, [deviceGroups]);

  // TODO: This is duplicate of FleetManager/useFilterSwitcher logics
  const toggleFilterSwitcher = useCallback((filterSwitchId: string) => {
    setActiveFilterSwitches((activeFilterSwitches) => {
      const filterSwitch = filterSwitches.current.find(({id}) => id === filterSwitchId);
      const groupId = filterSwitch?.group;

      if (groupId) {
        const group = filterSwitchGroups.find(({id}) => id === groupId);

        if (group?.type === FilterSwitchGroupType.Radio) {
          const sameGroupFilterSwitchIds = filterSwitches.current
            .filter(({group}) => group === groupId)
            .map(({id}) => id);
          const idsWithoutGrouped = differenceOfSets(
            activeFilterSwitches,
            new Set(sameGroupFilterSwitchIds),
          );

          return unionOfSets(idsWithoutGrouped, new Set([filterSwitchId]));
        }
      }

      return toggleInSet(activeFilterSwitches, filterSwitchId);
    });
  }, []);

  const clearFilterSwitches = useCallback(() => {
    setActiveFilterSwitches(new Set());
  }, []);

  return {
    filterSwitches,
    activeFilterSwitches,
    toggleFilterSwitcher,
    clearFilterSwitches,
  };
}

export {useFilterSwitches, filterSwitchGroups};
