
enum NOTIFICATION_ID {
  BILLING_OWNER_CHANGE = 'billing_owner_change',
  INVOICE_PAYMENT_FAILED = 'invoice.payment_failed',
  STORAGE_INVALID_TOKEN = 'storage.invalid_token',
}

export {
  NOTIFICATION_ID,
};
