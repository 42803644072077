import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {Divider, List, Stack} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {blueGrey} from '@mui/material/colors';
import {Component, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {EdgeNav} from 'app/components/Navigation/EdgeNav/EdgeNav';
import {MainRoutes, router} from 'app/router/main';
import {palette} from 'app/themes/app';
import {NavButton} from 'app/components/Navigation/shared/NavButton';
import {NavText} from 'app/components/Navigation/shared/NavText';
import {SettingsNav} from 'app/components/Navigation/SettingsNav/SettingsNav';
import {TOOLTIP_PLACEMENT, Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Icons} from 'app/util/icons';
import {NavHeader} from 'app/components/Navigation/NavHeader/NavHeader';
import {NavRoutes, NavSection} from 'app/components/Navigation/hooks/useNavRoutes';
import {TeamUser} from 'app/models/User/TeamUser';
import {AppTeam} from 'app/models/AppTeam/AppTeam';
import {TeamApiService} from 'app/services/api/team/TeamApiService';
import {uniqueNewName} from 'app/util/newName';
import {NavigationMaintenance} from 'app/components/Navigation/NavigationMaintenance/NavigationMaintenance';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';
import {Maintenance} from 'app/domain/maintenance';
import {Dispatcher} from 'app/data/Dispatcher';

interface Props extends Sx {
  user: TeamUser;
  teams: AppTeam[];
  route: MainRoutes;
  routes: NavRoutes;
  compact: boolean;
  maintenance?: Maintenance.State;
}

function NavigationRoot({sx, user, teams, route, routes, maintenance, compact}: Props) {
  const [section, setSection] = useState<NavSection>(() => {
    if (routes.edge.find((r) => r.route === route)) {
      return 'edge';
    }

    if (routes.settings.find((r) => r.route === route)) {
      return 'settings';
    }
  });

  const handleCreate = async () => {
    const name = uniqueNewName(
      'My New Team',
      teams.map((t) => t.name),
    );

    const response = await TeamApiService.createTeam(name);
    router.goToTeam(response.ID, router.url('accountSettings'));
  };

  const handleLogout = async () => {
    await TeamApiService.logout();
    Dispatcher.emit('logout');
    window.location.assign('/login.html');
  };

  const handleMyTeam = () => {
    router.go('accountSettings');
  };

  const closeSection = () => setSection(undefined);

  return (
    <Stack
      sx={packSx(
        {
          bgcolor: palette.badge,
          color: palette.white,
          width: compact ? 55 : 250,
        },
        sx,
      )}
      gap={1}
      divider={<Divider sx={{borderColor: blueGrey[800]}} flexItem={true} />}
    >
      <NavHeader
        teams={teams}
        email={user.email}
        teamId={user.teamId}
        teamName={user.teamName}
        compact={compact}
        onCreate={handleCreate}
        onLogout={handleLogout}
        onMyTeam={handleMyTeam}
      />

      <List sx={{overflowY: 'auto'}} disablePadding={true}>
        <EdgeNav
          open={section === 'edge'}
          compact={compact}
          route={route}
          routes={routes.edge}
          onSetSection={setSection}
        />

        <NavItem
          dataId="connect-route"
          label="Connect"
          compact={compact}
          route="meetings"
          selected={route === 'meetings'}
          icon={
            <LegacyIcon sx={{color: palette.green, fontSize: 22}}>
              {Icons.connect().reactComponent()}
            </LegacyIcon>
          }
          onClick={closeSection}
        />

        <NavItem
          dataId="unify-route"
          label="Unify"
          compact={compact}
          route="projects"
          selected={route === 'projects'}
          icon={
            <LegacyIcon sx={{color: palette.green, fontSize: 22}}>
              {Icons.unify().reactComponent()}
            </LegacyIcon>
          }
          onClick={closeSection}
        />

        <NavItem
          dataId="alerts-route"
          label="Alerts"
          compact={compact}
          route="devicesAlerts"
          selected={route === 'devicesAlerts'}
          icon={<NotificationsIcon sx={{color: palette.darkerGray, fontSize: 22}} />}
          onClick={closeSection}
        />

        <SettingsNav
          open={section === 'settings'}
          compact={compact}
          route={route}
          routes={routes.settings}
          onSetSection={setSection}
        />

        {maintenance && <NavigationMaintenance state={maintenance} compact={compact} />}
      </List>
    </Stack>
  );
}

export const Navigation = observer(NavigationRoot);

interface ItemProps extends Component {
  label: string;
  route: MainRoutes;
  icon?: React.ReactNode;
  selected?: boolean;
  compact?: boolean;
  onClick?: Fn;
}

function NavItem({dataId, route, selected, label, compact, icon, onClick}: ItemProps) {
  return (
    <Tooltip placement={TOOLTIP_PLACEMENT.RIGHT} content={compact ? label : undefined}>
      <NavButton dataId={dataId} selected={selected} href={router.url(route)} onClick={onClick}>
        {icon}
        {!compact && <NavText value={label} />}
      </NavButton>
    </Tooltip>
  );
}
