import React, {Fragment, ReactNode} from 'react';
import {router} from 'app/router/main';
import {Callback} from 'app/types/common';
import {observer} from 'mobx-react';
import {useCurrentUserStore} from 'app/store/hooks';

interface Props {
  children: ReactNode;
  onClickLink?: Callback;
}

const BillingUsageLinkComponent = ({
  children,
  onClickLink,
}: Props) => {
  const currentUserStore = useCurrentUserStore();

  if (currentUserStore.role.canEditBilling()) {
    return (
      <a
        href={router.url('settingBillingUsage')}
        onClick={onClickLink}
      >
        {children}
      </a>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export const BillingUsageLink = observer(BillingUsageLinkComponent);
