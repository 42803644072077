import dayjs, {Dayjs} from 'dayjs';
import {router} from 'app/router/main';
import {Schedule} from 'app/domain/schedule';
import {
  EventCategory,
  ScheduleFilterSwitch,
  ScheduleView,
} from 'app/components/sharedReactComponents/Events/types';
import {capitalize} from 'app/components/helpers/commonHelpers';
import {filterItemsByFilterSwitches} from 'app/components/sharedReactComponents/FilterSelector/utils';
import {containsSearchString} from 'app/components/FleetManager/utils';
import {isCompleted, isOngoing, isScheduled} from 'app/domain/schedule/utils';
import {permissions} from 'app/components/sharedReactComponents/Events/constants';

/* filter helpers */
export function filterOngoing(events: Schedule.Event[]) {
  return events.filter((e) => isOngoing(e.status));
}

export function filterCompleted(events: Schedule.Event[]) {
  return events.filter((e) => isCompleted(e.status));
}

export function filterScheduled(events: Schedule.Event[]) {
  return events.filter((e) => isScheduled(e.status));
}

export function filterEvents(events: Schedule.Event[], filters: ScheduleFilterSwitch[]) {
  return filterItemsByFilterSwitches(events, filters);
}

export function filterByName(event: Schedule.Event, search: string) {
  return containsSearchString(event.title, search);
}

export function getActiveFilters(
  allFilters: ScheduleFilterSwitch[],
  activeFilterSwitches: Set<string>,
): ScheduleFilterSwitch[] {
  return allFilters.filter((filterSwitch) => activeFilterSwitches.has(filterSwitch.id));
}

export function isListView(view: ScheduleView) {
  return view === 'list';
}
export function isCalendarView(view: ScheduleView) {
  return view === 'calendar';
}

export function getDeviceDetailsUrl(deviceId: string): string {
  return router.url('deviceDetails', {deviceId});
}

export function formatEventMedia(m: Schedule.EventMedia) {
  switch (m) {
    case 'streaming':
      return 'Live stream';

    case 'recording':
      return 'VOD';

    default:
      return 'VOD + Live stream';
  }
}

export function formatEventPeriod(begin: TimeStampSeconds, end: TimeStampSeconds) {
  const t1 = dayjs.unix(begin);
  const t2 = dayjs.unix(end);
  const isSameDay = t1.isSame(t2, 'day');

  const format = isSameDay ? 'h:mm A' : 'MMM D h:mm A';

  return `${t1.format(format)} - ${t2.format(format)}`;
}

export function formatCmsName(cms: Schedule.Cms): string {
  switch (cms) {
    case 'chrono':
      return 'Epiphan Edge';

    case 'yuja':
      return 'YuJa';

    default:
      return capitalize(cms);
  }
}

export function sortEvents(a: Schedule.Event, b: Schedule.Event) {
  if (a.start < b.start) {
    return -1;
  }

  if (a.start > b.start) {
    return 1;
  }

  if (a.end < b.end) {
    return -1;
  }

  if (a.end > b.end) {
    return 1;
  }

  return 0;
}

export function fromPeriod(period: Schedule.Period): Dayjs {
  return dayjs().year(period.year).week(period.week).startOf('week');
}

export function getCurrentPeriod(): Schedule.Period {
  return {
    week: dayjs().week(),
    year: dayjs().year(),
  };
}

export function isCurrentPeriod(period: Schedule.Period) {
  const current = getCurrentPeriod();
  return current.week === period.week && current.year === period.year;
}

export function getNextPeriod(period: Schedule.Period): Schedule.Period {
  const date = fromPeriod(period).add(1, 'week');

  return {
    week: date.week(),
    year: date.year(),
  };
}

export function getPreviousPeriod(period: Schedule.Period): Schedule.Period {
  const date = fromPeriod(period).subtract(1, 'week');

  return {
    week: date.week(),
    year: date.year(),
  };
}

export function getPeriodBorders(period: Schedule.Period): TimeRange {
  const date = fromPeriod(period);

  return {
    from: date.startOf('week').unix(),
    to: date.endOf('week').unix(),
  };
}

export function getVariant(status: Schedule.EventStatus): EventCategory {
  switch (status) {
    case 'scheduled':
      return status;

    case 'running':
    case 'paused':
      return 'ongoing';

    default:
      return 'completed';
  }
}

export function formatEventTime(start: number, end: number): string {
  const from = dayjs.unix(start);
  const to = dayjs.unix(end);

  return `${from.format('hh:mm A')} - ${to.format('hh:mm A')}`;
}

export function formatEventDate(start: number, end: number): string {
  const from = dayjs.unix(start);
  const to = dayjs.unix(end);

  if (from.isSame(to, 'day')) {
    return from.format('MMM D, YYYY');
  }
  return `${from.format('MMM D')} - ${to.format('MMM D, YYYY')}`;
}

export function formatEventFullTime(start: number, end: number): string {
  return `${formatEventTime(start, end)}, ${formatEventDate(start, end)}`;
}

export function canStart(cms: Schedule.Cms): boolean {
  return permissions.get(cms)?.start ?? false;
}

export function canStop(cms: Schedule.Cms): boolean {
  return permissions.get(cms)?.stop ?? false;
}

export function canPause(cms: Schedule.Cms): boolean {
  return permissions.get(cms)?.pause ?? false;
}

export function canResume(cms: Schedule.Cms): boolean {
  return permissions.get(cms)?.pause ?? false;
}

export function canExtend(cms: Schedule.Cms): boolean {
  return permissions.get(cms)?.extend ?? false;
}
