import React from 'react';
import {Typography, capitalize} from '@mui/material';
import {DataId, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {Schedule} from 'app/domain/schedule';
import {getEventVariant} from 'app/domain/schedule/utils';
import {EventCountdown} from 'app/components/sharedReactComponents/Events/shared/EventCountdown/EventCountdown';

type Props = Sx &
  DataId & {
    event: Schedule.Event;
  };

export function EventStatus({dataId, sx, event}: Props) {
  const variant = getEventVariant(event.status);

  const ongoing = variant === 'ongoing';
  const scheduled = variant === 'scheduled';

  if (ongoing || scheduled) {
    return (
      <EventCountdown
        dataId={dataId}
        sx={packSx({whiteSpace: 'pre', color: ongoing ? 'error.main' : 'primary.main'}, sx)}
        variant={variant}
        start={event.start}
        end={event.end}
        label={<Typography noWrap={true}>{ongoing ? 'Ends in' : 'Starts in'}</Typography>}
      />
    );
  }

  return (
    <Typography data-id={dataId} sx={sx} color="text.disabled">
      {formatStatus(event.status)}
    </Typography>
  );
}

function formatStatus(status: Schedule.EventStatus) {
  if (status === 'finished') {
    return 'Completed';
  }

  return capitalize(status);
}
