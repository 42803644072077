import React, {useState} from 'react';
import {Button, Divider, Stack, Typography} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import {Callback} from 'app/types/common';
import {PairDevicePageCloseButton} from 'app/components/PairDevice/PairDevicePageCloseButton';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {PairDeviceContainer} from 'app/components/sharedReactComponents/PairDeviceContainer';
import {PairDeviceForm, PairingFormData} from 'app/components/PairDevice/PairDeviceForm';
import {useTeamBilling} from 'app/components/BillingManager/hooks/useTeamBilling';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {usePairedQuery} from 'app/hooks/usePairedQuery';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {downloadFile} from 'app/util/download';
import {useFeature} from 'app/util/ApplicationFeatures';

interface Props {
  teamId: string;
  canEditBilling: boolean;
  loadingAdditionalData?: boolean;
  onSubmit: Callback;
}

export function PairOrCreateDeviceContent({
  teamId,
  canEditBilling,
  loadingAdditionalData = false,
  onSubmit,
}: Props) {
  const {cloud} = useTeamBilling();
  const usbPairing = useFeature('usb-pairing');

  const currentTier = cloud.tier;

  const {data: paired, isSuccess, isInitialLoading} = usePairedQuery({teamId});

  const [confirmedWarningMessage, setConfirmedWarningMessage] = useState(false);

  const handleFormSubmit = async (data: PairingFormData | string) => {
    if (!paired) {
      return;
    }

    if (confirmedWarningMessage) {
      await BillingApiService.setPayment(teamId, {
        plan: cloud.id,
        seat_count: paired.devices + 1,
      });
    }

    await onSubmit(data);
  };

  const handleDownloadPairing = () => {
    const url = DeviceApiService.getNexusPairingFile();
    downloadFile(url);
  };

  return (
    <div className="pair-device-content-container">
      <div className="container pair-device-content">
        <PairDevicePageCloseButton />

        {!isSuccess || loadingAdditionalData ? (
          <div className="pair-device-content__loading">
            {Icons.spinner().label('Loading...').size(SIZE.L).reactComponent()}
          </div>
        ) : (
          <PairDeviceContainer
            pairedCount={paired.devices}
            loading={isInitialLoading || loadingAdditionalData}
            teamTier={currentTier}
            seatMonthPrice={cloud.prices.seat}
            isUnlimitedProPlan={cloud.unlimited}
            confirmedWarningMessage={confirmedWarningMessage}
            canEditBilling={canEditBilling}
            onConfirmMessage={() => setConfirmedWarningMessage(true)}
          >
            <Stack
              mb={5}
              gap={3}
              divider={
                <Divider sx={{'&::before, &::after': {position: 'static'}}} variant="middle">
                  OR
                </Divider>
              }
            >
              <PairDeviceForm
                currentTeamId={teamId}
                hasGroups={cloud.premium}
                showCancelButton={confirmedWarningMessage}
                onSubmit={handleFormSubmit}
                onCancel={() => setConfirmedWarningMessage(false)}
              />

              {usbPairing && (
                <Stack alignItems="center" alignSelf="center" gap={2}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography>Pair your Pearl Nexus device with configured USB stick</Typography>

                    <Tooltip content="Download and copy the pairing file to a folder called EPIPHAN on a USB stick labelled EPIPHAN to pair your device">
                      <HelpOutlineIcon color="secondary" />
                    </Tooltip>
                  </Stack>

                  <Button
                    data-id="nexus-pairing-btn"
                    variant="contained"
                    color="secondary"
                    size="large"
                    startIcon={<FileOpenIcon />}
                    onClick={handleDownloadPairing}
                  >
                    Get pairing file
                  </Button>
                </Stack>
              )}
            </Stack>
          </PairDeviceContainer>
        )}
      </div>
    </div>
  );
}
