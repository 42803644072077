import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {EditableNameText} from 'app/components/sharedReactComponents/EditableNameText';
import {emptyStringValidator, nameValidator} from 'app/util/validators';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {useCurrentTeamStore} from 'app/store/hooks';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Callback, Sx} from 'app/types/common';
import {ItemsEnumeration} from 'app/components/sharedReactComponents/ItemsEnumeration';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {Check, CheckboxesListLock} from 'app/components/sharedReactComponents/CheckboxesListLock';
import {Notifications} from 'app/components/Notifications';
import {TeamApiService} from 'app/services/api/team/TeamApiService';
import {Dispatcher} from 'app/data/Dispatcher';
import {router} from 'app/router/main';
import {useCoreEntities} from 'app/components/UserProvider/UserProvider';
import {UsersPanel} from 'app/components/Teams/UsersPanel';
import {TransferTeamOwnershipPrompt} from 'app/components/libs/dialogs/TransferTeamOwnershipPrompt';
import {useTeamBilling} from 'app/components/BillingManager/hooks/useTeamBilling';

interface Props extends Sx {}

export const AccountSettings = observer(({sx}: Props) => {
  const currentTeamStore = useCurrentTeamStore();
  const {user} = useCoreEntities();
  const {cloud} = useTeamBilling();

  const teamId = currentTeamStore.id;
  const teamName = currentTeamStore.name;

  const [loading, setLoading] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const canDeleteTeam = user.role.canDeleteTeams();
  const isUserMainTeam = currentTeamStore.id === user.privateTeam;

  const handleTeamNameChange = async ({target: {value}}) => {
    setLoading(true);

    return currentTeamStore.rename(value as string).finally(() => {
      setLoading(false);
    });
  };

  const openErrorDialog = () => setIsErrorOpen(true);
  const closeErrorDialog = () => setIsErrorOpen(false);

  const openDeleteDialog = () => setIsDeleteOpen(true);
  const closeDeleteDialog = () => setIsDeleteOpen(false);

  const handleDeleteTeam = async () => {
    try {
      await TeamApiService.deleteTeam(currentTeamStore.id);

      if (isUserMainTeam) {
        Dispatcher.emit('logout');
        await TeamApiService.logout();
        window.location.assign('/login.html');
        return;
      }

      return router.goToTeam(user.privateTeam);
    } catch (e: any) {
      if (e.status === 412) {
        closeDeleteDialog();
        openErrorDialog();
      } else {
        Notifications.addErrorNotification('Could not delete team');
      }
    }
  };

  const checks = [
    {
      name: 'devices',
      label: 'All paired and offline device instances will be deleted',
    },
    {
      name: 'content',
      label: 'Any cloud-generated content associated with this team will be inaccessible',
    },
  ];

  if (isUserMainTeam) {
    checks.push({
      name: 'private-team',
      label: 'This default team will be substituted by new one with your name',
    });
  }

  const renderHeaderTitle = () => {
    const canRenameTeam = user.role.canEditTeams();

    return (
      <Box maxWidth="100%">
        <EditableNameText
          value={currentTeamStore.name}
          validators={[nameValidator, emptyStringValidator]}
          disabled={!canRenameTeam || loading}
          onChange={handleTeamNameChange}
        />
      </Box>
    );
  };

  const handleChangeOwner = (userId: string, userName: string) => {
    TransferTeamOwnershipPrompt.show({
      teamId,
      teamName,
      userId,
      userName,
      isFreePlan: !cloud.configured,
    });
  };

  return (
    <Box sx={sx}>
      <ErrorDialog open={isErrorOpen} onClose={closeErrorDialog} />

      <DeleteTeamDialog
        open={isDeleteOpen}
        name={currentTeamStore.name}
        checks={checks}
        onDelete={handleDeleteTeam}
        onClose={closeDeleteDialog}
      />

      <PageHeader title={renderHeaderTitle()} />

      <UsersPanel teamId={currentTeamStore.id} user={user} changeOwner={handleChangeOwner} />

      <Box pl={4} mt={3}>
        {canDeleteTeam && (
          <Button
            dataId="delete-team-button"
            theme={THEME.DANGER}
            variant={VARIANT.SOLID}
            onClick={openDeleteDialog}
          >
            Delete team
          </Button>
        )}
      </Box>
    </Box>
  );
});

interface DialogProps {
  open: boolean;
  onClose: Callback;
}

function ErrorDialog({open, onClose}: DialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>Cannot delete team</DialogTitle>
      <DialogContent>
        Team has active meeting or unify project. You should stop all meetings and remove all unify
        projects before deletion.
      </DialogContent>
      <DialogActions>
        <Button variant={VARIANT.SOLID} theme={THEME.SUCCESS} onClick={onClose}>
          Close
        </Button>
      </DialogActions>
      <DialogClose onClose={onClose} />
    </Dialog>
  );
}

interface DeleteTeamDialogProps extends DialogProps {
  name: string;
  open: boolean;
  checks: Check[];
  onDelete: Callback<Promise<void>>;
  onClose: Callback;
}

function DeleteTeamDialog({open, name, checks, onDelete, onClose}: DeleteTeamDialogProps) {
  const [enabled, setEnabled] = useState(checks.length === 0);

  const handleCheck = (value: boolean) => setEnabled(value);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        Delete the team <ItemsEnumeration items={[{getName: () => name}]} theme={THEME.INFO} />?
      </DialogTitle>

      <DialogContent>
        <Box sx={{mb: 2, textAlign: 'center'}}>Warning: This cannot be undone</Box>
        <CheckboxesListLock checks={checks} onChange={handleCheck} />
      </DialogContent>

      <DialogActions>
        <ProgressButton
          dataId="modal_confirm_button"
          theme={THEME.DANGER}
          variant={VARIANT.SOLID}
          autoFocus={true}
          disabled={!enabled}
          onClick={onDelete}
        >
          Yes confirm delete
        </ProgressButton>
      </DialogActions>
      <DialogClose onClose={onClose} />
    </Dialog>
  );
}
