import React, {useCallback} from 'react';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Icons} from 'app/util/icons';
import {NOTIFICATION_ID} from 'app/components/AsideNotifications/constants';
import {BillingOwnerChangeNotification} from 'app/components/AsideNotifications/AsideNotification/BillingOwnerChangeNotification';
import {InvoicePaymentFailedNotification} from 'app/components/AsideNotifications/AsideNotification/InvoicePaymentFailedNotification';
import {StorageInvalidTokenNotification} from 'app/components/AsideNotifications/AsideNotification/StorageInvalidTokenNotification';
import {useToggleAsideNotifications} from 'app/components/AsideNotifications/useToggleAsideNotifications';
import {useTeamNotifications} from 'app/components/AsideNotifications/useTeamNotifications';
import {front} from 'app/api/CirrusApi';
import {
  InvoicePaymentFailedNotificationModel,
  OwnershipRequestNotificationModel,
  StorageInvalidTokenNotificationModel,
} from 'app/components/AsideNotifications/types';
import {Notifications} from 'app/components/Notifications';
import {getApiErrorMessage} from 'app/util/getApiErrorMessage';

const AsideNotifications: React.VFC = () => {
  const {
    count,
    getNotificationsByType,
    markAsRead,
    reload: reloadTeamNotifications,
  } = useTeamNotifications();

  const {
    opened,
    close,
  } = useToggleAsideNotifications({
    onChange: markAsRead,
  });

  const handleAcceptOwnership = useCallback(async (token: string) => {
    return front.users().me().notifications(token).accept().post()
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        const errorText = getApiErrorMessage(error);
        Notifications.addErrorNotification(`Accept ownership request error${errorText ? `: "${errorText}"` : ''}`);
        throw error;
      })
      .finally(() => {
        reloadTeamNotifications();
      });
  }, [reloadTeamNotifications]);

  const handleDeclineOwnership = useCallback((notificationToken) => {
    return front.users().me().notifications(notificationToken).decline().post()
      .catch((error) => {
        const errorText = getApiErrorMessage(error);
        Notifications.addErrorNotification(`Decline ownership request error${errorText ? `: "${errorText}"` : ''}`);
        throw error;
      })
      .finally(() => {
        reloadTeamNotifications();
      });
  }, [reloadTeamNotifications]);

  return (
    <div
      className={classNames('cr-aside-notifications', opened && 'cr-aside-notifications--visible')}
      data-id="aside_notifications_panel"
    >
      <div className="cr-aside-notifications__header">
        <Button
          className="cr-aside-notifications__close"
          dataId="close_panel_button"
          title="Close (Esc)"
          onClick={close}
        >
          {Icons.close().reactComponent()}
        </Button>
      </div>

      {count === 0 ? (
        <div
          className="cr-aside-notifications__empty-message"
          data-id="no_notifications_message"
        >
          Nothing to show
        </div>
      ) : (
        <div className="cr-aside-notifications__title">
          Notifications
        </div>
      )}

      <div className="cr-aside-notifications__content">
        {getNotificationsByType(NOTIFICATION_ID.BILLING_OWNER_CHANGE).map(({
          Token,
          Data: {
            TeamID,
            TeamName,
            FromUserName,
          },
        }: OwnershipRequestNotificationModel) => (
          <BillingOwnerChangeNotification
            key={Token}
            data={{
              teamId: TeamID,
              teamName: TeamName,
              token: Token,
              fromUserName: FromUserName,
            }}
            closeAction={close}
            onAccept={handleAcceptOwnership}
            onDecline={handleDeclineOwnership}
          />
        ))}

        {getNotificationsByType(NOTIFICATION_ID.INVOICE_PAYMENT_FAILED).map(({
          Token,
          Data: {
            team_id,
            team_name,
          },
        }: InvoicePaymentFailedNotificationModel) => (
          <InvoicePaymentFailedNotification
            key={Token}
            data={{
              teamId: team_id,
              teamName: team_name,
            }}
            closeAction={close}
          />
        ))}

        {getNotificationsByType(NOTIFICATION_ID.STORAGE_INVALID_TOKEN).map(({
          Token,
          Data: {
            storage_id,
            storage_name,
            team_id,
            team_name,
          },
        }: StorageInvalidTokenNotificationModel) => (
          <StorageInvalidTokenNotification
            key={Token}
            data={{
              locationId: storage_id,
              locationName: storage_name,
              teamId: team_id,
              teamName: team_name
            }}
            closeAction={close}
          />
        ))}
      </div>
    </div>
  );
};

export {
  AsideNotifications,
};
