import React from 'react';
import dayjs from 'dayjs';
import {Stack, Typography} from '@mui/material';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import {Sx, TimestampRange} from 'app/types/common';

type Props = Sx & {
  range: TimestampRange;
};

export function NoRecordings({sx, range}: Props) {
  const {from, to} = range;

  const fromInstance = dayjs.unix(from);
  const toInstance = dayjs.unix(to);
  const sameYear = fromInstance.isSame(toInstance, 'year');

  return (
    <Stack
      data-id="no-recordings-card"
      sx={sx}
      direction="row"
      gap={1}
      justifyContent="center"
      alignItems="center"
    >
      <VideoFileIcon color="info" fontSize="large" />

      <Typography variant="h6" color="text.disabled" fontWeight={600}>
        No recordings available from {fromInstance.format(sameYear ? 'MMMM D' : 'LL')} to{' '}
        {toInstance.format('LL')}.
      </Typography>
    </Stack>
  );
}
