import {CurrentTeamStore} from 'app/store/Team/CurrentTeamStore';
import {CurrentUserStore} from 'app/store/User/CurrentUserStore';
import {DeviceAlertDictionaryStore} from 'app/store/Device/DeviceAlertDictionaryStore';
import {useRootStore} from 'app/store/StoreContext';
import {MeetingsStore} from 'app/store/Meetings/MeetingsStore';

export function useCurrentUserStore(): CurrentUserStore {
  return useRootStore().currentUser;
}

export function useCurrentTeamStore(): CurrentTeamStore {
  return useRootStore().currentTeam;
}

export function useAlertDictionary(): DeviceAlertDictionaryStore {
  return useRootStore().deviceAlertDictionaryStore;
}

export function useMeetingsStore(): MeetingsStore {
  return useRootStore().meetings;
}
