import React from 'react';
import {Stack} from '@mui/material';
import {Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {
  DeviceField,
  UnitField,
} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/DeviceFields/DeviceField/DeviceField';
import {Cloud} from 'app/domain/cloud';

interface Props extends Sx {
  device: AnyDeviceModelType;
}

export function DeviceFields({sx, device}: Props) {
  const model = device.getModelName();
  const fields = getFields(model, device.isOnline());

  return (
    <Stack sx={sx} direction="row" alignItems="top" gap={3}>
      {fields.map((field) => (
        <DeviceField key={field} field={field} device={device} />
      ))}
    </Stack>
  );
}

function getFields(model: Cloud.UnitModel, online: boolean): UnitField[] {
  const lvsFields: UnitField[] = ['model', 'sn', 'ip', 'mac'];

  if (ModelService.isUnify(model)) return ['host'];

  if (ModelService.isLivescrypt(model)) return lvsFields;

  if (ModelService.isVirtual(model)) return ['model', 'sn', 'mac'];

  if (!online) {
    return lvsFields;
  }

  return [...lvsFields, 'firmware'];
}
