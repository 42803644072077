import React from 'react';
import {Modal} from 'app/components/sharedReactComponents/Modal/Modal';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {DangerouslyHTML} from 'app/components/sharedReactComponents/DangerouslyHTML';
import {Callback} from 'app/types/common';

interface Props {
  content: string;
  onDone: Callback;
}

const Content = ({content, onDone}: Props) => {
  return (
    <div className="user-agreement-prompt__container">
      <header className="user-agreement-prompt__title">Epiphan Cloud subscription agreement</header>

      <DangerouslyHTML
        className="user-agreement-prompt__content"
        autoFocus={true}
        content={content}
      />

      <footer className="user-agreement-prompt__footer">
        <ProgressButton
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          onClick={() => {
            return onDone().then(() => Modal.hide());
          }}
        >
          Accept
        </ProgressButton>
      </footer>
    </div>
  );
};

export const UserAgreementPrompt = {
  show({content, onDone}: Props) {
    Modal.show({
      className: 'user-agreement-prompt',
      showCloseButton: false,
      shouldCloseOnEsc: false,
      shouldCloseOnOverlayClick: false,
      children: <Content content={content} onDone={onDone} />,
    });
  },
};
