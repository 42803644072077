import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {WS} from 'app/api/WebSocket/WS';
import {Edge} from 'app/domain/edge';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {stringComparator} from 'app/util/Sort';
import {Ws} from 'app/contracts/ws';

interface Args {
  teamId: string;
  enabled: boolean;
}

export function useDeviceGroups({teamId, enabled}: Args) {
  const [groups, setGroups] = useState<Edge.Group[]>([]);
  const [map, setMap] = useState<Map<string, Edge.Group>>(new Map());

  const query = useQuery({
    queryKey: ['edge-groups', teamId],
    queryFn: () => DeviceApiService.getGroups(teamId),
    enabled,
  });

  useEffect(() => {
    const result = new Map<string, Edge.Group>();
    const data = query.data ?? [];

    data.forEach((g) => {
      result.set(g.id, g);
    });

    setMap(result);
  }, [query.data]);

  useEffect(() => {
    const values = [...map.values()].sort((a, b) => stringComparator(a.name, b.name));
    setGroups(values);
  }, [map]);

  const wsEnabled = enabled && query.isSuccess;

  useEffect(() => {
    const onGroupChange = (message: Ws.GroupChange) => {
      const {Action: action} = message.Body;

      if (action === 'deleted') {
        const {GroupID: groupId} = message.Body;
        map.delete(groupId);
        setMap(new Map(map));
        return;
      }

      if (action === 'created' || action === 'changed') {
        const {GroupID: groupId, GroupName: name} = message.Body;
        setMap((prev) => new Map(prev.set(groupId, {id: groupId, name})));
      }
    };

    WS.onDeviceGroupChange(onGroupChange);

    return () => {
      WS.offDeviceGroupChange(onGroupChange);
    };
  }, [wsEnabled, map]);

  const getGroup = useCallback((groupId: string) => map.get(groupId), [map]);

  const {refetch, isInitialLoading} = query;

  return {
    groups,
    loading: isInitialLoading,
    getGroup,
    refetch,
  };
}

export function useEdgeGroups({teamId, enabled}: Args) {
  const client = useQueryClient();

  const queueRef = useRef<Ws.GroupChange[]>([]);
  const intervalRef = useRef<number>(0);

  const {data, isSuccess} = useQuery({
    queryKey: ['edge', 'groups', teamId],
    queryFn: async () => {
      const res = await DeviceApiService.getGroups(teamId);
      return new Map(res.map((g) => [g.id, g]));
    },
    enabled,
  });

  useEffect(() => {
    const onGroupChange = (message: Ws.GroupChange) => {
      if (message.Body.Action === 'deleted') {
        queueRef.current.push(message);
        return;
      }

      client.setQueryData(
        ['edge', 'groups', teamId],
        (prev: Map<string, Edge.Group> | undefined) => {
          if (!prev) {
            return prev;
          }

          let copy = new Map(prev);
          copy = dispatchMessage(copy, message);
          return copy;
        },
      );
    };

    WS.onDeviceGroupChange(onGroupChange);

    return () => {
      WS.offDeviceGroupChange(onGroupChange);
    };
  }, [client, teamId]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    intervalRef.current = window.setInterval(() => {
      if (!queueRef.current.length) {
        return;
      }

      client.setQueryData(
        ['edge', 'groups', teamId],
        (prev: Map<string, Edge.Group> | undefined) => {
          if (!prev) {
            return prev;
          }

          let copy = new Map(prev);

          queueRef.current.forEach((message) => {
            copy = dispatchMessage(copy, message);
          });

          queueRef.current = [];

          return copy;
        },
      );
    }, 1000);

    return () => {
      if (intervalRef.current !== 0) {
        clearInterval(intervalRef.current);
      }
    };
  }, [client, isSuccess, teamId]);

  const groups = useMemo(
    () => (data ? [...data.values()] : []).sort((a, b) => stringComparator(a.name, b.name)),
    [data],
  );

  return {groups, ready: isSuccess};
}

function dispatchMessage(
  map: Map<string, Edge.Group>,
  message: Ws.GroupChange,
): Map<string, Edge.Group> {
  const {Action: action} = message.Body;

  switch (action) {
    case 'deleted': {
      const {GroupID: groupId} = message.Body;
      map.delete(groupId);
      break;
    }

    case 'created':
    case 'changed': {
      const {GroupID: groupId, GroupName: name} = message.Body;
      map.set(groupId, {id: groupId, name});
      break;
    }

    default:
      break;
  }

  return map;
}
