import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {Box} from '@mui/material';
import {Header} from 'app/components/FleetManager/Header';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {useCurrentTeamStore} from 'app/store/hooks';
import {EdgeWelcome} from 'app/components/FleetManager/EdgeWelcome/EdgeWelcome';
import {useCoreEntities} from 'app/components/UserProvider/UserProvider';
import {usePairedQuery} from 'app/hooks/usePairedQuery';
import {FleetManager} from 'app/components/FleetManager/FleetManager';
import {StyleSx} from 'app/types/common';
import {useTeamBilling} from 'app/components/BillingManager/hooks/useTeamBilling';
import {useFleetManager} from 'app/components/FleetManager/hooks/useFleetManager';

const pageSx: StyleSx = {width: 1032, px: 2};
const welcomeSx: StyleSx = {my: 4};
const edgeSx: StyleSx = {py: 2};

export const FleetManagerPage = observer(() => {
  const team = useCurrentTeamStore();
  const {user} = useCoreEntities();
  const {cloud} = useTeamBilling();

  const [search, setSearch] = useState('');

  const teamId = team.id;
  const hasPremium = cloud.premium;

  const capabilities = useMemo(() => team.getCapabilities(), [team]);

  const groupsAccess = capabilities.groups();

  const {devices, groups, ready} = useFleetManager({teamId, groupsAccess});

  const {data: paired} = usePairedQuery({teamId});

  const hasDevices = devices.length > 0;
  const hasDeviceGroups = hasPremium && groups.length > 0;
  const hasProjects = paired ? paired.projects > 0 : false;

  const showWelcome = !hasDevices && !hasDeviceGroups && !hasProjects;

  return (
    <div className="fleet-manager-page">
      <Header searchValue={search} onSearch={setSearch} />

      {ready ? (
        <Box sx={pageSx}>
          {showWelcome ? (
            <EdgeWelcome sx={welcomeSx} />
          ) : (
            <FleetManager
              sx={edgeSx}
              teamId={team.id}
              teamCapabilities={capabilities}
              userId={user.id}
              role={user.role}
              premium={cloud.premium}
              devices={devices}
              groups={groups}
              search={search}
              onClearSearch={() => setSearch('')}
            />
          )}
        </Box>
      ) : (
        <IndeterminateProgressBar />
      )}
    </div>
  );
});
