import React, {useState} from 'react';
import {Box, CircularProgress, Divider, Link, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {Cloud} from 'app/domain/cloud';
import {ALL_ALERT_TYPES} from 'app/components/Settings/Alerts/AlertSettings/constants';
import {router} from 'app/router/main';
import {EmptyFilterMessage} from 'app/components/sharedReactComponents/EmptyFilterMessage/EmptyFilterMessage';
import {AlertTableBody} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertTableBody/AlertTableBody';
import {AlertTableHeader} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertTableHeader/AlertTableHeader';

interface Props extends Sx {
  deviceAlerts: Cloud.AlertSettings[];
  emptyFilterResults: boolean;
  loading: boolean;
  disabled?: boolean;
  getAlertByType: (type: Cloud.AlertType) => Cloud.Alert | undefined;
  onChange: (id: string, type: Cloud.AlertType, value: boolean) => void;
  onToggleAlert: (type: Cloud.AlertType, value: boolean) => void;
  onClearFilter: () => void;
}

export function AlertsTable({
  sx,
  deviceAlerts,
  emptyFilterResults,
  loading = false,
  disabled = false,
  onChange,
  onClearFilter,
  getAlertByType,
  onToggleAlert,
}: Props) {
  const [hoveredAlert, setHoveredAlert] = useState<Cloud.AlertType | undefined>(undefined);

  const renderBody = () => {
    if (loading) {
      return (
        <Stack p={2} direction="row" justifyContent="center" alignItems="center" gap={2}>
          <CircularProgress size={16} />
          <Typography>Loading device alerts</Typography>
        </Stack>
      );
    }

    if (deviceAlerts.length > 0) {
      return (
        <AlertTableBody
          deviceAlerts={deviceAlerts}
          alertTypes={ALL_ALERT_TYPES}
          disabled={disabled}
          hoveredAlert={hoveredAlert}
          onChange={onChange}
        />
      );
    }

    if (emptyFilterResults) {
      return (
        <EmptyFilterMessage
          sx={{p: 2}}
          message="Filter applied, but no results found"
          buttonText="Reset filter"
          handleClear={onClearFilter}
        />
      );
    }

    return (
      <Typography p={2} textAlign="center">
        No devices have been paired with this team.{' '}
        <Link href={router.url('welcome')}>Pair a device</Link>
      </Typography>
    );
  };

  return (
    <Box sx={sx} className="alert-settings-table">
      <AlertTableHeader
        alertTypes={ALL_ALERT_TYPES}
        deviceAlerts={deviceAlerts}
        getAlertByType={getAlertByType}
        onToggleAlert={onToggleAlert}
        onHoverAlert={setHoveredAlert}
      />

      <Divider orientation="horizontal" flexItem={true} />

      {renderBody()}
    </Box>
  );
}
