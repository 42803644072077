import {useQuery} from '@tanstack/react-query';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';

type Args = {
  deviceId: string;
  enabled: boolean;
  staleTime?: number;
  refetchInterval?: number;
};

export function useFirmware({deviceId, enabled, refetchInterval, staleTime}: Args) {
  return useQuery({
    queryKey: ['device-firmware', deviceId],
    queryFn: async () => {
      const response = await DeviceApiService.getFirmware(deviceId);
      return response;
    },
    enabled,
    refetchInterval,
    staleTime,
  });
}
