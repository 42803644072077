import React from 'react';
import {Box, Collapse, IconButton, Stack, Typography, alpha} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {grey, red} from '@mui/material/colors';
import {DeviceAlertBadge} from 'app/components/DevicesAlerts/DeviceAlertBadge';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {useToggle} from 'app/hooks/useToggle';
import {processDetectingDeviceModel} from 'app/store/models/device/utils';
import {Cloud} from 'app/domain/cloud';
import {formatFullTimeOfDay} from 'app/util/time';
import {packSx} from 'app/util/packSx/packSx';

interface Props {
  alert: Cloud.UnitAlert;
  getSuggestion: (id: string) => string | undefined;
}

export function DeviceAlert({alert, getSuggestion}: Props) {
  const [opened, toggleOpened] = useToggle(false);

  const critical = alert.severity === 'critical';

  return (
    <Box
      className="device-alerts-table-row"
      sx={packSx(
        {borderBottom: 1, borderBottomColor: grey[300]},
        opened ? {bgcolor: alpha(grey[100], 0.8)} : {},
        critical
          ? {
              bgcolor: alpha(red[50], 0.8),
              borderLeftWidth: 2,
              borderLeftColor: 'error.main',
              borderLeftStyle: 'solid',
            }
          : {},
      )}
      data-id="device_alert_item"
    >
      <Stack direction="row" alignItems="center" p={1} gap={1}>
        <Box width={60}>
          <DeviceAlertBadge severity={alert.severity} />
        </Box>

        <Typography color={grey[600]} width={78} data-id="device_alert_time">
          {formatFullTimeOfDay(alert.timestamp)}
        </Typography>

        <Typography component="span" minWidth={0} flex={1}>
          {alert.deviceName ? (
            <OverflowTooltip rowsNumber={2} dataId="device_alert_name">
              {alert.deviceName}
            </OverflowTooltip>
          ) : (
            '<Unknown Device>'
          )}
        </Typography>

        <Typography width={100} data-id="device_alert_device_model">
          {processDetectingDeviceModel(alert.deviceModel)}
        </Typography>

        <Typography flex={1} data-id="device_alert_message">
          {alert.message}
        </Typography>

        <IconButton onClick={toggleOpened}>
          <KeyboardArrowDownIcon sx={opened ? {transform: 'rotate(180deg)'} : {}} />
        </IconButton>
      </Stack>

      <Collapse in={opened}>
        <Box px={1} pb={1} data-id="device_alert_suggested_actions">
          <Typography fontWeight={600} mb={0.5}>
            Suggested Actions:
          </Typography>

          <Typography data-id="device_alert_suggested_actions_content" whiteSpace="pre-wrap">
            {getSuggestion(alert.id) ?? 'No actions'}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}
