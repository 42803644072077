import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';
import {CopyButton} from 'app/components/sharedReactComponents/CopyButton';
import {SIZE, THEME} from 'app/constants';
import {Icons, SvgIcon} from 'app/util/icons';
import {observer} from 'mobx-react';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';
import {isParticipantStreamSettings} from 'app/types/guards/connect';
import {SrtDestination} from 'app/components/pages/MeetingDetails/ParticipantCard/ParticipantStream/SrtDestination/SrtDestination';

interface Props extends ClassName {
  stream: SrtStream;
}

function getStateIcon(stream: SrtStream): SvgIcon {
  const {starting, stopping, started, disconnected, failed} = stream;

  if (failed) {
    return Icons.warning().theme(THEME.DANGER);
  }

  if (starting || stopping || started || disconnected) {
    return Icons.spinner().theme(THEME.SUCCESS);
  }

  return Icons.streaming().theme(THEME.PRIMARY);
}

interface StatusProps extends ClassName {
  tooltip?: string;
}

function StreamStatus({className, tooltip, children}: PropsWithChildren<StatusProps>) {
  return (
    <Tooltip content={tooltip}>
      <span
        className={classNames('participant-stream__status-text', className)}
        data-id="srt_status_text"
      >
        {children}
      </span>
    </Tooltip>
  );
}

function getStateText(stream: SrtStream): React.ReactNode {
  const {starting, stopping, started, disconnected, failed, error} = stream;

  if (failed) {
    return (
      <StreamStatus className="participant-stream__status-text--error" tooltip={error?.message}>
        Connection failed
      </StreamStatus>
    );
  }

  if (disconnected) {
    return (
      <StreamStatus tooltip={error?.message}>
        <div className="participant-stream__container">
          Reconnecting {Icons.warning().theme(THEME.DANGER).reactComponent()}
        </div>
      </StreamStatus>
    );
  }

  if (starting || stopping || started) {
    return <StreamStatus>Connecting</StreamStatus>;
  }

  return <StreamStatus>Connected</StreamStatus>;
}

const ParticipantStreamComponent = ({className, stream}: Props) => {
  const {settings} = stream;

  if (!settings) {
    return null;
  }

  const showPwd = isPassphraseVisible(settings);

  const getSrtStatus = () => {
    const icon = getStateIcon(stream).size(SIZE.S).reactComponent();
    const label = getStateText(stream);

    return (
      <span className="participant-stream__status" data-id="srt_status">
        {icon}
        {label}
      </span>
    );
  };

  return (
    <div className={classNames('participant-stream', className)} data-id="participant_srt">
      <div className="participant-stream__col-1">
        <div className="participant-stream__container">
          <span>SRT Stream:</span>
          {getSrtStatus()}
        </div>

        <SrtDestination className="participant-stream__address" stream={stream} />
      </div>

      <div className="participant-stream__col-2">
        <div className="participant-stream__latency">
          <span className="participant-stream__label">Latency: </span>
          <span data-id="srt_mode_latency">{settings.latency} ms</span>
        </div>

        {showPwd && (
          <CopyButton
            className="participant-stream__copy"
            dataId="copy_srt_passphrase"
            placement="left"
            text="Copy passphrase"
            value={settings.key}
            theme={THEME.SUCCESS}
          />
        )}
      </div>
    </div>
  );
};

export const ParticipantStream = observer(ParticipantStreamComponent);

function isPassphraseVisible(settings: App.Connect.StreamSettings): boolean {
  if (isParticipantStreamSettings(settings)) {
    return !settings.integration && settings.key.length > 0;
  }

  return settings.key.length > 0;
}
