import {useQuery} from '@tanstack/react-query';
import {Edge} from 'app/domain/edge';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';

function mapCount(c: Contracts.Core.PairedCount): Edge.Paired {
  const {count, livescrypt, vpearl} = c;
  return {devices: count, lvs: livescrypt, projects: vpearl};
}

interface Args {
  teamId: string;
}

export function usePairedQuery({teamId}: Args) {
  return useQuery({
    queryKey: ['device-count', teamId],
    queryFn: async () => {
      const result = await DeviceApiService.getPairedCount();
      return mapCount(result);
    },
  });
}
