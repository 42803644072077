import React, {useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {Box, Button, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {AvailableForProPlanTooltip} from 'app/components/sharedReactComponents/AvailableForProPlanTooltip';
import {useUnitPresets} from 'app/hooks/Device/Preset/useUnitPresets';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {
  DevicePresetForm,
  PresetFields,
} from 'app/components/sharedReactComponents/DevicePresetForm/DevicePresetForm';
import {formatFullDate} from 'app/util/time';
import {Edge} from 'app/domain/edge';
import {PresetCard} from 'app/components/sharedReactComponents/DevicePresetCard/PresetCard/PresetCard';
import {Cloud} from 'app/domain/cloud';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {PresetApiService} from 'app/services/api/preset/PresetApiService';

interface Props extends Sx {
  teamPresets: Edge.TeamPreset[];
  deviceId: string;
  deviceName: string;
  model: Cloud.UnitModel;
  userName: string;
  offline: boolean;
  available: boolean;
  permitApply: boolean;
  permitDelete: boolean;
  reloadTeamPresets: () => Promise<any>;
}

export function UnitPresets({
  sx,
  teamPresets,
  deviceId,
  deviceName,
  model,
  available,
  offline,
  userName,
  permitApply,
  permitDelete,
  reloadTeamPresets,
}: Props) {
  const [creating, setCreating] = useState(false);

  const {
    data: presets,
    isSuccess,
    refetch: reloadLocalPresets,
  } = useUnitPresets({enabled: true, unitId: deviceId});

  const date = formatFullDate(dayjs().unix());

  const presetMap = useMemo(
    () => new Map<string, string>(teamPresets.map((p) => [p.hash, p.name])),
    [teamPresets],
  );

  const getNameInTeam = (hash: string) => presetMap.get(hash);

  const handleCreatePreset = async (fields: PresetFields) => {
    const sections = fields.sections as Edge.PresetSection[];

    await PresetApiService.createUnitPreset(deviceId, {
      name: fields.name,
      sections,
      description:
        fields.description ?? `This preset is loaded from ${deviceName} by ${userName} on ${date}`,
    });

    setCreating(false);
  };

  const handleApply = async (preset: Edge.Preset) => {
    if (!Edge.isTeamPreset(preset)) {
      await PresetApiService.applyLocalPreset(deviceId, {
        name: preset.name,
        sections: preset.sections,
      });
    }
  };

  const handleUploadToTeam = async (name: string) => {
    await PresetApiService.uploadUnitPreset(deviceId, name);
    await reloadTeamPresets();
  };

  const handleDelete = async (preset: Edge.Preset) => {
    if (!Edge.isTeamPreset(preset)) {
      await PresetApiService.deleteLocalPreset(deviceId, preset.name);
      await reloadLocalPresets();
    }
  };

  return (
    <Box sx={sx}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h5" fontWeight={600}>
          Local presets on the {ModelService.isUnify(model) ? 'project' : 'device'}
        </Typography>

        <AvailableForProPlanTooltip available={available}>
          <Button
            data-id="create-preset-button"
            variant="outlined"
            color="primary"
            disabled={offline || creating || !available}
            disableRipple={false}
            onClick={() => setCreating(true)}
          >
            Create a configuration preset
          </Button>
        </AvailableForProPlanTooltip>
      </Stack>

      {!isSuccess && <LoadingPlaceholder />}

      {isSuccess && (
        <Box>
          {creating && (
            <DevicePresetForm
              sx={{mb: 2}}
              deviceName={deviceName}
              unitPresets={presets}
              offline={offline}
              model={model}
              onCancel={() => setCreating(false)}
              onCreate={handleCreatePreset}
            />
          )}

          <Stack gap={2}>
            {presets.map((preset) => (
              <PresetCard
                dataId={preset.name}
                key={preset.name}
                preset={preset}
                deviceName={deviceName}
                offline={offline}
                showModel={true}
                permitDelete={permitDelete}
                permitApply={permitApply}
                available={available}
                savedAs={getNameInTeam(preset.hash)}
                onApply={handleApply}
                onUpload={handleUploadToTeam}
                onDelete={handleDelete}
              />
            ))}
          </Stack>
        </Box>
      )}
    </Box>
  );
}
