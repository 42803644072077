import React from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {app} from 'app/themes/app';

export function MainTheme({children}: React.PropsWithChildren<{}>) {
  return (
    <ThemeProvider theme={app}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
