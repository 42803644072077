import {useCallback, useState} from 'react';
import {RecordingSort, SortField} from 'app/components/features/recordings/types';
import {SortDirection} from 'app/types/common';
import {stringComparator} from 'app/util/Sort';
import {Files} from 'app/domain/files';

type SortFn = (a: Files.Recording, b: Files.Recording, direction: SortDirection) => number;

type Return = {
  sort: RecordingSort;
  setSort: React.Dispatch<React.SetStateAction<RecordingSort>>;
  sortFn: (a: Files.Recording, b: Files.Recording) => number;
};

export function useSortRecordings(): Return {
  const [sort, setSort] = useState<RecordingSort>({direction: 'asc', field: 'name'});

  const sortFn = useCallback(
    (a: Files.Recording, b: Files.Recording) => {
      const predicate = getSortPredicate(sort.field);
      return predicate(a, b, sort.direction);
    },
    [sort],
  );

  return {sort, setSort, sortFn};
}

function getSortPredicate(field: SortField): SortFn {
  switch (field) {
    case 'date':
      return sortByDate;

    case 'size':
      return sortBySize;

    default:
      return sortByName;
  }
}

function sortByName(a: Files.Recording, b: Files.Recording, direction: SortDirection): number {
  if (direction === 'asc') {
    return stringComparator(a.name, b.name);
  }

  return stringComparator(b.name, a.name);
}

function sortBySize(a: Files.Recording, b: Files.Recording, direction: SortDirection): number {
  if (direction === 'asc') {
    return a.size - b.size;
  }

  return b.size - a.size;
}

function sortByDate(a: Files.Recording, b: Files.Recording, direction: SortDirection): number {
  if (direction === 'asc') {
    return a.startAt - b.startAt;
  }

  return b.startAt - a.startAt;
}
