import React from 'react';
import {Box, CircularProgress, Stack, Typography} from '@mui/material';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Sx} from 'app/types/common';
import {DeviceFields} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/DeviceFields/DeviceFields';
import {useSystemInfo} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/useSystemInfo';
import {DevicePerformance} from 'app/components/DeviceDetails/DeviceInfoTab/DevicePerformance/DevicePerformance';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {DeviceStorage} from 'app/components/DeviceDetails/DeviceInfoTab/DeviceStorage/DeviceStorage';
import {useDeviceStorage} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/useStorageInfo';
import {useDeviceUploading} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/useAfuInfo';

interface Props extends Sx {
  device: AnyDeviceModelType;
}

export function SystemInformation({sx, device}: Props) {
  const deviceId = device.getId();
  const online = device.isOnline();
  const model = device.getModelName();

  const isUnify = ModelService.isUnify(model);
  const isPearl = ModelService.isPearlFamily(model);

  const enabled = online && isPearl;

  const systemQuery = useSystemInfo({deviceId, enabled, refetchInterval: 60 * 1000});
  const storageQuery = useDeviceStorage({deviceId, enabled, refetchInterval: 60 * 1000});
  const afuQuery = useDeviceUploading({deviceId, enabled, refetchInterval: 60 * 1000});

  const isSystemReady = systemQuery.isSuccess;
  const isStorageReady = storageQuery.isSuccess && afuQuery.isSuccess;

  return (
    <Box sx={sx}>
      <Typography fontWeight={600} variant="h5" mb={3}>
        System information
      </Typography>

      <DeviceFields sx={{mb: 5}} device={device} />

      {enabled && (
        <>
          {isSystemReady && isStorageReady ? (
            <Stack direction="row" justifyContent="space-between">
              {isSystemReady && systemQuery.data && (
                <DevicePerformance
                  sx={{width: 430, height: 320}}
                  system={systemQuery.data}
                  isUnify={isUnify}
                />
              )}

              {isStorageReady && storageQuery.data && (
                <DeviceStorage
                  sx={{width: 545, height: 320}}
                  storage={storageQuery.data}
                  afu={afuQuery.data ?? undefined}
                />
              )}
            </Stack>
          ) : (
            <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
              <CircularProgress />
              <Typography variant="h6">Loading panels</Typography>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}
