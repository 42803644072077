import React from 'react';
import {Box, Button, Stack} from '@mui/material';
import {Icons} from 'app/util/icons';
import {SIZE, THEME} from 'app/constants';
import {isNil} from 'app/util/isNil';
import {SxObj} from 'app/types/common';
import {Maintenance} from 'app/domain/maintenance';
import {palette} from 'app/themes/app';

interface Props {
  state: Maintenance.State;
  onDetails: () => void;
  onClose: () => void;
}

const root: SxObj = {
  px: 2.5,
  height: '60px',
  boxSizing: 'border-box',
  borderBottom: '2px solid',
  borderBottomColor: palette.orange,
  bgcolor: '#FFECA8',
};

export function MaintenanceMessage({state, onDetails, onClose}: Props) {
  const content = getHeaderContent(state);

  if (!content) {
    return null;
  }

  const handleDetails = () => {
    if (content.isError) {
      onDetails();
    }
  };

  return (
    <Stack sx={root} gap={1} direction="row" alignItems="center">
      <Stack
        data-id="maintenance-details"
        direction="row"
        alignItems="center"
        flex={1}
        gap={1}
        role={content.isError ? 'button' : 'banner'}
        bgcolor="transparent"
        tabIndex={0}
        onClick={handleDetails}
      >
        <Box>{Icons.attention().size(SIZE.L).theme(THEME.DANGER).reactComponent()}</Box>
        <Box flex={1}>
          <Box>
            <strong> {content.title}</strong>
          </Box>
          <Box>{content.description}</Box>
        </Box>
      </Stack>

      {!content.isError && (
        <Button data-id="close-maintenance-message" color="error" variant="text" onClick={onClose}>
          {Icons.close().reactComponent()}
        </Button>
      )}
    </Stack>
  );
}

interface Content {
  title: React.ReactNode;
  description: React.ReactNode;
  isError: boolean;
}

function getHeaderContent(state: Maintenance.State): Content | undefined {
  const {planned, incidents, viewed} = state;
  const hasProblem = incidents.length > 0;
  const hasPlanned = !isNil(planned) && !viewed;

  if (hasProblem) {
    return {
      isError: true,
      title: 'Cloud is unstable.',
      description: 'Some functionality may be unavailable. Click to see details.',
    };
  }

  if (hasPlanned) {
    const date = new Date(planned.start * 1000);
    const time = date.toLocaleDateString('en', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short',
    });

    return {
      title: `${planned.summary} ${time}`,
      description: planned.description,
      isError: false,
    };
  }
}
