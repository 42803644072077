import {useQuery} from '@tanstack/react-query';
import {RecordingsApi} from 'app/services/api/recordings/RecordingsApi';
import {TimestampRange} from 'app/types/common';
import {FilesMapper} from 'app/util/mappers/files/FilesMapper';

type Args = {
  enabled: boolean;
  teamId: string;
  deviceId: string;
  range: TimestampRange;
};

export function useUnitRecordings({teamId, enabled, deviceId, range}: Args) {
  return useQuery({
    queryKey: ['unit-recordings', teamId, deviceId, range.from, range.to],
    queryFn: async () => {
      const {from, to} = range;
      const res = await RecordingsApi.getUnitRecordings(deviceId, from, to);
      return res.files.map(FilesMapper.mapRecording);
    },
    enabled,
  });
}
