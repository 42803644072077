import {BaseCapabilities} from 'app/models/Capabilities/BaseCapabilities';
import {DeviceCapability} from 'app/components/DeviceDetails/constants';

class DeviceCapabilities extends BaseCapabilities {
  canRecording() {
    return this.check(DeviceCapability.Recording);
  }

  canRecordViaState() {
    return this.check(DeviceCapability.StateRecording);
  }

  canUploading() {
    return this.check(DeviceCapability.Uploading);
  }

  canStreaming() {
    return this.check(DeviceCapability.StateStreaming);
  }

  canScheduling() {
    return this.check(DeviceCapability.StateScheduling);
  }

  canTranscribing() {
    return this.check(DeviceCapability.StateTranscribing);
  }

  canRemoteLogin() {
    return this.check(DeviceCapability.RemoteLogin);
  }

  canRebooting() {
    return this.check(DeviceCapability.Reboot);
  }

  supportsPresets() {
    return this.check(DeviceCapability.Presets);
  }

  supportsAudioLevels() {
    return this.check(DeviceCapability.AudioLevels);
  }

  supportsChannelPreviews() {
    return this.check(DeviceCapability.ChannelPreviews);
  }

  supportsCms() {
    return this.check(DeviceCapability.Cms);
  }

  supportsSourcePreviews() {
    return this.check(DeviceCapability.SourcePreviews);
  }

  supportsWsApi() {
    return this.check(DeviceCapability.WsApi);
  }

  supportsChronoCms() {
    return this.check(DeviceCapability.ChronoCms);
  }
}

export {DeviceCapabilities};
