import React from 'react';
import {Box, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {palette} from 'app/themes/app';
import {usageSx} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/shared/styles';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Sx {
  showMessage: boolean;
}

export const NoActivePlan = ({sx, showMessage}: Props) => {
  return (
    <Box sx={packSx(usageSx.invoice, sx)}>
      <Typography fontSize={16} fontWeight={600} color={palette.darkerGray}>
        No active plan
      </Typography>

      {showMessage && (
        <Typography mt={2} color={palette.darkerGray}>
          This section will show invoice and usage information once you subscribe to Epiphan Connect
        </Typography>
      )}
    </Box>
  );
};
