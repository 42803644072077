import {alpha, SxProps} from '@mui/material';
import {backgrounds, palette} from 'app/themes/app';

const invoice: SxProps = {
  px: 6,
  py: 5,
  borderRadius: 1.5,
  bgcolor: alpha(backgrounds.lightGray, 0.5),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: palette.grayMedium,
};

export const usageSx = {invoice};
