import {Cloud} from 'app/domain/cloud';

const ALL_ALERT_TYPES: Cloud.AlertType[] = [
  Cloud.AlertType.DeviceOffline,
  Cloud.AlertType.Storage,
  Cloud.AlertType.InputNoSignal,
  Cloud.AlertType.NoSignal,
  Cloud.AlertType.DeviceHealth,
  Cloud.AlertType.Framerate,
  Cloud.AlertType.PublisherBandwidth,
  Cloud.AlertType.AFU,
  Cloud.AlertType.SystemLoad,
  Cloud.AlertType.Publishers,
  Cloud.AlertType.NoStorage,
  Cloud.AlertType.Schedule,
];

const LIVESCRYPT_ALERT_TYPES: Cloud.AlertType[] = [
  Cloud.AlertType.DeviceOffline,
  Cloud.AlertType.DeviceHealth,
];

export {ALL_ALERT_TYPES, LIVESCRYPT_ALERT_TYPES};
