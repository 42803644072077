import React from 'react';
import {observer} from 'mobx-react';
import {Stack} from '@mui/material';
import {Header} from 'app/components/FleetManager/Header';
import {useTeamSchedule} from 'app/components/sharedReactComponents/Events/hooks/useTeamSchedule';
import {useCurrentTeamStore} from 'app/store/hooks';
import {TeamSchedule} from 'app/components/sharedReactComponents/Events/TeamSchedule/TeamSchedule';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {useCoreEntities} from 'app/components/UserProvider/UserProvider';
import {StyleSx} from 'app/types/common';
import {noop} from 'app/util/noop';

const scheduleSx: StyleSx = {flex: 1, my: 4};

export const EventsPage = observer(() => {
  const team = useCurrentTeamStore();
  const {user} = useCoreEntities();

  const enabled = team.getCapabilities().cms();

  const {connection, ready, events, devices, period, fetching, actualEventIds, setPeriod} =
    useTeamSchedule({
      enabled,
      teamId: team.id,
    });

  return (
    <Stack flex={1}>
      <Header searchValue="" showSearch={false} onSearch={noop} />

      {!ready ? (
        <IndeterminateProgressBar />
      ) : (
        <TeamSchedule
          sx={scheduleSx}
          teamId={team.id}
          userId={user.id}
          events={events}
          devices={devices}
          connection={connection}
          period={period}
          fetching={fetching}
          actualEventIds={actualEventIds}
          setPeriod={setPeriod}
        />
      )}
    </Stack>
  );
});
