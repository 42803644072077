import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useQuery} from '@tanstack/react-query';
import {Cloud} from 'app/domain/cloud';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {AlertsApiService} from 'app/services/api/alerts/AlertsApiService';
import {AlertMapper} from 'app/services/mappers/AlertMapper/AlertMapper';
import {useAcknowledgeAlerts} from 'app/components/DevicesAlerts/hooks/useAcknowledgeAlerts';

type UnreadAlertsType = {
  alerts: Cloud.UnitAlert[];
  acknowledgeSince: (timestamp: number) => Promise<void>;
};

const AlertContext = createContext<UnreadAlertsType | undefined>(undefined);

export function UnreadAlerts({children}: PropsWithChildren<{}>) {
  const [alerts, setAlerts] = useState<Cloud.UnitAlert[]>([]);

  const {data, isSuccess, refetch} = useUnreadAlertsQuery();

  useEffect(() => {
    if (isSuccess) {
      setAlerts(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    const onMessage = async () => refetch();

    WS.on(EVENT_TYPE.NOTIFICATIONS_UPDATED, onMessage);

    return () => {
      WS.off(EVENT_TYPE.NOTIFICATIONS_UPDATED, onMessage);
    };
  }, [refetch]);

  const acknowledge = useAcknowledgeAlerts();

  const acknowledgeSince = useCallback(
    async (timestamp: number) => {
      await acknowledge(timestamp);
      void refetch();
    },
    [acknowledge, refetch],
  );

  const ctx = useMemo<UnreadAlertsType>(
    () => ({alerts, acknowledgeSince}),
    [alerts, acknowledgeSince],
  );

  return <AlertContext.Provider value={ctx}>{children}</AlertContext.Provider>;
}

function useUnreadAlertsQuery() {
  return useQuery({
    queryKey: ['unread-alerts'],
    queryFn: async () => {
      const response = await AlertsApiService.getUnreadAlerts();
      return response.map(AlertMapper.mapUnitAlert);
    },
  });
}

export function useUnreadAlerts(): UnreadAlertsType {
  const ctx = useContext(AlertContext);

  if (!ctx) {
    throw new Error('Called outside UnreadAlertsContext context');
  }

  return ctx;
}
