/**
 * {@link https://epiphan.atlassian.net/l/c/BhPFk1PH}
 */
export enum EVENT_TYPE {
  DEVICE_CHANNEL_IMAGE = 'DeviceChannelImage',
  DEVICE_SOURCE_IMAGE = 'DeviceSourceImage',
  DEVICE_ALS = 'DeviceALS',
  NOTIFICATIONS_UPDATED = 'NotificationsUpdated',
  TEAM_PRESET_CHANGED = 'TeamPresetChange',
  MEETING_CHANGED = 'MeetingChanged',
  PARTICIPANT_UPDATED = 'ParticipantUpdated',
  RETURN_FEED_UPDATED = 'InjectionUpdated',
  TEAMS_CALL_UPDATED = 'TeamsCallUpdated',
  EVENT_SCHEDULE_CHANGED = 'ScheduleChange',
  CHRONO_EVENT_CHANGED = 'ChronoEventStatus',
  DEVICE_CHANGE = 'DeviceChange',
  DEVICE_GROUP_CHANGE = 'DeviceGroupChange',
  ADD_DEVICE = 'DevicePatchPair',
  UNPAIR_DEVICE = 'DevicePatchUnpair',
  REMOVE_DEVICE = 'DevicePatchRemove',
  DEVICE_STATUS = 'DeviceOnlineStatus',
  DEVICE_DESIRED_STATE = 'DevicePatchDesiredState',
  DEVICE_PUBLISHERS = 'DevicePatchPublishers',
  DEVICE_WARNINGS = 'DevicePatchWarnings',
  DEVICE_RENAME = 'DevicePatchRename',
  LOCAL_PRESETS = 'DevicePatchPresets',
}
