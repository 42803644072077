import {Grid, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import React from 'react';

type Props = Sx & {
  showUnit: boolean;
};

export function RecordingHeader({sx, showUnit}: Props) {
  return (
    <Grid
      sx={sx}
      px={0.5}
      fontSize={12}
      color="text.disabled"
      columns={24}
      container={true}
      columnGap={1}
      alignItems="center"
    >
      <Grid xs={3} item={true} />

      <Grid xs={true} item={true}>
        <Typography variant="caption">Name</Typography>
      </Grid>
      <Grid xs={3} item={true}>
        <Typography variant="caption">Date</Typography>
      </Grid>

      <Grid xs={2} item={true}>
        <Typography variant="caption">Time</Typography>
      </Grid>
      <Grid xs={2} item={true}>
        <Typography variant="caption">Size</Typography>
      </Grid>

      {showUnit && (
        <Grid xs={3} item={true}>
          <Typography variant="caption">Device</Typography>
        </Grid>
      )}
      <Grid xs={2} item={true} />
    </Grid>
  );
}
