import {useContext} from 'react';
import {MaintenanceContext} from 'app/components/Maintenance/MaintenanceContext';

export function useMaintenance() {
  const ctx = useContext(MaintenanceContext);

  if (!ctx) {
    throw new Error('Not in MaintenanceContext');
  }

  return ctx;
}
