import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {WS} from 'app/api/WebSocket/WS';
import {AnyDeviceModelType, createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {Ws} from 'app/contracts/ws';
import {
  setChannelWarnings,
  setDesiredState,
  setDeviceGroup,
  setPublishers,
  setUnitStatus,
} from 'app/components/features/edge/utils';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {useMounted} from 'app/hooks/useIsMounted';
import {useEdgeGroups} from 'app/hooks/useDeviceGroups';

interface Args {
  teamId: string;
  groupsAccess: boolean;
}

export function useFleetManager({teamId, groupsAccess}: Args) {
  const query = useEdgeQuery(teamId);
  const client = useQueryClient();

  const queueRef = useRef<Ws.DeviceMessage[]>([]);
  const intervalRef = useRef<number>(0);

  const mounted = useMounted();

  const groupsQuery = useEdgeGroups({teamId, enabled: groupsAccess});

  const getDevice = useCallback((id: string) => query.data?.get(id), [query.data]);

  const {isSuccess, refetch} = query;

  const groupsReady = groupsAccess ? groupsQuery.ready : true;

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    intervalRef.current = window.setInterval(() => {
      if (!queueRef.current.length) {
        return;
      }

      client.setQueryData(
        ['edge', 'devices', teamId],
        (prev: Map<string, AnyDeviceModelType> | undefined) => {
          if (!prev) {
            return prev;
          }

          const copy = new Map(prev);

          const updated = dispatchMessages(copy, queueRef.current);
          queueRef.current = [];

          return updated;
        },
      );
    }, 1000);

    return () => {
      if (intervalRef.current !== 0) {
        clearInterval(intervalRef.current);
      }
    };
  }, [client, isSuccess, mounted, teamId]);

  useEffect(() => {
    const onDeviceMessage = (m: Ws.DeviceMessage) => {
      queueRef.current.push(m);
    };

    WS.on(EVENT_TYPE.ADD_DEVICE, onDeviceMessage);
    WS.on(EVENT_TYPE.UNPAIR_DEVICE, onDeviceMessage);
    WS.on(EVENT_TYPE.REMOVE_DEVICE, onDeviceMessage);
    WS.on(EVENT_TYPE.DEVICE_CHANGE, onDeviceMessage);
    WS.on(EVENT_TYPE.DEVICE_STATUS, onDeviceMessage);
    WS.on(EVENT_TYPE.DEVICE_DESIRED_STATE, onDeviceMessage);
    WS.on(EVENT_TYPE.DEVICE_PUBLISHERS, onDeviceMessage);
    WS.on(EVENT_TYPE.DEVICE_WARNINGS, onDeviceMessage);
    WS.on(EVENT_TYPE.DEVICE_RENAME, onDeviceMessage);

    return () => {
      WS.off(EVENT_TYPE.ADD_DEVICE, onDeviceMessage);
      WS.off(EVENT_TYPE.UNPAIR_DEVICE, onDeviceMessage);
      WS.off(EVENT_TYPE.REMOVE_DEVICE, onDeviceMessage);
      WS.off(EVENT_TYPE.DEVICE_CHANGE, onDeviceMessage);
      WS.off(EVENT_TYPE.DEVICE_STATUS, onDeviceMessage);
      WS.off(EVENT_TYPE.DEVICE_DESIRED_STATE, onDeviceMessage);
      WS.off(EVENT_TYPE.DEVICE_PUBLISHERS, onDeviceMessage);
      WS.off(EVENT_TYPE.DEVICE_WARNINGS, onDeviceMessage);
      WS.off(EVENT_TYPE.DEVICE_RENAME, onDeviceMessage);
    };
  }, []);

  useEffect(() => {
    const onGroupChange = (message: Ws.GroupChange) => {
      const {Action: action} = message.Body;

      if (action === 'device_removed' || action === 'device_added') {
        queueRef.current.push(message);
      }
    };

    WS.onDeviceGroupChange(onGroupChange);

    return () => {
      WS.offDeviceGroupChange(onGroupChange);
    };
  }, []);

  const devices = useMemo(() => {
    if (!query.data) {
      return [];
    }

    return [...query.data.values()];
  }, [query.data]);

  return {devices, ready: isSuccess && groupsReady, groups: groupsQuery.groups, getDevice, refetch};
}

function useEdgeQuery(teamId: string) {
  return useQuery({
    queryKey: ['edge', 'devices', teamId],
    queryFn: async () => {
      const contract = await DeviceApiService.getUnits([]);
      const mapped = contract.map((d) => createDeviceModel(d));
      return new Map(mapped.map((m) => [m.getId(), m]));
    },
  });
}

function dispatchMessages(map: Map<string, AnyDeviceModelType>, patches: Ws.DeviceMessage[]) {
  patches.forEach((message) => {
    switch (message.Kind) {
      case 'DeviceOnlineStatus': {
        const {DeviceID: deviceId} = message.Body;

        const instance = map.get(deviceId);

        if (instance) {
          const model = instance.getInnerModel();
          const updated = setUnitStatus(model, message);
          map.set(deviceId, createDeviceModel(updated));
        }

        break;
      }

      case 'DevicePatchPublishers': {
        const {DeviceID: deviceId} = message.Body;
        const instance = map.get(deviceId);

        if (instance) {
          const model = instance.getInnerModel();
          const updated = setPublishers(model, message.Body.Payload);
          map.set(deviceId, createDeviceModel(updated));
        }

        break;
      }

      case 'DevicePatchDesiredState': {
        const {DeviceID: deviceId} = message.Body;
        const instance = map.get(deviceId);

        if (instance) {
          const model = instance.getInnerModel();
          const updated = setDesiredState(model, message.Body.Payload);
          map.set(deviceId, createDeviceModel(updated));
        }

        break;
      }

      case 'DevicePatchWarnings': {
        const {DeviceID: deviceId} = message.Body;

        const [masterId, channelIdx] = parseMasterAndChannelIds(deviceId);

        const instance = map.get(masterId);

        if (instance) {
          const model = instance.getInnerModel();

          if (channelIdx) {
            const updated = setChannelWarnings(model, deviceId, message.Body.Payload);
            map.set(masterId, createDeviceModel(updated));
          } else {
            map.set(masterId, createDeviceModel({...model, Warnings: message.Body.Payload}));
          }
        }

        break;
      }

      case 'DevicePatchRename': {
        const {DeviceID: deviceId} = message.Body;

        const [masterId, channelIdx] = parseMasterAndChannelIds(deviceId);

        const instance = map.get(masterId);

        if (!instance) {
          break;
        }

        const model = instance.getInnerModel();

        if (channelIdx) {
          const channels = (model.Child ?? []).map((channel) =>
            channel.Id === deviceId ? {...channel, Name: message.Body.Payload.Name} : channel,
          );

          map.set(masterId, createDeviceModel({...model, Child: channels}));
        } else {
          map.set(masterId, createDeviceModel({...model, Name: message.Body.Payload.Name}));
        }

        break;
      }

      case 'DeviceChange': {
        const instance = createDeviceModel(message.Body.Payload);

        if (!ModelService.isUnify(instance.getModelName())) {
          map.set(instance.getId(), instance);
        }

        break;
      }

      case 'DevicePatchPair': {
        const instance = createDeviceModel(message.Body.Device);

        if (!ModelService.isUnify(instance.getModelName())) {
          map.set(instance.getId(), instance);
        }

        break;
      }

      case 'DevicePatchRemove': {
        const {DeviceID: deviceId} = message.Body;
        map.delete(deviceId);
        break;
      }

      case 'DeviceGroupChange': {
        const {Action: action} = message.Body;

        if (action === 'device_removed' || action === 'device_added') {
          const {DeviceID: deviceId} = message.Body;

          const instance = map.get(deviceId);

          if (instance) {
            const model = instance.getInnerModel();

            if (action === 'device_removed') {
              const updated = createDeviceModel(setDeviceGroup(model, '', ''));
              map.set(deviceId, updated);
            }

            if (action === 'device_added') {
              const {GroupID, GroupName} = message.Body;
              const updated = createDeviceModel(setDeviceGroup(model, GroupID, GroupName));
              map.set(deviceId, updated);
            }
          }
        }

        break;
      }

      default:
        break;
    }
  });

  return map;
}
