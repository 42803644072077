import React from 'react';
import {Stack} from '@mui/material';
import {ChangeEvent, Sx} from 'app/types/common';
import {Cloud} from 'app/domain/cloud';
import {Checkbox, CHECKBOX_THEME} from 'app/components/sharedReactComponents/Checkbox';

interface Props extends Sx {
  type: Cloud.AlertType;
  value: boolean;
  disabled: boolean;
  hovered: boolean;
  onChange: (type: Cloud.AlertType, value: boolean) => void;
}

export function AlertValue({sx, type, value, hovered, disabled, onChange}: Props) {
  const handleChange = ({target: {value}}: ChangeEvent<boolean>) => {
    onChange(type, value);
  };

  return (
    <Stack sx={sx} py={2} alignItems="center" justifyContent="center" width={60}>
      <Checkbox
        value={Boolean(value)}
        disabled={disabled}
        theme={CHECKBOX_THEME.SILVER_SAND}
        highlightOnHover={true}
        hovered={hovered}
        onChange={handleChange}
      />
    </Stack>
  );
}
