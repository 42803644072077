import {LIVESCRYPT_ALERT_TYPES} from 'app/components/Settings/Alerts/AlertSettings/constants';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {Cloud} from 'app/domain/cloud';

export function isAlertDisabled(
  deviceAlert: Cloud.AlertSettings,
  alertType: Cloud.AlertType,
): boolean {
  if (ModelService.isLivescrypt(deviceAlert.deviceModel)) {
    return !LIVESCRYPT_ALERT_TYPES.includes(alertType);
  }

  return false;
}
