import React from 'react';
import {StackHorizontal} from 'app/components/sharedReactComponents/Stack';
import {ActionButtonsSeparator} from 'app/components/sharedReactComponents/ActionButtons/ActionButtonsSeparator';
import {RecordingButton} from 'app/components/sharedReactComponents/ActionButtons/RecordingButton';
import {TranscribingButton} from 'app/components/sharedReactComponents/ActionButtons/TranscribingButton';
import {StreamingDestinationControl} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationControl';
import {Callback} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';

interface Props {
  device: AnyDeviceModelType;
  streamingDestinations: AnyStreamingDestinationModelType[];
  editable: boolean;
  hasEvent?: boolean;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  onChangeStreamingDestination: Callback;
}

export function ActionButtons({
  device,
  hasEvent,
  streamingDestinations,
  editable,
  getDeviceById,
  onChangeStreamingDestination,
}: Props) {
  return (
    <StackHorizontal separatorComponent={<ActionButtonsSeparator />}>
      {device.access.transcribing.enabled && <TranscribingButton device={device} />}

      {device.access.streaming.enabled && (
        <StreamingDestinationControl
          device={device}
          streamingDestinations={streamingDestinations}
          editable={editable}
          getDeviceById={getDeviceById}
          onChange={onChangeStreamingDestination}
        />
      )}

      {device.access.recording.enabled && <RecordingButton device={device} hasEvent={hasEvent} />}
    </StackHorizontal>
  );
}
