import React from 'react';
import {router} from 'app/router/main';
import {cirrusApi} from 'app/api/CirrusApi';
import {AsideNotification} from 'app/components/AsideNotifications/AsideNotification';
import {TeamUrl} from 'app/components/AsideNotifications/AsideNotification/TeamUrl';
import {Callback} from 'app/types/common';

interface Props {
  data: {
    locationId: string;
    locationName: string;
    teamId: string;
    teamName: string;
  };
  closeAction: Callback;
}

export const StorageInvalidTokenNotification: React.VFC<Props> = ({
  data: {
    locationId,
    locationName,
    teamId,
    teamName,
  },
  closeAction,
}) => {
  const renderLink = () => (
    <a
      href={router.urlToTeamRoute(teamId, router.url('fileTransfer', {}, {locationId}))}
      onClick={(e) => {
        e.preventDefault();
        closeAction();

        if (teamId === cirrusApi.getCurrentTeamId()) {
          router.go('fileTransfer', {}, {locationId});
        } else {
          router.goToTeamRoute(teamId, router.url('fileTransfer', {}, {locationId}));
        }
      }}
    >
      {locationName}
    </a>
  );

  const renderTeamLink = () => (
    <TeamUrl
      teamId={teamId}
      teamName={teamName}
      onClick={closeAction}
    />
  );

  return (
    <AsideNotification dataId="storage_invalid_token_notification">
      Invalid internal storage access token "{renderLink()}" in team "{renderTeamLink()}"
    </AsideNotification>
  );
};
