import React, {useState} from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {Button, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Sx} from 'app/types/common';
import PauseIcon from '@mui/icons-material/Pause';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Schedule} from 'app/domain/schedule';
import {isPaused, isRunning, isScheduled} from 'app/domain/schedule/utils';
import {StartEventDialog} from 'app/components/sharedReactComponents/Events/dialogs/StartEventDialog/StartEventDialog';
import {StopEventDialog} from 'app/components/sharedReactComponents/Events/dialogs/StopEventDialog/StopEventDialog';
import {
  canPause,
  canResume,
  canStart,
  canStop,
} from 'app/components/sharedReactComponents/Events/utils';

interface Props extends Sx {
  event: Schedule.Event;
  offline: boolean;
  allowStart: boolean;
  onStart(id: string): Promise<void>;
  onStop(id: string): Promise<void>;
  onPause(id: string): Promise<void>;
  onResume(id: string): Promise<void>;
}

const offlineTooltip = 'Device is offline';
const startTooltip = 'Ongoing or not next event for the device';

export function EventActions({
  sx,
  event,
  offline,
  allowStart,
  onPause,
  onStart,
  onStop,
  onResume,
}: Props) {
  const [startDialog, setStartDialog] = useState(false);
  const [stopDialog, setStopDialog] = useState(false);

  const paused = isPaused(event.status);
  const running = isRunning(event.status);
  const scheduled = isScheduled(event.status);
  const ongoing = running || paused;

  const handleStart = async () => {
    await onStart(event.id);
    setStartDialog(false);
  };

  const handleStop = async () => {
    await onStop(event.id);
    setStopDialog(false);
  };

  const handleResume = async () => {
    await onResume(event.id);
  };

  const handlePause = async () => {
    await onPause(event.id);
  };

  const showResume = paused && canResume(event.cms);
  const showPause = running && canPause(event.cms);
  const showStop = ongoing && canStop(event.cms);
  const showStart = scheduled && canStart(event.cms);

  return (
    <Stack sx={sx} direction="row" alignItems="center" gap={1}>
      <StartEventDialog
        open={startDialog}
        name={event.title}
        onStart={handleStart}
        onClose={() => setStartDialog(false)}
      />

      <StopEventDialog
        open={stopDialog}
        name={event.title}
        onStop={handleStop}
        onClose={() => setStopDialog(false)}
      />

      {showResume && (
        <Tooltip content={offline ? offlineTooltip : undefined}>
          <span>
            <LoadingButton
              sx={{minWidth: 20, px: 1}}
              data-id="resume-button"
              color="secondary"
              variant="contained"
              disabled={offline}
              disableRipple={false}
              onClick={handleResume}
            >
              <PlayArrowIcon />
            </LoadingButton>
          </span>
        </Tooltip>
      )}

      {showPause && (
        <Tooltip content={offline ? offlineTooltip : undefined}>
          <span>
            <LoadingButton
              sx={{minWidth: 20, px: 1}}
              data-id="pause-button"
              color="error"
              variant="contained"
              disabled={offline}
              disableRipple={false}
              onClick={handlePause}
            >
              <PauseIcon />
            </LoadingButton>
          </span>
        </Tooltip>
      )}

      {showStop && (
        <Tooltip content={offline ? offlineTooltip : undefined}>
          <span>
            <Button
              data-id="stop-button"
              color="error"
              variant="contained"
              disabled={offline}
              disableRipple={false}
              onClick={() => setStopDialog(true)}
            >
              Stop
            </Button>
          </span>
        </Tooltip>
      )}

      {showStart && (
        <Tooltip content={offline ? offlineTooltip : !allowStart ? startTooltip : undefined}>
          <span>
            <Button
              data-id="start-button"
              variant="contained"
              disabled={offline || !allowStart}
              disableRipple={false}
              onClick={() => setStartDialog(true)}
            >
              Start
            </Button>
          </span>
        </Tooltip>
      )}
    </Stack>
  );
}
