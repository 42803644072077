import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {alpha, Box, Typography} from '@mui/material';
import {EdgePlan} from 'app/models/EdgePlan/EdgePlan';
import {Tabs, TABS_THEME} from 'app/components/sharedReactComponents/Tabs/Tabs';
import {Tab} from 'app/components/sharedReactComponents/Tabs/Tab';
import {ChangeEvent} from 'app/types/common';
import {TabPanels} from 'app/components/sharedReactComponents/Tabs/TabPanels';
import {TabPanel} from 'app/components/sharedReactComponents/Tabs/TabPanel';
import {ConnectUsageAndInvoices} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/ConnectUsageAndInvoices/ConnectUsageAndInvoices';
import {CloudAndUnifyInvoices} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/CloudAndUnifyInvoices/CloudAndUnifyInvoices';
import {palette} from 'app/themes/app';

interface Props {
  teamId: string;
  cloud: EdgePlan;
  connect?: App.Billing.Connect.Plan;
}

type UsageAndInvoiceTab = 'cloud' | 'connect';

export const UsageAndInvoices = observer(({teamId, cloud, connect}: Props) => {
  const [tab, setTab] = useState<UsageAndInvoiceTab>('cloud');

  const onTabChange = ({target: {value}}: ChangeEvent<UsageAndInvoiceTab>) => {
    setTab(value);
  };

  return (
    <Box>
      <Typography fontWeight={600} variant="h5" mb={3}>
        Usage and invoices
      </Typography>

      <Box
        sx={{
          borderWidth: 0,
          borderStyle: 'solid',
          borderColor: alpha(palette.black, 0.12),
          borderBottomWidth: 1,
          '& button': {width: 170},
        }}
        mb={3}
      >
        <Tabs value={tab} theme={TABS_THEME.MENU} onChange={onTabChange}>
          <Tab data-id="edge-usage-tab" label="Edge & Unify" value="cloud" />
          <Tab data-id="connect-usage-tab" label="Connect" value="connect" />
        </Tabs>
      </Box>

      <TabPanels value={tab}>
        <TabPanel value="cloud">
          <CloudAndUnifyInvoices teamId={teamId} period={cloud.period} />
        </TabPanel>

        <TabPanel value="connect">
          <ConnectUsageAndInvoices subscription={connect} teamId={teamId} />
        </TabPanel>
      </TabPanels>
    </Box>
  );
});
