import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCancelButton,
  ModalContainer,
  ModalFooter,
  ModalTitle,
} from 'app/components/sharedReactComponents/Modal/Modal';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {
  SIZE,
  THEME,
  VARIANT,
} from 'app/constants';
import {Checkbox} from 'app/components/sharedReactComponents/Checkbox';
import {front} from 'app/api/CirrusApi';
import {Notifications} from 'app/components/Notifications';
import {Callback} from 'app/types/common';
import {getApiErrorMessage} from 'app/util/getApiErrorMessage';
import {noop} from 'app/util/noop';

interface TransferTeamOwnershipPromptProps {
  teamId: string;
  teamName: string;
  userId: string;
  userName: string;
  isFreePlan: boolean;
  onDone?: Callback;
}

const Content: React.VFC<TransferTeamOwnershipPromptProps> = ({
  teamId,
  teamName,
  userId,
  userName,
  isFreePlan,
  onDone = noop,
}) => {
  const transferBilling = !isFreePlan;

  const handleClickSend = async () => {
    return front.teams(teamId).users(userId).master().put({KeepCard: transferBilling})
      .then(() => {
        Modal.hide();
        onDone();
      })
      .catch((error) => {
        const errorText = getApiErrorMessage(error);
        Notifications.addErrorNotification(`Team transfer request error${errorText ? `: "${errorText}"` : ''}`);
      });
  };

  return (
    <ModalContainer>
      <ModalTitle>
        Transfer ownership of your team

        {' '}

        <span className="confirm-modal-transfer-ownership-prompt__highlighted-text">
          {teamName}
        </span>

        {' to '}

        <span className="confirm-modal-transfer-ownership-prompt__highlighted-text">
          {userName}
        </span>
      </ModalTitle>

      {!isFreePlan && (
        <ModalBody>
          <div className="confirm-modal-transfer-ownership-prompt__checkboxes">
            <Checkbox
              label="Transfer billing information"
              value={transferBilling}
              disabled={true}
            />
          </div>
        </ModalBody>
      )}

      <ModalFooter>
        <ProgressButton
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          autoFocus={true}
          onClick={handleClickSend}
        >
          Send transfer request
        </ProgressButton>

        <ModalCancelButton/>
      </ModalFooter>

      <div className="confirm-modal-transfer-ownership-prompt__footnote">
        * If ownership transfer is accepted, your role will change to <strong>Administrator</strong>
      </div>
    </ModalContainer>
  );
};

export const TransferTeamOwnershipPrompt = {
  show({
    teamId,
    teamName,
    userId,
    userName,
    isFreePlan,
    onDone,
  }: TransferTeamOwnershipPromptProps) {
    Modal.show({
      className: 'confirm-modal-transfer-ownership-prompt',
      fullWidth: true,
      maxWidth: SIZE.M,
      children: (
        <Content
          teamId={teamId}
          teamName={teamName}
          userId={userId}
          userName={userName}
          isFreePlan={isFreePlan}
          onDone={onDone}
        />
      )
    });
  }
};
