import React from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {UsersPanelListItem} from 'app/components/Teams/UsersPanel/UsersPanelList/UsersPanelListItem';
import {Callback, ClassName} from 'app/types/common';
import {User} from 'app/models/User/UserModel';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';

interface Props extends ClassName {
  users: User[];
  currentUserId: string;
  currentUserRole: AnyUserRoleModel;
  changeOwner: Callback;
  loading: boolean;
  onUpdate: Callback;
  onDelete: Callback;
}

export const UsersPanelList = ({
  className,
  users,
  currentUserId,
  currentUserRole,
  changeOwner,
  loading,
  onUpdate,
  onDelete,
}: Props) => (
  <div className={classNames('users-panel-list', className)}>
    <div className="users-panel-list__title">
      Team members
    </div>

    {loading ? (
      <div className="users-panel-list__loading">
        {Icons.spinner().reactComponent()}
      </div>
    ) : (
      <div
        className="users-panel-list__list"
        data-id="users_list"
      >
        {users.map(user => (
          <UsersPanelListItem
            key={user.getId()}
            className="users-panel-list__list-item"
            user={user}
            currentUserRole={currentUserRole}
            currentUserId={currentUserId}
            changeOwner={changeOwner}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        ))}
      </div>
    )}
  </div>
);
