import {UserLoginType, UserRole} from 'app/constants';
import {TeamMemberStatus} from 'app/data/Status';

export interface User {
  getId: () => string;
  getName: () => string;
  getEmail: () => string | undefined;
  getLoginType: () => UserLoginType | undefined;
  getPicture: () => string | undefined;
  getStatus: () => TeamMemberStatus | undefined;
  getRole: () => UserRole | undefined;
  isMaster: () => boolean;
  toJSON: () => UserData | undefined;
}

export interface UserData {
  ID: string;
  Name: string;
  LoginType: UserLoginType;
  Status: TeamMemberStatus;
  UserRole: UserRole;
  IsMaster: boolean;
  Picture?: string;
  Email?: string;
}

export class UserModel implements User {
  protected data: UserData | undefined;

  constructor(json?: UserData) {
    this.data = json;
  }

  getId(): string {
    return this.data?.ID ?? '';
  }

  getName(): string {
    return this.data?.Name ?? '';
  }

  getEmail(): string | undefined {
    return this.data?.Email;
  }

  getLoginType(): UserLoginType | undefined {
    return this.data?.LoginType;
  }

  getPicture(): string | undefined {
    return this.data?.Picture;
  }

  getStatus(): TeamMemberStatus | undefined {
    return this.data?.Status;
  }

  getRole(): UserRole | undefined {
    return this.data?.UserRole;
  }

  isMaster(): boolean {
    return this.data?.IsMaster === true;
  }

  toJSON(): UserData | undefined {
    if (this.data) {
      return {
        ...this.data
      };
    }
  }
}
