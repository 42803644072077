import React, {createContext, PropsWithChildren, useContext, useMemo} from 'react';

const flags = ['usb-pairing', 'files'] as const;

export type AppFeature = typeof flags[number];

const FeaturesContext = createContext<Set<AppFeature>>(new Set());

export function ApplicationFeatures({children}: PropsWithChildren<{}>) {
  const features = useMemo(() => {
    if (typeof CIRRUS_FEATURE_FLAGS !== 'string') {
      return new Set<AppFeature>();
    }

    const parsed = CIRRUS_FEATURE_FLAGS.split(',') as AppFeature[];
    return new Set(parsed.filter((flag) => flags.includes(flag)));
  }, []);

  return <FeaturesContext.Provider value={features}>{children}</FeaturesContext.Provider>;
}

export function useFeature(feature: AppFeature) {
  const features = useContext(FeaturesContext);
  return features.has(feature);
}
