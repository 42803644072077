import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {observer} from 'mobx-react';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {DevicesAlertsCounter} from 'app/components/DevicesAlerts/DevicesAlertsCounter';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {LongNameBreakAllWrapper} from 'app/components/sharedReactComponents/LongNameWrapper';
import {useCurrentTeamStore} from 'app/store/hooks';
import {useTeamBilling} from 'app/components/BillingManager/hooks/useTeamBilling';
import {usePairedQuery} from 'app/hooks/usePairedQuery';
import {isNil} from 'app/util/isNil';
import {EdgeCard} from 'app/components/Settings/BillingAndUsage/cards/EdgeCard/EdgeCard';
import {LiveScryptCard} from 'app/components/Settings/BillingAndUsage/cards/LiveScryptCard/LiveScryptCard';
import {ConnectCard} from 'app/components/Settings/BillingAndUsage/cards/ConnectCard/ConnectCard';
import {UnifyCard} from 'app/components/Settings/BillingAndUsage/cards/UnifyCard/UnifyCard';
import {UsageAndInvoices} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/UsageAndInvoices';
import {PaymentInformation} from 'app/components/Settings/BillingAndUsage/PaymentInformation/PaymentInformation';

export const BillingAndUsage = observer(() => {
  const team = useCurrentTeamStore();

  const teamId = team.id;
  const teamName = team.getName();

  const {cloud, connect, payment, actions, unify} = useTeamBilling();

  const pairedQuery = usePairedQuery({teamId});
  const {data: counts} = pairedQuery;

  const isLoading = pairedQuery.isInitialLoading || isNil(payment) || isNil(cloud) || isNil(counts);

  return (
    <Box>
      <PageHeader
        buttons={
          <>
            <DevicesAlertsCounter />
          </>
        }
      />

      {isLoading ? (
        <IndeterminateProgressBar />
      ) : (
        <Box className="container" sx={{py: 4}}>
          <Typography sx={{opacity: 0.5, mb: 0.5}}>Settings</Typography>

          <Typography variant="h5" fontWeight={600} mb={5}>
            Billing and Usage for <LongNameBreakAllWrapper>{teamName}</LongNameBreakAllWrapper>
          </Typography>

          <Stack direction="row" justifyContent="space-between" mb={5}>
            <EdgeCard plan={cloud} total={counts.devices} onChange={() => actions.manageCloud()} />

            <UnifyCard
              plan={unify}
              total={counts.projects}
              onManage={() => actions.manageUnify()}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between" mb={5}>
            <ConnectCard
              plan={connect}
              pending={payment?.pendingInvoicePayment}
              onManage={() => actions.manageConnect()}
            />

            <LiveScryptCard
              total={counts.lvs}
              configured={cloud.configured}
              price={cloud.prices.transcriber}
              onEditPayment={() => actions.managePayment()}
            />
          </Stack>

          <PaymentInformation
            sx={{mb: 5}}
            payment={payment}
            onEnterCoupon={() => actions.enterCoupon()}
            onEditPayment={() => actions.managePayment()}
            onCancelRequest={() => actions.cancelInvoiceRequest()}
          />

          <UsageAndInvoices teamId={teamId} cloud={cloud} connect={connect} />
        </Box>
      )}
    </Box>
  );
});
