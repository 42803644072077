import {maintenance, front} from 'app/api/CirrusApi';
import {MaintenanceContract} from 'app/contracts/maintenance';

export class MaintenanceApiService {
  static async getMaintenance(): Promise<MaintenanceContract.Entity[]> {
    return maintenance.windows().get();
  }

  static async getVersion(): Promise<MaintenanceContract.Version> {
    return front.versions().get();
  }
}
