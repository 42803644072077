import {useQuery} from '@tanstack/react-query';
import {Device} from 'app/components/DeviceDetails/Models/types';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {AnyDeviceModelType, createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';

interface Args {
  enabled: boolean;
  key: string;
  teamId: string;
  ids: string[];
  cacheTime?: number;
}

export function useDevicesQuery({key, enabled, ids, teamId, cacheTime}: Args) {
  const query = useQuery({
    queryKey: [key, teamId, ids],
    queryFn: async () => {
      const contract = await DeviceApiService.getUnits(ids);
      return mapDevices(contract);
    },
    enabled,
    cacheTime,
  });

  return query;
}

function mapDevices(data: Device[]): Map<string, AnyDeviceModelType> {
  const mapped = data.map((d) => createDeviceModel(d));
  return new Map(mapped.map((m) => [m.getId(), m]));
}
