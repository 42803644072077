import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {sortBy} from 'ramda';
import {InviteUserForm} from 'app/components/Teams/UsersPanel/InviteUserForm';
import {UsersPanelList} from 'app/components/Teams/UsersPanel/UsersPanelList';
import {UserRole} from 'app/constants';
import {UserModel, UserData} from 'app/models/User/UserModel';
import {Callback, Sx} from 'app/types/common';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {RepeatedCall} from 'app/util/RepeatedCall';
import {secondsToMilliseconds, minutesToMilliseconds} from 'app/util/timeConverter';
import {teamApiService} from 'app/services/api/team/TeamApiService';
import {Notifications} from 'app/components/Notifications';
import {TeamUser} from 'app/models/User/TeamUser';
import {Box} from '@mui/material';

const sortUsers = (currentUserId: string) => (users: UserModel[]) =>
  sortBy<UserModel>((user) => {
    if (user.getId() === currentUserId) {
      return -200;
    }

    if (user.isMaster()) {
      return -100;
    }

    const index = [UserRole.Operator, UserRole.Editor, UserRole.Owner].indexOf(user.getRole()!);

    return -index;
  }, users);

interface Props extends Sx {
  teamId: string;
  user: TeamUser;
  changeOwner: Callback;
}

export const UsersPanel = ({sx, teamId, user, changeOwner}: Props) => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const repeatedCall = useRef(
    new RepeatedCall({
      call: async ({getAbort}) => {
        try {
          const response = await teamApiService.getUsers<UserData>(teamId, getAbort);
          const mapped = response.map((data) => new UserModel(data));
          const sorted = sortUsers(user.id)(mapped);
          setUsers(sorted);
        } catch {
          setUsers([]);
        }
      },
      initialTimeoutMs: secondsToMilliseconds(10),
      maxTimeoutMs: minutesToMilliseconds(2),
    }),
  );

  useEffect(() => {
    const instance = repeatedCall.current;

    const load = async () => {
      try {
        setLoadingPage(true);
        await instance.start(true);
      } finally {
        setLoadingPage(false);
      }
    };

    void load();

    return () => {
      instance.stop();
    };
  }, [teamId]);

  const reload = useCallback(async () => {
    const instance = repeatedCall.current;
    try {
      setLoadingUsers(true);
      await instance.restart(true);
    } finally {
      setLoadingUsers(false);
    }
  }, []);

  const handleUpdate = useCallback(
    async (userId: string, role: UserRole) => {
      try {
        await teamApiService.setUserRole(teamId, userId, role);
      } catch {
        Notifications.addErrorNotification('Could not update user role');
      }

      return reload();
    },
    [teamId, reload],
  );

  const handleDelete = useCallback(
    async (userId: string) => {
      try {
        await teamApiService.deleteUser(teamId, userId);
      } catch {
        Notifications.addErrorNotification('Could not delete user');
      }

      return reload();
    },
    [teamId, reload],
  );

  if (loadingPage) {
    return <IndeterminateProgressBar />;
  }

  return (
    <Box sx={sx} className="container">
      <div className={classNames('users-panel')}>
        <div className="users-panel__users-list">
          <UsersPanelList
            users={users}
            loading={loadingUsers}
            currentUserId={user.id}
            currentUserRole={user.role}
            changeOwner={changeOwner}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
          />
        </div>

        {user.role.canEditTeams() && (
          <div className="users-panel__invite-user" data-id="invite_user_form">
            <InviteUserForm teamId={teamId} onUserInvite={reload} />
          </div>
        )}
      </div>
    </Box>
  );
};
