import {useCallback, useEffect, useMemo, useState} from 'react';
import {differenceOfSets} from 'app/util/differenceOfSets';
import {isSetsEquals} from 'app/util/isSetsEquals';
import {toggleInSet} from 'app/util/toggleInSet';
import {unionOfSets} from 'app/util/unionOfSets';
import {Files} from 'app/domain/files';

type Return = {
  selected: Files.Recording[];
  allSelected: boolean;
  select: (items: string | string[]) => void;
  deselect: (items: string | string[]) => void;
  isSelected: (item: string) => boolean;
  toggle: (item: string) => void;
  deselectAll: () => void;
  selectAll: () => void;
};

export function useSelectRecordings(recordings: Files.Recording[]): Return {
  const [selectedSet, setSelectedSet] = useState(new Set<string>());
  const [selected, setSelected] = useState<Files.Recording[]>([]);

  useEffect(() => {
    setSelectedSet((selected) => {
      const actual = new Set(recordings.map((r) => r.id));

      const filtered = Array.from(selected.values()).filter((value) => actual.has(value));
      return new Set(filtered);
    });
  }, [recordings]);

  useEffect(() => {
    setSelected(recordings.filter((r) => selectedSet.has(r.id)));
  }, [recordings, selectedSet]);

  const select = useCallback((items: string | string[]) => {
    setSelectedSet((prev) => {
      const setToAdd = itemsToSet(items);
      const next = unionOfSets(prev, setToAdd);

      return isSetsEquals(next, prev) ? prev : next;
    });
  }, []);

  const deselect = useCallback((items: string | string[]) => {
    setSelectedSet((prev) => {
      const setToRemove = itemsToSet(items);
      const next = differenceOfSets(prev, setToRemove);

      return isSetsEquals(next, prev) ? prev : next;
    });
  }, []);

  const toggle = useCallback((item: string) => {
    setSelectedSet((prev) => toggleInSet(prev, item));
  }, []);

  const isSelected = useCallback((item: string) => selectedSet.has(item), [selectedSet]);

  const allSelected = useMemo(() => {
    if (!recordings.length) {
      return false;
    }

    return recordings.every((r) => selectedSet.has(r.id));
  }, [selectedSet, recordings]);

  const selectAll = useCallback(() => {
    setSelectedSet(new Set(recordings.map((r) => r.id)));
  }, [recordings]);

  const deselectAll = useCallback(() => {
    setSelectedSet(new Set());
  }, []);

  return {selected, select, deselect, isSelected, toggle, allSelected, deselectAll, selectAll};
}

function itemsToSet(items: string | string[]): Set<string> {
  if (Array.isArray(items)) {
    return new Set(items);
  }

  return new Set([items]);
}
