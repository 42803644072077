import {createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {transcribeDeviceDetailsURL} from 'app/components/Dashboard/TranscribeDevices/actions';
import {Device} from 'app/components/DeviceDetails/Models/types';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';
import {TranscribeDevicesTableListItem} from 'app/components/Dashboard/TranscribeDevices/types';

type TableListProps = {
  devices: LiveScryptDeviceModel[];
  list: TranscribeDevicesTableListItem[];
};

function processTableList(devices: Device[]): TableListProps {
  const deviceModels: any[] = [];
  const list: TranscribeDevicesTableListItem[] = [];

  const addDevice = (deviceModel) => {
    if (deviceModel.isUnpaired()) {
      return;
    }

    const deviceCapabilities = deviceModel.getCapabilities();

    if (deviceCapabilities.canTranscribing()) {
      deviceModels.push(deviceModel);
      list.push(processTableListItem(deviceModel));
    }
  };

  devices.forEach(device => {
    const deviceModel = createDeviceModel(device);
    addDevice(deviceModel);
  });

  return {
    devices: deviceModels,
    list,
  };
}

function processTableListItem(deviceModel: LiveScryptDeviceModel): TranscribeDevicesTableListItem {
  const id = deviceModel.getId();

  return {
    id,
    name: deviceModel.getName(),
    detailsURL: transcribeDeviceDetailsURL(id),
    online: deviceModel.isOnline(),
    transcribing: deviceModel.getTranscribingInfo(),
    audioLevel: `audio_${id}`,
    deviceModel,
  };
}

export {
  processTableList,
};
