import {isNil} from 'app/util/isNil';
import {front} from 'app/api/CirrusApi';
import {CommandOptions, Device, DeviceSetting} from 'app/components/DeviceDetails/Models/types';
import {CommonDeviceDto, DeviceDetailsDto} from 'app/store/dto/device/Device';
import {GetAbortCallback} from 'app/api/types';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {getDeviceModel} from 'app/store/models/device/utils';
import {Schedule} from 'app/domain/schedule';
import {EdgeContract} from 'app/contracts/edge';
import {CloudContract} from 'app/contracts/cloud';
import {Edge} from 'app/domain/edge';

export class DeviceApiService {
  rename = async (id: string, name: string) => front.devices(id).rename().post({Name: name});

  sendCommand = async (id: string, command: string, opt: CommandOptions) =>
    front
      .devices(id)
      .task()
      .post({cmd: command, ...opt});

  startStreaming = async (deviceId: string, destination: string) =>
    front.streams(destination).start().post({DeviceID: deviceId});

  stopStreaming = async (deviceId: string, destination: string) =>
    front.streams(destination).stop().post({DeviceID: deviceId});

  static async getSources(
    deviceId: string,
    getAbort?: GetAbortCallback,
  ): Promise<EdgeContract.Source[]> {
    const result = await front.devices(deviceId).sources().get(undefined, {getAbort});

    if (isNil(result) || !Array.isArray(result)) {
      return [];
    }
    return result;
  }

  static async getSystemStatus(
    deviceId: string,
    getAbort?: GetAbortCallback,
  ): Promise<EdgeContract.System | null> {
    return front.devices(deviceId).system().status().get(undefined, {getAbort});
  }

  static async getStorage(
    deviceId: string,
    getAbort?: GetAbortCallback,
  ): Promise<EdgeContract.Storage | null> {
    return front.devices(deviceId).storage().get(undefined, {getAbort});
  }

  static async getAfu(
    deviceId: string,
    getAbort?: GetAbortCallback,
  ): Promise<EdgeContract.Afu | null> {
    return front.devices(deviceId).afu().get(undefined, {getAbort});
  }

  deleteUnifyProject = async (projectId: string, teamId: string): Promise<void> =>
    front.team(teamId).devices().vpearl(projectId).delete();

  pairRealDevice = async (teamId: string, dto: Payloads.Cloud.PairDevice): Promise<{ID: string}> =>
    front.team(teamId).devices().post(dto);

  deleteDevice = async (deviceId: string): Promise<void> => front.devices(deviceId).delete();

  stopUnifyProject = async (deviceId: string): Promise<void> =>
    front.devices().vpearl(deviceId).stop().post();

  static async getUrlRemoteLogin(deviceId: string): Promise<EdgeContract.RemoteLogin> {
    return front.devices(deviceId).remote_login().get();
  }

  static async getProjects<T = CommonDeviceDto>(getAbort?: GetAbortCallback): Promise<T[]> {
    return front.devices().vpearls().get(undefined, {getAbort});
  }

  static async retrieveDevice(id: string): Promise<Device> {
    return front.devices(id).get(undefined);
  }

  static async getPairedCount(): Promise<Contracts.Core.PairedCount> {
    return front.devices().devicenumbers().get(undefined);
  }

  static async getTeamPairedCount(teamId: string): Promise<Contracts.Core.PairedCount> {
    return front.team(teamId).devices().devicenumbers().get();
  }

  static async pairDevice(teamId: string, p: Payloads.Cloud.PairDevice): Promise<{ID: string}> {
    return front.team(teamId).devices().post(p);
  }

  static async getGroups(teamId: string): Promise<Edge.Group[]> {
    return front.team(teamId).groups().get();
  }

  static async createUnify(teamId: string, payload: {name: string}) {
    return front.team(teamId).devices().vpearl().post(payload);
  }

  static async getUnits(ids: string[] = []): Promise<Device[]> {
    return front.devices().get(ids.length ? `ids=${ids.join(',')}` : undefined);
  }

  static async getFirmware(
    deviceId: string,
    getAbort?: GetAbortCallback,
  ): Promise<Edge.Firmware | null> {
    return front.devices(deviceId).update().status().get(undefined, {getAbort});
  }

  static async getDevicesFirmware(ids: string[]): Promise<Record<string, Edge.Firmware>> {
    return front.devices().firmware().update().status().post({devices: ids});
  }

  async getDevices<T = CommonDeviceDto>(getAbort: GetAbortCallback): Promise<T[]> {
    const all = (await front.devices().get(undefined, {getAbort})) as T[];
    return all.filter((d) => !ModelService.isUnify(getDeviceModel(d as any)));
  }

  async getDevice<T = DeviceDetailsDto>(deviceId: string, getAbort?: GetAbortCallback): Promise<T> {
    return front.devices(deviceId).get(undefined, {getAbort});
  }

  async getDeviceSettings(deviceId: string, getAbort?: GetAbortCallback): Promise<DeviceSetting[]> {
    return front
      .devices(deviceId)
      .settings()
      .get(undefined, {getAbort})
      .then((settings: DeviceSetting[]) => {
        return settings ?? [];
      })
      .catch(() => {
        return [];
      });
  }

  static async batchTask(command: Payloads.Device.BatchCommand) {
    return front.devices().batch_task().post(command);
  }

  static async getDestinations(): Promise<CloudContract.StreamDestination[]> {
    return front.streams().get();
  }

  static getSnapshotUrl(id: string): string {
    return front.devices(id).preview().url();
  }

  static setDeviceCms(id: string, cms: Schedule.Cms | '') {
    return front.devices(id).cms().put({cms});
  }

  static getTranscriberSessions(
    deviceId: string,
  ): Promise<{data: EdgeContract.TranscribeSession[]} | null> {
    return front.transcribing(deviceId).sessions().get(undefined);
  }

  static getNexusPairingFile(): string {
    return front.devices().pairing_stick().url();
  }

  static getTranscriberSessionUrl(
    deviceId: string,
    sessionId: string,
    type: 'srt' | 'txt',
  ): string {
    const base = front.transcribing(deviceId).sessions(sessionId);

    if (type === 'srt') {
      return base.srt().url();
    }

    return base.txt().url();
  }

  static deleteTranscribeSession(deviceId: string, sessionId: string): Promise<void> {
    return front.transcribing(deviceId).sessions(sessionId).delete();
  }

  static async startProject(deviceId: string): Promise<void> {
    return front.devices().vpearl(deviceId).resume().post();
  }
  static async stopProject(deviceId: string): Promise<void> {
    return front.devices().vpearl(deviceId).stop().post();
  }

  static async deleteProject(projectId: string, teamId: string): Promise<void> {
    return front.team(teamId).devices().vpearl(projectId).delete();
  }

  static async deleteDevice(deviceId: string): Promise<void> {
    return front.devices(deviceId).delete();
  }

  static async sendBatchTask(
    command: string,
    devices: string[],
  ): Promise<EdgeContract.BatchResponse> {
    return front
      .devices()
      .batch_task()
      .post({
        Devices: devices,
        Task: {cmd: command},
      });
  }
}

export const deviceApiService = new DeviceApiService();
