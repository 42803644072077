import {useMouseHovered} from 'react-use';
import React, {useState} from 'react';
import {Box} from '@mui/material';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

type Props = Sx & {
  thumbnail: string;
  filmstrip: string;
  width: number;
};

export function Filmstrip({sx, filmstrip, thumbnail, width}: Props) {
  const [playStrip, setPlayStrip] = useState(false);

  const ref = React.useRef<HTMLElement | null>(null);
  const {elX} = useMouseHovered(ref, {bound: true, whenHovered: true});

  const step = width / 10;
  const offset = Math.trunc(elX / step);

  const bg = getBackground({playStrip, filmstrip, thumbnail, offset: width * offset});

  return (
    <Box
      ref={ref}
      sx={packSx({background: bg, cursor: 'pointer'}, sx)}
      width={width}
      onMouseEnter={() => setPlayStrip(true)}
      onMouseLeave={() => setPlayStrip(false)}
    />
  );
}

type Args = {
  playStrip: boolean;
  thumbnail: string;
  filmstrip: string;
  offset: number;
};

function getBackground({playStrip, filmstrip, thumbnail, offset}: Args): string {
  const thumbnailBg = `url(${thumbnail}) no-repeat 0px / cover`;
  const filmstripBg = `url(${filmstrip}) no-repeat -${offset}px / cover`;

  return playStrip ? [filmstripBg, thumbnailBg].join(', ') : thumbnailBg;
}
