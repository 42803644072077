import React, {ReactNode} from 'react';
import {Box, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {FirmwareStatus} from 'app/components/DeviceDetails/DeviceInfoTab/FirmwareStatus';

export type UnitField = 'model' | 'sn' | 'ip' | 'mac' | 'firmware' | 'host';

interface Props extends Sx {
  field: UnitField;
  device: AnyDeviceModelType;
}

export function DeviceField({field, device}: Props) {
  const {title, content} = useDeviceField({field, device});

  return (
    <Box>
      <Typography fontWeight={600} fontSize={16} mb={0.5}>
        {title}
      </Typography>

      <Box>{content}</Box>
    </Box>
  );
}

interface FieldMeta {
  title: string;
  content: ReactNode;
}

interface Args {
  field: UnitField;
  device: AnyDeviceModelType;
}

function useDeviceField({field, device}: Args): FieldMeta {
  switch (field) {
    case 'model':
      return {
        title: 'Model',
        content: <Typography data-id="system_info_model">{device.getModelName()}</Typography>,
      };

    case 'sn':
      return {
        title: 'Serial number',
        content: (
          <Typography data-id="system_info_serial_number">
            {device.getSerialNumber() || '-'}
          </Typography>
        ),
      };

    case 'ip':
      return {
        title: 'IP address',
        content: (
          <Typography data-id="system_info_ip_address">{device.getIPAddress() ?? '-'}</Typography>
        ),
      };

    case 'mac':
      return {
        title: 'MAC address',
        content: (
          <Typography data-id="system_info_mac_address">{device.getMACAddress() ?? '-'}</Typography>
        ),
      };

    case 'firmware':
      return {
        title: 'Firmware',
        content: <FirmwareStatus device={device} />,
      };

    default:
      return {
        title: 'Host',
        content: <Typography data-id="system_info_host">{device.getHostName()}</Typography>,
      };
  }
}
