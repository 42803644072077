import React, {useMemo} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {filterByDeviceModel} from 'app/components/Presets/utils';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {Edge} from 'app/domain/edge';
import {PresetApiService} from 'app/services/api/preset/PresetApiService';
import {downloadPreset} from 'app/components/DeviceDetails/PresetsTab/utils';
import {PresetCard} from 'app/components/sharedReactComponents/DevicePresetCard/PresetCard/PresetCard';
import {DevicePresetUploader} from 'app/components/sharedReactComponents/PresetUploader/DevicePresetUploader';
import {EdgeContract} from 'app/contracts/edge';

interface Props extends Sx {
  presets: Edge.TeamPreset[];
  loading: boolean;
  deviceId: string;
  deviceName: string;
  model: string;
  offline: boolean;
  available: boolean;
  permitApply: boolean;
  permitDelete: boolean;
  onRefetch: () => Promise<any>;
}

export function TeamPresets({
  sx,
  presets,
  loading,
  deviceId,
  deviceName,
  model,
  offline,
  permitApply,
  permitDelete,
  available,
  onRefetch,
}: Props) {
  const modelPresets = useMemo(() => filterByDeviceModel(presets, model), [presets, model]);

  const handleUploadPresetFile = async () => {
    await onRefetch();
  };

  const handleApply = async (preset: Edge.Preset) => {
    if (Edge.isTeamPreset(preset)) {
      await PresetApiService.applyTeamPreset(deviceId, {
        id: preset.id,
        name: preset.name,
        sections: preset.sections,
      });
    }
  };

  const handleDownload = (preset: Edge.Preset) => {
    if (Edge.isTeamPreset(preset)) {
      downloadPreset(preset.id, `${preset.name}.preset`);
    }
  };

  const handleDelete = async (preset: Edge.Preset) => {
    if (Edge.isTeamPreset(preset)) {
      await PresetApiService.deleteTeamPreset(preset.id);
      await onRefetch();
    }
  };

  const handleUpdate = async (id: string, c: EdgeContract.UpdatePreset) => {
    await PresetApiService.updateTeamPreset(id, c);
  };

  return (
    <Box sx={sx}>
      <Typography fontWeight={600} variant="h5" mb={2}>
        Team presets
      </Typography>

      {loading && <LoadingPlaceholder />}

      {!loading && (
        <Box>
          <Stack gap={2}>
            {modelPresets.map((preset) => (
              <PresetCard
                dataId={preset.id}
                key={preset.hash}
                preset={preset}
                deviceName={deviceName}
                offline={offline}
                showModel={false}
                permitApply={permitApply}
                permitDelete={permitDelete}
                available={available}
                onApply={handleApply}
                onDelete={handleDelete}
                onDownload={handleDownload}
                onUpdate={handleUpdate}
              />
            ))}

            <DevicePresetUploader
              size={presets.length > 0 ? 'small' : 'large'}
              onUpload={handleUploadPresetFile}
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}
