import React, {useContext, useMemo} from 'react';
import {observer} from 'mobx-react';
import {useQuery} from '@tanstack/react-query';
import {InitializationPage} from 'app/components/InitializationPage/InitializationPage';
import {TeamUser} from 'app/models/User/TeamUser';
import {UserApiService} from 'app/services/api/user/UserApiService';
import {AppMapper} from 'app/services/mappers/AppMapper/AppMapper';
import {TeamApiService} from 'app/services/api/team/TeamApiService';
import {AppTeam} from 'app/models/AppTeam/AppTeam';
import {stringComparator} from 'app/util/Sort';
import {isNil} from 'app/util/isNil';
import {useCurrentTeamStore} from 'app/store/hooks';

interface CoreState {
  user: TeamUser;
  teams: AppTeam[];
  refetchUser: Fn<Promise<any>>;
}

const CoreContext = React.createContext<CoreState | undefined>(undefined);

function CurrentUser({children}: React.PropsWithChildren<{}>) {
  const currentTeamStore = useCurrentTeamStore();

  const teams = useUserTeamsQuery();
  const user = useTeamUserQuery(currentTeamStore.initialized);

  const context = useMemo<CoreState | undefined>(() => {
    if (!user.data || !teams.data) {
      return undefined;
    }

    return {
      teams: teams.data,
      user: user.data,
      refetchUser: user.refetch,
    };
  }, [user.data, user.refetch, teams.data]);

  if (user.isError) {
    throw new Error('Could not load user');
  }

  if (user.isInitialLoading || isNil(context)) {
    return <InitializationPage />;
  }

  return <CoreContext.Provider value={context}>{children}</CoreContext.Provider>;
}

export const ApplicationUser = observer(CurrentUser);

function useTeamUserQuery(enabled: boolean) {
  return useQuery({
    queryKey: ['team-user'],
    queryFn: UserApiService.getUser,
    select: AppMapper.mapTeamUser,
    enabled,
  });
}

function mapTeams(list: Contracts.Core.Team[]): AppTeam[] {
  return list.map(AppMapper.mapAppTeam).sort((a, b) => stringComparator(a.name, b.name));
}

function useUserTeamsQuery() {
  return useQuery({
    queryKey: ['teams'],
    queryFn: TeamApiService.getTeams,
    select: mapTeams,
  });
}

export function useCoreEntities() {
  const ctx = useContext(CoreContext);

  if (!ctx) {
    throw new Error('Not in user context');
  }

  return ctx;
}
