import React from 'react';
import {Component, Sx} from 'app/types/common';
import {Box, Divider, Stack, Typography} from '@mui/material';
import {formatPrice} from 'app/util/prices';
import {packSx} from 'app/util/packSx/packSx';
import {usageSx} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/shared/styles';
import {formatAbbreviatedDateWithYear} from 'app/util/time';
import {palette} from 'app/themes/app';

export interface Row {
  name: string;
  total: number;
  description: React.ReactNode;
}

interface Props extends Sx, Component {
  issueDate: TimeStampSeconds;
  period: App.Billing.Period;
  rows: Row[];
  total: number;
}

export function InvoiceCard({sx, dataId, issueDate, period, rows, total}: Props) {
  return (
    <Stack data-id={dataId} sx={packSx(usageSx.invoice, sx)} gap={2}>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h5" fontWeight={600}>
            Upcoming invoice
          </Typography>

          <Typography data-id="invoice-period" color={palette.darkerGray}>
            {formatAbbreviatedDateWithYear(period.start)} -{' '}
            {formatAbbreviatedDateWithYear(period.end)}
          </Typography>
        </Stack>
        <Typography data-id="invoice-date" color={palette.blue}>
          Will be issued on: {formatAbbreviatedDateWithYear(issueDate)}
        </Typography>
      </Box>

      <Stack gap={2} divider={<Divider flexItem={true} />}>
        {rows.map((r, i) => {
          return (
            <Box key={r.name} data-id={`invoice-row-${i}`}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
                <Typography data-id="invoice-row-name" fontSize={16}>
                  {r.name}
                </Typography>
                <Typography data-id="invoice-row-total" fontSize={16} fontWeight={600}>
                  ${formatPrice(r.total, 2)}
                </Typography>
              </Stack>

              <Typography data-id="invoice-row-description">{r.description}</Typography>
            </Box>
          );
        })}
      </Stack>

      <Typography
        data-id="invoice-total"
        fontSize={16}
        fontWeight={600}
        color={palette.blue}
        textAlign="right"
      >
        Total period amount: ${formatPrice(total, 2)}
      </Typography>
    </Stack>
  );
}
