import React from 'react';
import {Cloud} from 'app/domain/cloud';
import {Divider, Stack} from '@mui/material';
import {UnitAlert} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertTableBody/UnitAlert';

interface Props {
  alertTypes: Cloud.AlertType[];
  deviceAlerts: Cloud.AlertSettings[];
  disabled: boolean;
  hoveredAlert?: Cloud.AlertType;
  onChange: (id: string, type: Cloud.AlertType, value: boolean) => void;
}

export function AlertTableBody({
  deviceAlerts,
  alertTypes,
  hoveredAlert,
  disabled,
  onChange,
}: Props) {
  return (
    <Stack divider={<Divider flexItem={true} orientation="horizontal" />}>
      {deviceAlerts.map((deviceAlert) => (
        <UnitAlert
          key={deviceAlert.id}
          alertTypes={alertTypes}
          deviceAlert={deviceAlert}
          disabled={disabled}
          hoveredAlert={hoveredAlert}
          onChange={onChange}
        />
      ))}
    </Stack>
  );
}
