import {parseChannelDeviceShortId} from 'app/components/DeviceDetails/utils';
import {DeviceModel} from 'app/components/DeviceDetails/Models/DeviceModel';
import {DeviceCommandDispatcher} from 'app/util/CommandDispatcher';
import {Device} from 'app/components/DeviceDetails/Models/types';
import {
  DeviceCapability,
  DeviceModel as DeviceModelName,
} from 'app/components/DeviceDetails/constants';

export interface PearlSlotDeviceModelAdditionalProps {
  masterDeviceName: string;
  masterCapabilities: DeviceCapability[];
  masterModelName: DeviceModelName;
}

export class PearlSlotDeviceModel extends DeviceModel {
  private readonly masterDeviceName: string;
  private readonly masterCapabilities: DeviceCapability[];
  private readonly masterModelName: DeviceModelName;

  constructor(props: Device, {
    masterDeviceName,
    masterCapabilities,
    masterModelName,
  }: PearlSlotDeviceModelAdditionalProps) {
    super(props);
    this.masterCapabilities = masterCapabilities ?? [];
    this.masterDeviceName = masterDeviceName;
    this.masterModelName = masterModelName;
  }

  /**
   * @deprecated
   */
  getCapabilitiesRaw(): DeviceCapability[] {
    return this.masterCapabilities;
  }

  /**
   * @deprecated
   */
  getName() {
    return this.getUserProperties()?.Name ?? super.getName();
  }

  /**
   * @deprecated
   */
  setName(name: string) {
    this.setUserProperty('Name', name);
  }

  /**
   * @deprecated
   */
  getChannelDeviceMasterDeviceName() {
    return this.masterDeviceName;
  }

  getModelName(): DeviceModelName {
    return DeviceModelName.Channel;
  }

  getMasterModelName() {
    return this.masterModelName;
  }

  /**
   * @deprecated
   */
  getChannelDeviceIdIndex(): string {
    return parseChannelDeviceShortId(this.getId());
  }

  /**
   * @deprecated
   */
  getChannelDeviceMasterDeviceId() {
    return this.device.Master;
  }

  /**
   * @deprecated
   */
  async sendLevelsStartCommand(): Promise<any> {
    return DeviceCommandDispatcher.audioLevels(this.getChannelDeviceMasterDeviceId());
  }

  /**
   * @deprecated
   */
  async sendChannelPreviewsStartCommand(): Promise<any> {
    return DeviceCommandDispatcher.channelPreviews(this.getChannelDeviceMasterDeviceId());
  }

  /**
   * @deprecated
   */
  private getUserProperties() {
    return this.device.UserProperties;
  }

  /**
   * @deprecated
   */
  private setUserProperty(name: string, value: any) {
    if (!this.device.UserProperties) {
      this.device.UserProperties = {};
    }

    this.device.UserProperties[name] = value;
  }
}
