import React from 'react';
import {Sx} from 'app/types/common';
import {Box, Typography} from '@mui/material';
import {useConnectReport} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/ConnectUsageAndInvoices/useConnectUsageAndInvoices';
import {NoActivePlan} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/ConnectUsageAndInvoices/NoActivePlan/NoActivePlan';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {isNil} from 'app/util/isNil';
import {ConnectInvoice} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/ConnectUsageAndInvoices/ConnectInvoice/ConnectInvoice';
import {ConnectHistory} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/ConnectUsageAndInvoices/ConnectHistory/ConnectHistory';
import {palette} from 'app/themes/app';

interface Props extends Sx {
  teamId: string;
  subscription?: App.Billing.Connect.Plan;
}

export function ConnectUsageAndInvoices({sx, teamId, subscription}: Props) {
  const {isInitialLoading, data} = useConnectReport({teamId, enabled: true});

  const renderUsage = (report: App.Billing.Connect.Report) => {
    if (!subscription || !report.invoice) {
      return <NoActivePlan sx={{mb: 3}} showMessage={report.history.length === 0} />;
    }

    const {invoice} = report;

    if (invoice) {
      return (
        <ConnectInvoice
          dataId="connect-invoice"
          sx={{mb: 3}}
          name={subscription.tier.name}
          invoice={invoice}
          period={subscription.period}
        />
      );
    }

    return null;
  };

  const renderBody = () => {
    const loading = isInitialLoading || isNil(data);

    if (loading) {
      return <LoadingPlaceholder />;
    }

    return (
      <React.Fragment>
        {renderUsage(data)}

        <Typography fontWeight={600} fontSize={16} mb={1}>
          Invoice history
        </Typography>

        {data.history.length > 0 ? (
          <ConnectHistory teamId={teamId} history={data.history} />
        ) : (
          <Typography fontWeight={600} textAlign="center" color={palette.darkerGray} my={2}>
            No previous invoices
          </Typography>
        )}
      </React.Fragment>
    );
  };

  return <Box sx={sx}>{renderBody()}</Box>;
}
