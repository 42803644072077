import React, {useState} from 'react';
import {Virtuoso} from 'react-virtuoso';
import {Box, Button, Drawer, Slide, Stack, TextField, Typography} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {Sx, TimestampRange} from 'app/types/common';
import {Files} from 'app/domain/files';
import {useSelectRecordings} from 'app/components/features/recordings/hooks/useSelectRecordings';
import {RecordingCard} from 'app/components/features/recordings/shared/RecordingCard/RecordingCard';
import {RecordingHeader} from 'app/components/features/recordings/shared/RecordingHeader/RecordingHeader';
import {BatchRecordings} from 'app/components/features/recordings/shared/BatchRecordings/BatchRecordings';
import {SortingSelector} from 'app/components/features/recordings/shared/SortingSelector/SortingSelector';
import {SortField, sortFields} from 'app/components/features/recordings/types';
import {useFilterRecordings} from 'app/components/features/recordings/hooks/useFilterRecordings';
import {useSortRecordings} from 'app/components/features/recordings/hooks/useSortRecordings';
import {NoFiltered} from 'app/components/features/recordings/shared/NoFiltered/NoFiltered';
import {RecordingsApi} from 'app/services/api/recordings/RecordingsApi';
import {downloadFile} from 'app/util/download';
import {NoRecordings} from 'app/components/features/recordings/shared/NoRecordings/NoRecordings';
import {RecordingsPeriod} from 'app/components/features/recordings/shared/RecordingsPeriod/RecordingsPeriod';
import {RecordingsSkeleton} from 'app/components/features/recordings/shared/RecordingsSkeleton/RecordingsSkeleton';

type Props = Sx & {
  loading: boolean;
  recordings: Files.Recording[];
  range: TimestampRange;
  setRange: React.Dispatch<React.SetStateAction<TimestampRange>>;
  reload: () => Promise<any>;
};

export function UnitRecordings({sx, loading, recordings, range, setRange, reload}: Props) {
  const [asideFilter, setAsideFilter] = useState(false);

  const {sort, setSort, sortFn} = useSortRecordings();
  const {filtered, search, setSearch} = useFilterRecordings({recordings, sortFn});

  const {selected, allSelected, deselectAll, selectAll, isSelected, toggle} =
    useSelectRecordings(filtered);

  const handleSelectAll = () => {
    if (allSelected) {
      deselectAll();
      return;
    }

    selectAll();
  };

  const handleClear = () => {
    setSearch('');
  };

  const totalSelected = selected.length;

  const hasRecordings = recordings.length > 0;
  const hasFiltered = filtered.length > 0;
  const hasSelected = totalSelected > 0;

  const handleDelete = async (id: string) => {
    await RecordingsApi.deleteRecording(id);
    await reload();
  };

  const handleDownload = async (id: string, name: string) => {
    const url = RecordingsApi.getDownloadUrl(id);
    downloadFile(url, name);
  };

  const renderList = () => {
    if (loading) {
      return <RecordingsSkeleton sx={{flex: 1, pt: 2}} lines={3} />;
    }

    if (!hasRecordings) {
      return <NoRecordings sx={{flex: 1}} range={range} />;
    }

    if (!hasFiltered) {
      return <NoFiltered sx={{flex: 1}} onClear={handleClear} />;
    }

    return (
      <>
        <RecordingHeader showUnit={false} />

        <Virtuoso
          data={filtered}
          useWindowScroll={true}
          itemContent={(_index, r) => {
            const {id, name} = r;
            return (
              <Box pb={1}>
                <RecordingCard
                  key={id}
                  dataId={id}
                  selected={isSelected(id)}
                  recording={r}
                  showUnit={false}
                  onSelect={() => toggle(id)}
                  onDelete={() => handleDelete(id)}
                  onDownload={() => handleDownload(id, name)}
                />
              </Box>
            );
          }}
        />
      </>
    );
  };

  return (
    <Stack sx={sx}>
      <Stack direction="row" alignItems="center" mb={1} height={38}>
        <Typography fontWeight={600} variant="h5">
          Recordings
        </Typography>

        <Stack ml="auto" direction="row" alignItems="center" gap={1}>
          <TextField
            sx={{width: 240, height: 34}}
            value={search}
            InputProps={{startAdornment: <SearchIcon />, placeholder: 'Search'}}
            inputProps={{'data-id': 'search-input'}}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />

          <SortingSelector
            dataId="sort-selector"
            sx={{minWidth: 150}}
            field={sort.field}
            direction={sort.direction}
            fields={sortFields}
            onSort={(field, direction) => setSort({direction, field: field as SortField})}
          />

          <Button
            data-id="filter-btn"
            variant="outlined"
            color="info"
            disableRipple={false}
            disabled={true}
            startIcon={<FilterAltIcon />}
            onClick={() => setAsideFilter(true)}
          >
            Filter
          </Button>
        </Stack>
      </Stack>

      <Stack mb={0.5} direction="row" alignItems="center" gap={1}>
        <RecordingsPeriod range={range} setRange={setRange} />

        {hasFiltered && (
          <Button
            data-id="select-all-btn"
            sx={{ml: 'auto', minWidth: 106}}
            variant="outlined"
            color="info"
            startIcon={allSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            disableRipple={false}
            onClick={handleSelectAll}
          >
            {allSelected ? 'Deselect' : 'Select all'}
          </Button>
        )}
      </Stack>

      {renderList()}

      <Drawer open={asideFilter} anchor="right" onClose={() => setAsideFilter(false)}>
        <Stack p={2} width={450}>
          <Box>
            <Typography variant="h6">Sort</Typography>
          </Box>

          <Box>
            <Typography variant="h6">Filter</Typography>
          </Box>
        </Stack>
      </Drawer>

      <Slide in={hasSelected} direction="up" mountOnEnter={true} unmountOnExit={true}>
        <BatchRecordings recordings={selected} reload={reload} onClose={deselectAll} />
      </Slide>
    </Stack>
  );
}
